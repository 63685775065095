import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import moment from 'moment-timezone';
import html2canvas from 'html2canvas';
import pdfMake from "pdfmake"
import { Button, Modal, Form, Table } from 'react-bootstrap';

class ReportViewDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Amount: this.props.SelectedClaimObject.Amount,
    }
  }

  OpenSelectedReceipt(SelectedViewReceipt) {
    // window.open(SelectedViewReceipt, '_blank', 'noopener,noreferrer');
    // const test = SelectedViewReceipt; // URL of the text file
    // fetch(test)
    //   .then(response => response.text())
    //   .then(text => {
    //     if (SelectedViewReceipt.includes('txt')) {
    //       const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
    //       const url = URL.createObjectURL(blob);
    //       window.open(url, '_blank', 'noopener,noreferrer');
    //     } else {
    //       window.open(test, '_blank', 'noopener,noreferrer');
    //     }
    //   });
    const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
    // window.open(SelectedViewReceipt, '_blank', 'noopener,noreferrer');
    const test = SelectedViewReceipt; // URL of the text file
    // fetch(test)
    //   .then(response => response.text())
    //   .then(text => {
    //     if (SelectedViewReceipt.includes('txt')) {
    //       const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
    //       const url = URL.createObjectURL(blob);
    //       window.open(url, '_blank', 'noopener,noreferrer');
    //     } else {
    //       window.open(test, '_blank', 'noopener,noreferrer');
    //     }
    //   });
    if(isIOS) {
      var windowReference = window.open();
      fetch(test)
        .then(response => response.text())
        .then(text => {
          if (SelectedViewReceipt.includes('txt')) {
            const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
            const url = URL.createObjectURL(blob);
            windowReference.location = url;
          } else {
            windowReference.location = test;
          }
        });
      // document.getElementById("openWindowButton").addEventListener("click", function() {
      //   var windowReference = window.open();
      //   fetch(test)
      //     .then(response => response.text())
      //     .then(text => {
      //       if (SelectedFile.includes('txt')) {
      //         const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
      //         const url = URL.createObjectURL(blob);
      //         windowReference.location = url;
      //       } else {
      //         windowReference.location = test;
      //       }
      //     });
      // });
    } else {
      fetch(test)
        .then(response => response.text())
        .then(text => {
          if (SelectedViewReceipt.includes('txt')) {
            const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank', 'noopener,noreferrer');
          } else {
            window.open(test, '_blank', 'noopener,noreferrer');
          }
        });
    }
  }

  SetAmount(value) {
    let Amount = this.state.Amount
    Amount = value
    this.setState({ Amount })
  }

  ExportPDF() {
    html2canvas(document.getElementById("appear")).then(canvas => {
      var data = canvas.toDataURL();
      var pdfExportSetting = {
        content: [
          {
            image: data,
            width: 500
          }
        ]
      };
      pdfMake.createPdf(pdfExportSetting).download(`Receipt-${moment(new Date()).format('DD-MM-YYYY HH:mm:ss')}/${this.props.SelectedClaimObject.Name}.pdf`);
    });
    this.props.OpenReceiptFunction()
  }

  DownloadNow() {
    this.props.OpenReceiptFunction()
    this.setState({ StraighDownload: true })
  }

  printWindow() {
    window.print();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.OpenReceipt !== this.props.OpenReceipt && this.state.StraighDownload === true) {
      this.ExportPDF();
      this.setState({ StraighDownload: false })
    }
  }

  componentWillUnmount() {
    this.setState({ StraighDownload: false, ConsirmPass: false })
  }


  render() {
    return (
      <Modal centered show={this.props.ReportModal} size="lg" onHide={this.props.handleClose} style={{ fontSize: '12px'}}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`查看申请细节`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.OpenReceipt !== true && 
        <>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].ClaimDate}</div>
            {/* <div style={{marginRight: 10, width: '60%', backgroundColor: 'white'}}>{moment(this.props.SelectedClaimObject.UploadDateTime).format('DD-MM-YYYY')}</div> */}
            <Form.Control placeholder="从" value={moment(this.props.SelectedClaimObject.UploadDateTime).format('DD-MM-YYYY')} readOnly={true} type="text" style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px' }}/>
          </div>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Users}</div>
            {/* <div style={{width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Name}</div> */}
            <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.SelectedClaimObject.Name} disabled={true}/>
          </div>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Company}</div>
            {/* <div style={{ marginRight: 10, width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Company}</div> */}
            <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Company} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px' }}/>
          </div>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Branch}</div>
            {/* <div style={{ marginRight: 10, width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Branch}</div> */}
            <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Branch} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px'  }}/>
          </div>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Department}</div>
            {/* <div style={{ marginRight: 10, width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Department}</div> */}
            <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Department} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px'  }}/>
          </div>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Subject}</div>
            {/* <div style={{ marginRight: 10, width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Category}</div> */}
            <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Category} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px'  }}/>
          </div>
          <div className='ClaimInput'>
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Title}</div>
            {/* <div style={{ width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Subject}</div> */}
            <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px'}} value={this.props.SelectedClaimObject.Subject} disabled={true}/>
          </div>
          <div className='ClaimInput'>
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Remark}</div>
            {/* <div style={{ width: '60%', backgroundColor: 'white', wordBreak: 'break-word' }}>{this.props.SelectedClaimObject.Comment}</div> */}
            <textarea style={{ width: '60%', backgroundColor: 'white', fontSize: '12px', minHeight: 95 }} value={this.props.SelectedClaimObject.Comment} disabled={true}></textarea>
          </div>
          <div className='ClaimInput'>
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Amount}</div>
            {this.props.SelectedClaimObject.Status === 'CashOut' && this.props.username === this.props.SelectedClaimObject.UploadBy ? (
              <Form.Control style={{ width: '60%', backgroundColor: 'white' }} value={this.state.Amount} onChange={(e) => this.SetAmount(e.target.value)}/>
            ) : (<Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px'}} value={Number(this.state.Amount).toFixed(2)} disabled={true}/>)}
          </div>
          <div className='ClaimInput'>
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Status}</div>
            {/* <div style={{ width: '60%', backgroundColor: 'white', color: (this.props.SelectedClaimObject.Status === 'Reject' || this.props.SelectedClaimObject.Status === 'Return') ? 'red' : 'black'}}>{this.props.SelectedClaimObject.Status}</div> */}
            <Form.Control style={{ width: '60%', backgroundColor: 'white', color: (this.props.SelectedClaimObject.Status === 'Reject' || this.props.SelectedClaimObject.Status === 'Return') ? 'red' : 'black', fontSize: '12px' }} value={this.props.SelectedClaimObject.Status} disabled={true}/>
          </div>
          <div style={{ marginTop: 5, marginBottom: 10}}>
            <form>
              <div className="form-group multi-preview" style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}>
                {(this.props.SelectedClaimObject.ReceiptImgURL || []).map((url, ind) => (
                  // <div key={ind}>
                  //   <img className="button2" style={{ width: 200, height: 200, padding: 1, cursor: 'pointer' }} src={url.ReceiptImg} alt="images" onClick={() => this.OpenSelectedReceipt(url.ReceiptImg)}/>
                  // </div>
                  <div key={ind} style={{ display: 'flex', flexDirection: 'column', width: '90%'}}>
                    <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                      <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '90%' }}>{url.ReceiptImg}</div>
                      <div className='dcc' onClick={() => this.OpenSelectedReceipt(url.ReceiptImg)} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                    </div>
                  </div>
                ))}
              </div>
            </form>
          </div>
          {this.props.SelectedClaimObject.Status === 'CashOut' && this.props.username === this.props.SelectedClaimObject.UploadBy && 
            <Button variant="success" style={{ backgroundColor: '#027eab', marginRight: 5 }} onClick={() => this.props.ReClaimSelectedClaim(this.state.Amount)}>{language[this.props.currentLanguage].ReClaim}</Button>
          }
          {(this.props.SelectedClaimObject.Status === 'CashOut') && 
                <>
                  <button type="button" className="btn btn-secondary btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.props.OpenReceiptFunction()}>
                    {language[this.props.currentLanguage].ViewReceipt}
                  </button>
                  <button type="button" className="btn btn-secondary btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.DownloadNow()}>
                    {language[this.props.currentLanguage].DownloadReceipt}
                  </button>
                </>}
          {/* <Button style={{ backgroundColor: (this.props.DisplayHistory === false) ? '#027eab' : 'green' }} onClick={() => this.props.DisplayHistorySet()}>
            {(this.props.DisplayHistory === false) ? language[this.props.currentLanguage].DiplayActionHistory : language[this.props.currentLanguage].HideActionHistory}
          </Button> */}
          {/* {this.props.DisplayHistory === true &&  */}
          <div style={{ width: '80%' }}>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>{language[this.props.currentLanguage].ActionsUser}</th>
                  <th>{language[this.props.currentLanguage].Date}</th>
                  <th>{language[this.props.currentLanguage].Remark}</th>
                  <th>{language[this.props.currentLanguage].Reason}</th>
                  <th>{language[this.props.currentLanguage].Amount}</th>
                  <th>{language[this.props.currentLanguage].Action}</th>
                </tr>
              </thead>
              {this.props.HistoryData.length > 0 &&
                    <tbody>
                      {this.props.HistoryData.map((items, index) => {
                        return(
                          <tr key={index}>
                            <td>{items.ActionUser}</td>
                            <td>{moment(items.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td style={{ wordBreak: 'break-word' }}>{items.Comment}</td>
                            <td>{items.Reason}</td>
                            <td>{items.Amount}</td>
                            {/* <td>{items.Status}</td> */}
                            <td>{language[this.props.currentLanguage][items.Status]}</td>
                          </tr>
                        )
                      })}
                    </tbody>
              }
              {this.props.HistoryData.length === 0 &&
                    <tbody>
                      <tr>
                        <td colSpan="6" style={{ textAlign: 'center', fontWeight: 'bold' }}>No History Data</td>
                      </tr>
                    </tbody>
              }
            </Table>
          </div>
          <Button variant="danger" style={{ marginLeft: 5 }} onClick={(this.props.CurrentP === 'Main') ? this.props.CloseViewReceipt : this.props.handleClose}>{language[this.props.currentLanguage].Close}</Button>
        </>
          }
          {this.props.OpenReceipt === true && 
              <div  style={{ display: 'flex', flexDirection: 'column'}}>
                <div  id="appear" className='dcc' style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                  <div style={{ fontWeight: 'bold', fontSize: '26px', marginBottom: 40 }}>{'Payment Receipt'}</div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Company Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Company}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Branch Name '}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Branch}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Department '}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Department}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Category Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Category}`}</div>
                  </div>
                  {this.props.SelectedClaimObject.Sub1 !== null && 
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Sub Category Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Sub1}`}</div>
                  </div>
                  }
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Subject Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Subject}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Submitted Date'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${moment(this.props.SelectedClaimObject.UploadDateTime).format('DD-MM-YYYY')}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Paid Date'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${moment(this.props.SelectedClaimObject.ActionDateTime).format('DD-MM-YYYY')}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Remark'}</div>
                    <div style={{ width: '60%', fontSize: '17px', wordBreak: "break-word", fontWeight: '500'}}>{this.props.SelectedClaimObject.Comment.length > 100 ? `${this.props.SelectedClaimObject.Comment.substring(0, 50)}........` : this.props.SelectedClaimObject.Comment}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Submitted Amount'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`RM ${this.props.SelectedClaimObject.Amount}`}</div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 65}}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Paid Amount'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`RM ${this.props.SelectedClaimObject.ApproveAmount}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%', marginBottom: 100 }}>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'Paid By'}</div>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'Receive By'}</div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 5}}>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'_____________________________'}</div>
                    <div style={{ width: '50%', fontSize: '17px'}}>{'_____________________________'}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '20px'}}>{`${this.props.SelectedClaimObject.Accountant}`}</div>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '20px'}}>{`${this.props.SelectedClaimObject.Name}`}</div>
                  </div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                  <button  type="button" className="CssButtonP ButtonH" style={{ marginTop: 10, marginRight: 5, width: '50%', }} onClick={() => 
                  {
                    // className="btn btn-secondary btn-block "
                    const  answer = window.confirm(`${language[this.props.currentLanguage].AlertPrint}`);
                    if (answer) {
                      this.printWindow()
                    }
                  }
                  }>
                    {language[this.props.currentLanguage].Print}
                  </button>
                  <button type="button" className="CssButtonP ButtonH" style={{ marginTop: 10, marginRight: 5, width: '50%' }} onClick={() => this.ExportPDF()}>
                    {language[this.props.currentLanguage].DownloadReceipt}
                  </button>
                </div>
                <button type="button" className="CssButtonRed ButtonHRed" style={{ marginTop: 10, marginRight: 5, backgroundColor: 'red' }} onClick={() => this.props.OpenReceiptFunction()}>
                  {language[this.props.currentLanguage].Back}
                </button>
              </div>
          }
        
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportViewDetails);
