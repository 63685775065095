import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import Header from '../Component/Header';
import MainPageDetails from '../Component/MainPageDetails';
import ViewReceiptModal from '../Component/ViewReceiptModal';
import MainEditModal from '../Component/MainEditModal';
import { Form, Table, Alert, Button } from 'react-bootstrap';
import { exportMainData } from '../jsonToXlsx';
import { summaryReportFNC, summaryReportDetailFNC, getClaimHistoryRecordFNC, firstApproveClaimFNC } from '../Api'
import moment from 'moment-timezone';

var timer

class Main extends React.Component {

  fileObj = [];
  fileArray = [];
  Base64 = []
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false,
      ClaimViewReceipt: '',
      OpenViewReceiptModal: false,
      ShowUnClaimModal: false,
      showNotification: false,
      SelectedDate: moment().format('YYYY-MM'),
      SelectedWholeYear: false,
      claimData: {},
      UnClaimData: [],
      SelectedValue: {},
      TotalAmount: {},
      FilterValue: [],
      AllSelectValue: [],
      DetailsFilterList: {
        BranchFilter: [],
        DepartFilter: [],
        StatusFilter: [],
        NameFilter: [],
        SubjectFilter: [],
        ApproveFilter: [],
        // SecondApproveFilter: [],
        AccountFilter: [],
        DisplayDate: [],
        DisplayLatestDate: [],
      },
      ShowFilterStatus: 'All',
      ShowFilterDepart: 'All',
      ShowFilterBranch: 'All',
      ShowFilterName: 'All',
      ShowFilterSubject: 'All',
      ShowFilterApprove: 'All',
      ShowFilter2ndApprove: 'All',
      ShowFilterAccount: 'All',
      ShowFilterDate: 'All',
      ShowFilterLatestDate: 'All',
      OwnSummaryClaimData: {},
      SelectedType: '',
      ViewDetails: false,
      background: '#0D6EFD',
      HistoryData: [],
      SelectedTypes: {},
      ErrorMessage: '',
      ConfirmPass: false,
      OpenEdittablemodal: false,
      SelectedYear: moment().format('YYYY'),
      SelectedMonth: moment().format('MM'),
      DisplayYears: [],
      BorderHide: false,
    }
    this.handleClose = this.handleClose.bind(this);
    this.FilterTableView = this.FilterTableView.bind(this);
    this.WatchReceipt = this.WatchReceipt.bind(this);
    this.getClaimData = this.getClaimData.bind(this);
    this.CloseViewReceipt = this.CloseViewReceipt.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.OpenonfirmPAss = this.OpenonfirmPAss.bind(this);
    this.CloseConfirmPAss = this.CloseConfirmPAss.bind(this);
    this.RunApi = this.RunApi.bind(this);
    this.SetStateProps = this.SetStateProps.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }

  handleClose() {
    this.setState({
      OpenEdittablemodal: false,
      ConfirmPass: false,
      // SelectedTypes: {},
      ViewDetails: false,
      SelectedType: '',
      ShowUnClaimModal: false,
      ShowFilterStatus: 'All',
      ShowFilterDepart: 'All',
      ShowFilterBranch: 'All',
      ShowFilterName: 'All',
      ShowFilterSubject: 'All',
      ShowFilterApprove: 'All',
      ShowFilter2ndApprove: 'All',
      ShowFilterAccount: 'All',
      ShowFilterDate: 'All',
      ShowFilterLatestDate: 'All',
      FilterValue: [],
      UnClaimData: [],
      AllSelectValue: [],
      // SelectedValue: {},
      DetailsFilterList: {
        BranchFilter: [],
        DepartFilter: [],
        NameFilter: [],
        SubjectFilter: [],
        StatusFilter: [],
        ApproveFilter: [],
        // SecondApproveFilter: [],
        AccountFilter: [],
        DisplayDate: [],
        DisplayLatestDate: [],
      },
    })
  }

  async componentDidMount() {
    if(this.props.token === '') {
      this.logout();
    }
    // if(this.props.role === 'User') {
    //   window.location.href = '/Claim';
    // }
    this.DisplayYears();
    this.getClaimData();
  }

  SetStateProps(value){
    this.setState({[value]: !this.state[value]})
  }

  logout() {
    localStorage.removeItem('claimtoken');
    localStorage.removeItem('claimusername');
    localStorage.removeItem('claimrole');
    localStorage.removeItem('CompanyData');
    window.location.href = '/';
  }

  DisplayYears() {
    const start = moment().format('YYYY') - 10
    const end = moment().format('YYYY')
    let DisplayYears = this.state.DisplayYears
    for (var i = start + 1; i < end; i++) {
      DisplayYears.push(i.toString())
    }
    DisplayYears.push(end);
    this.setState({ DisplayYears })
  }

  async getClaimData() {
    const SelectedMonth = this.state.SelectedMonth
    const SelectedYear = this.state.SelectedYear
    const SelectedFullYear = this.state.SelectedWholeYear
    const getSummaryData = await summaryReportFNC(SelectedYear, SelectedMonth, SelectedFullYear, this.props.username, this.props.token)
    const CheckRoleBoolean = this.props.role === 'CEO' ? true : false
    if (getSummaryData) {
      this.setState({ BorderHide: CheckRoleBoolean,claimData: getSummaryData.summaryReportObject, OwnSummaryClaimData: getSummaryData.accountOwnSummary }, () => {
        this.CountTotalAmount(this.state.claimData);
      })
    }
  }

  CountTotalAmount(claimData) {
    let TotalAmount = {
      TotalAmount: 0,
      TotalApprove: 0,
      TotalPending: 0,
      TotalCashOut: 0,
      TotalCashOutPending: 0,
      TotalReturn: 0,
      TotalReject: 0,
    }
    Object.keys(claimData).forEach((items) => {
      TotalAmount.TotalAmount += Number(claimData[items].Total)
      TotalAmount.TotalApprove += Number(claimData[items].Approved)
      TotalAmount.TotalPending += Number(claimData[items].Pending)
      TotalAmount.TotalCashOut += Number(claimData[items].CashOut)
      TotalAmount.TotalCashOutPending += Number(claimData[items].NotCashOut)
      TotalAmount.TotalReturn += Number(claimData[items].Return)
      TotalAmount.TotalReject += Number(claimData[items].Reject)
    })
    this.setState({ TotalAmount })
  }

  CalculatedAmount(ClaimData, Type) {
    let CalculatedTotal = {
      TotalAmount: 0,
    }
    Object.keys(ClaimData).forEach((items) => {
      CalculatedTotal.TotalAmount += Number(ClaimData[items][Type])
    })
    return CalculatedTotal.TotalAmount.toLocaleString()
  }

  async UnClaimData(SelectedCompany, Status, Type) {
    const SelectedMonth = this.state.SelectedMonth
    const SelectedYear = this.state.SelectedYear
    const SelectedFullYear = this.state.SelectedWholeYear
    const getSummaryDetails = await summaryReportDetailFNC(
      SelectedYear, SelectedMonth, SelectedFullYear, this.props.username, SelectedCompany, Status, Type, this.props.token
    )
    if (getSummaryDetails) {
      let Status = []
      let BranchArray = [] 
      let DepartArray = []
      let NameArray = []
      let SubjectArray = []
      let ApproveArray =  []
      // let SecondApproveArray = []
      let AccountArray = []
      let DisplayDateArray = []
      let LatestDateArray = []

      getSummaryDetails.forEach((items) => {
        BranchArray.push(items.Branch)
        DepartArray.push(items.Department)
        Status.push(items.Status)
        NameArray.push(items.UploadBy)
        SubjectArray.push(items.Category)
        ApproveArray.push(items.FirstApprove)
        // SecondApproveArray.push(items.SecondApprove)
        AccountArray.push(items.Accountant)
        DisplayDateArray.push(moment(items.UploadDateTime).format('DD-MM-YY'))
        LatestDateArray.push(moment(items.ActionDateTime).format('DD-MM-YY'))
      })
      BranchArray = [...new Set(BranchArray)];
      DepartArray = [...new Set(DepartArray)];
      Status = [...new Set(Status)];
      NameArray = [...new Set(NameArray)];
      SubjectArray = [...new Set(SubjectArray)];
      ApproveArray  = [...new Set(ApproveArray)];
      // SecondApproveArray  = [...new Set(SecondApproveArray)];
      AccountArray  = [...new Set(AccountArray)];
      DisplayDateArray  = [...new Set(DisplayDateArray)];
      LatestDateArray  = [...new Set(LatestDateArray)];
      ApproveArray = ApproveArray.filter(item => item !== null);
      // SecondApproveArray = SecondApproveArray.filter(item => item !== null);
      AccountArray = AccountArray.filter(item => item !== null);
      LatestDateArray = LatestDateArray.filter(item => item !== 'Invalid date');
      DisplayDateArray = DisplayDateArray.filter(item => item !== 'Invalid date');
      let DetailsFilterList = {
        BranchFilter: BranchArray,
        DepartFilter: DepartArray,
        StatusFilter: Status,
        NameFilter: NameArray,
        SubjectFilter: SubjectArray,
        ApproveFilter: ApproveArray,
        // SecondApproveFilter: SecondApproveArray,
        AccountFilter: AccountArray,
        DisplayDate: DisplayDateArray,
        DisplayLatestDate: LatestDateArray,
      }
      this.setState({ UnClaimData: getSummaryDetails, DetailsFilterList, AllSelectValue: getSummaryDetails, ViewDetails: true })
    }
  }

  DisplaySeletedDetails(SelectedCompany, Type, displayType, passbacktype) {
    this.setState({ SelectedTypes: { SelectedType: displayType, SelectedTable: passbacktype } })
    this.UnClaimData(SelectedCompany, Type, passbacktype)
    let SelectedValue = {
      Company: SelectedCompany,
      Type: displayType,
    }
    this.setState({ SelectedType: passbacktype })
    this.setState({ ShowUnClaimModal: true, SelectedValue})
    this.setState({
      ShowFilterStatus: 'All',
      ShowFilterDepart: 'All',
      ShowFilterBranch: 'All',
      ShowFilterName: 'All',
      ShowFilterSubject: 'All',
      ShowFilterApprove: 'All',
      ShowFilter2ndApprove: 'All',
      ShowFilterAccount: 'All',
      ShowFilterDate: 'All',
      ShowFilterLatestDate: 'All',
    })
  }

  FilterTableView(e, Selecttype) {
    let AllSelectValue = this.state.AllSelectValue
    let ShowFilterBranch = this.state.ShowFilterBranch
    let ShowFilterDepart = this.state.ShowFilterDepart
    let ShowFilterStatus = this.state.ShowFilterStatus
    let ShowFilterName = this.state.ShowFilterName
    let ShowFilterLatestDate = this.state.ShowFilterLatestDate
    let ShowFilterSubject = this.state.ShowFilterSubject
    let ShowFilterApprove = this.state.ShowFilterApprove
    // let ShowFilter2ndApprove = this.state.ShowFilter2ndApprove
    let ShowFilterAccount = this.state.ShowFilterAccount
    let ShowFilterDate = this.state.ShowFilterDate
  
    if(Selecttype === 'ShowFilterBranch') {
      ShowFilterBranch = e
      this.setState({ShowFilterBranch: e })
    }
    if(Selecttype === 'ShowFilterDepart') {
      ShowFilterDepart = e
      this.setState({ShowFilterDepart: e })
    }
    if(Selecttype === 'ShowFilterStatus') {
      ShowFilterStatus = e
      this.setState({ShowFilterStatus: e })
    }
    if(Selecttype === 'ShowFilterName') {
      ShowFilterName = e
      this.setState({ShowFilterName: e })
    }
    if(Selecttype === 'ShowFilterApprove') {
      ShowFilterApprove = e
      this.setState({ShowFilterApprove: e })
    }
    // if(Selecttype === 'ShowFilter2ndApprove') {
    //   ShowFilter2ndApprove = e
    //   this.setState({ShowFilter2ndApprove: e })
    // }
    if(Selecttype === 'ShowFilterAccount') {
      ShowFilterAccount = e
      this.setState({ShowFilterAccount: e })
    }
    if(Selecttype === 'ShowFilterSubject') {
      ShowFilterSubject = e
      this.setState({ShowFilterSubject: e })
    }
    if(Selecttype === 'ShowFilterLatestDate') {
      ShowFilterLatestDate = e
      this.setState({ShowFilterLatestDate: e })
    }
    if(Selecttype === 'ShowFilterDate') {
      ShowFilterDate = e
      this.setState({ShowFilterDate: e })
    }
    if (ShowFilterBranch !== 'All') {
      AllSelectValue = AllSelectValue.filter((value) => value.Branch === ShowFilterBranch);
    }
    if (ShowFilterDepart !== 'All') {
      AllSelectValue = AllSelectValue.filter((value) => value.Department === ShowFilterDepart);
    }
    if (ShowFilterStatus !== 'All') {
      AllSelectValue = AllSelectValue.filter((value) => value.Status === ShowFilterStatus);
    }
    if (ShowFilterName !== 'All') {
      AllSelectValue = AllSelectValue.filter((value) => value.UploadBy === ShowFilterName);
    }
    if (ShowFilterApprove !== 'All') {
      AllSelectValue = AllSelectValue.filter((value) => value.FirstApprove === ShowFilterApprove);
    }
    // if (ShowFilter2ndApprove !== 'All') {
    //   AllSelectValue = AllSelectValue.filter((value) => value.SecondApprove === ShowFilter2ndApprove);
    // }
    if (ShowFilterAccount !== 'All') {
      AllSelectValue = AllSelectValue.filter((value) => value.Accountant === ShowFilterAccount);
    }
    if (ShowFilterSubject !== 'All') {
      AllSelectValue = AllSelectValue.filter((value) => value.Category === ShowFilterSubject);
    }
    if (ShowFilterLatestDate !== 'All') {
      AllSelectValue = AllSelectValue.filter((value) => moment(value.ActionDateTime).format('DD-MM-YY') === ShowFilterLatestDate);
    }
    if (ShowFilterDate !== 'All') {
      AllSelectValue = AllSelectValue.filter((value) => moment(value.UploadDateTime).format('DD-MM-YY') === ShowFilterDate);
    }

    this.setState({ UnClaimData: AllSelectValue })
  }

  WatchReceipt(ClaimReceipts) {
    const SelectedTypes = this.state.SelectedTypes
    if(SelectedTypes.SelectedType === '待批' && SelectedTypes.SelectedTable === 'Self') {
      this.setState({ OpenEdittablemodal: true, ClaimViewReceipt: ClaimReceipts }, () => {
        this.DisplayHistoryData(ClaimReceipts.ID);
      })
    } else {
      this.setState({ OpenViewReceiptModal: true, ClaimViewReceipt: ClaimReceipts }, () => {
        this.DisplayHistoryData(ClaimReceipts.ID);
      })
    }
  }

  CloseViewReceipt() {
    this.setState({ OpenViewReceiptModal: false, ClaimViewReceipt: '', ConfirmPass: false, OpenEdittablemodal: false }, () => {
      this.DisplaySeletedDetails(`${this.state.SelectedValue.Company}`, `${(this.state.SelectedValue.Type) === '待批' ? 'Pending' : (this.state.SelectedValue.Type) === '尚未交付' ? 'Approve' : (this.state.SelectedValue.Type) === '已批' ? 'Approve' : (this.state.SelectedValue.Type) === '被退回' ? 'Return' : (this.state.SelectedValue.Type) === '总数' ? 'All' : (this.state.SelectedValue.Type) === '已交付' ? 'CashOut' : ''}`, `${this.state.SelectedTypes.SelectedType}`, `${this.state.SelectedTypes.SelectedTable}`)
    })
  }

  CloseConfirmPAss() {
    this.setState({ ConfirmPass: false })
  }

  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  async DisplayHistoryData(EditID) {
    this.setState({ DisplayHistory: !this.state.DisplayHistory }, async() => {
      // document.getElementById('scripted').focus();
      const callHistoryData = await getClaimHistoryRecordFNC(EditID, this.props.username, this.props.token)
      if(callHistoryData.error) {
        if (callHistoryData.error === 'database error') {
          this.openNotification(`${language[this.props.currentLanguage].DatabaseError}`,'error')
          this.handleClose();
        }
      } else {
        this.setState({ HistoryData: callHistoryData })
      }
    })
  }

  OpenonfirmPAss() {
    this.setState({ ConfirmPass: true })
  }
  
  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 4000);
  };

  async RunApi(type, SelectedActions, SelectedCompany) {
    if (type === '批准') {
      const ApproveClaim = await firstApproveClaimFNC(SelectedActions, this.props.username, this.props.token)
      if(ApproveClaim.error === 'Don\'t have authorize to update/reject claim') {
        this.openNotification('失误: 您没有此功能的权限','Error')
      } 
      if(ApproveClaim.error === 'Cannot approve / invalid approve') {
        this.openNotification('失误: 请联系另一位会计批准','Error')
      } 
      // if(ApproveClaim.error === 'Login session timeout') {
      //   this.logout();
      // } 
      if(ApproveClaim.message === 'Successful update') {
        this.openNotification(`成功: 成功批准申请`,'success')
        this.getClaimData();
        // this.handleClose();
        this.DisplaySeletedDetails(`${SelectedCompany}`, 'Pending', `${this.state.SelectedTypes.SelectedType}`, `${this.state.SelectedTypes.SelectedTable}`)
      }
    }
  }

  async exportFile() {
    await exportMainData(this.state.UnClaimData, this.state.SelectedMonth, this.state.SelectedYear, this.props.currentLanguage, this.state.SelectedWholeYear)
  }

  render() {
    return (
      <div fluid className={(this.state.ViewDetails === true) ? 'non-printable' : 'printable'}>
        <Header/>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        {this.state.OpenViewReceiptModal === true &&
          <ViewReceiptModal 
            OpenViewReceiptModal={this.state.OpenViewReceiptModal}
            openNotification={this.openNotification} CloseViewReceipt={this.CloseViewReceipt}
            getClaimData={this.getClaimData} handleClose={this.handleClose}
            OpenonfirmPAss={this.OpenonfirmPAss} ClaimsData={this.state.ClaimViewReceipt}
            ConfirmPass={this.state.ConfirmPass} HistoryData={this.state.HistoryData}
            SelectedTypes={this.state.SelectedTypes} CloseConfirmPAss={this.CloseConfirmPAss}
          />
        }
        {this.state.OpenEdittablemodal === true &&
         <MainEditModal OpenEdittablemodal={this.state.OpenEdittablemodal}
           openNotification={this.openNotification} CloseViewReceipt={this.CloseViewReceipt}
           getClaimData={this.getClaimData} handleClose={this.handleClose} ClaimsData={this.state.ClaimViewReceipt}
           HistoryData={this.state.HistoryData} SelectedTypes={this.state.SelectedTypes}
         />
        }
        <div style={{ paddingLeft: 10, paddingRight: 10, fontSize: '13px' }}>
          <div onScroll={(e) => this.handleScroll(e)} id={'Top'} style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <h2 style={{ marginRight: 10 }}>{language[this.props.currentLanguage].HomePage}</h2>
              {/* <Form.Control value={this.state.SelectedDate} onChange={(e) => this.setState({ SelectedDate: e.target.value }, () => this.getClaimData())} type="month" style={{ marginRight: 10, width: 140 }}/> */}
              {/* <Button variant="success" style={{ marginRight: 5 }} onClick={() => this.getClaimData()}>{language[this.props.currentLanguage].Search}</Button> */}
              <div style={{ marginRight: 10, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Month}</div>
              <Form.Select style={{ width: 100}} onChange={(e) => this.setState({ SelectedMonth: e.target.value }, () => {this.getClaimData()})} value={this.state.SelectedMonth}>
                {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((item, index) => 
                  <option key={index} value={item}>{language[this.props.currentLanguage][item]}</option>
                )}
              </Form.Select>
              {this.state.DisplayYears.length > 0 && 
            <>
              <div style={{ marginRight: 10, fontWeight: 'bold', marginLeft: 5 }}>{language[this.props.currentLanguage].Year}</div>
              <Form.Select style={{ width: 100}} onChange={(e) => this.setState({ SelectedYear: e.target.value }, () => { this.getClaimData() })} value={this.state.SelectedYear}>
                {this.state.DisplayYears.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </>}
              <div className="dcc button"
                style={{ backgroundColor: this.state.background, marginLeft: 10,
                  fontWeight: 'bold', padding: 7 , cursor: 'pointer', borderRadius: 5, paddingLeft: 12, paddingRight: 12, marginRight: 5 }} 
                onMouseEnter={() => {
                  this.setState({ background: '#00B79B' });
                }}
                onMouseLeave={() => {
                  this.setState({ background: '#0D6EFD' });
                }}
                variant="success" onClick={() => this.getClaimData()}>
                <span>
                  {language[this.props.currentLanguage].Search}
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: 10, fontWeight: 'bold'}}>{language[this.props.currentLanguage].WholeYear}</div>
                <Form.Check
                  type="checkbox"
                  checked={this.state.SelectedWholeYear}
                  onChange={(e) => this.setState({ SelectedWholeYear: e.target.checked }, () => { this.getClaimData()})}
                /></div>
            </div>
          </div>
          {this.state.ViewDetails === false && 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <Button style={{ width: 'auto', paddingLeft: 15, paddingRight: 15 }} onClick={() => {
            const  answer = window.confirm(`${language[this.props.currentLanguage].AlertPrint}`);
            if (answer) {
              window.print()
            }
          }
          }>{language[this.props.currentLanguage].Print}</Button>
        </div>
          }
          {(this.state.ViewDetails === true) && 
        <div>
          <MainPageDetails
            BorderHide={this.state.BorderHide}
            SetStateProps={this.SetStateProps}
            exportFile={this.exportFile}
            RunApi={this.RunApi}
            ShowUnClaimModal={this.state.ShowUnClaimModal}
            handleClose={this.handleClose}
            WatchReceipt={this.WatchReceipt}
            DetailsFilterList={this.state.DetailsFilterList}
            SelectedValue={this.state.SelectedValue}
            FilterTableView={this.FilterTableView}
            UnClaimData={this.state.UnClaimData}
            ShowFilterStatus={this.state.ShowFilterStatus}
            howFilShowFilterBranchterBranch={this.state.ShowFilterBranch}
            ShowFilterDepart={this.state.ShowFilterDepart}
            ShowFilterName={this.state.ShowFilterName}
            ShowFilterSubject={this.state.ShowFilterSubject}
            ShowFilterApprove={this.state.ShowFilterApprove}
            ShowFilter2ndApprove={this.state.ShowFilter2ndApprove}
            ShowFilterDate={this.state.ShowFilterDate}
            ShowFilterLatestDate={this.state.ShowFilterLatestDate}
            ShowFilterAccount={this.state.ShowFilterAccount}
            FilterValue={this.state.FilterValue}
          />
        </div>
          }
          {(this.props.role === 'CEO' || this.props.role === 'Account') && 
        <>
          <div style={{ marginTop: 10, fontSize: '20px', fontWeight: 'bold' }}>{language[this.props.currentLanguage].AllUserClaimummary}</div>
          <Table striped bordered hover responsive style={{ width: '90%'}}>
            <thead>
              <tr style={{ textAlign: 'center'}}>
                <th>{language[this.props.currentLanguage].Company}</th>
                <th>{language[this.props.currentLanguage].TotalAmount}</th>
                <th>{language[this.props.currentLanguage].Approved}</th>
                <th>{language[this.props.currentLanguage].Pending}</th>
                <th>{language[this.props.currentLanguage].Cashouted}</th>
                <th>{language[this.props.currentLanguage].NotCashOut}</th>
                <th>{language[this.props.currentLanguage].Returned}</th>
                <th>{language[this.props.currentLanguage].Rejected}</th>
              </tr>
            </thead>
            {Object.keys(this.state.claimData).length > 0 ?
              <tbody>
                {Object.keys(this.state.claimData).map((items, index) => {
                  return(
                    <tr key={index} style={{ cursor: 'pointer' }}>
                      <td>{items}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'All', '总数', 'All')}>{Number(this.state.claimData[items].Total).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'Approve', '已批', 'All')}>{Number(this.state.claimData[items].Approved).toLocaleString()}</td>
                      <td className="button3" style={{ color: Number(this.state.claimData[items].Pending) === 0 ? '' : 'blue'}} onClick={() => this.DisplaySeletedDetails(items, 'Pending', '待批', 'All')}>{Number(this.state.claimData[items].Pending).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'CashOut', '已交付', 'All')}>{Number(this.state.claimData[items].CashOut).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'Approve', '尚未交付', 'All')}>{Number(this.state.claimData[items].NotCashOut).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'Return', '被退回', 'All')}>{Number(this.state.claimData[items].Return).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'Reject', '被拒绝', 'All')}>{Number(this.state.claimData[items].Reject).toLocaleString()}</td>
                    </tr>
                  )
                })}
                <tr style={{ backgroundColor: '#4A5874'}}>
                  <td style={{ color: 'white' }}>{language[this.props.currentLanguage].Total}</td>
                  <td style={{ color: 'white' }}>{Number(this.state.TotalAmount.TotalAmount).toLocaleString()}</td>
                  <td style={{ color: 'white' }}>{Number(this.state.TotalAmount.TotalApprove).toLocaleString()}</td>
                  <td style={{ color: 'white' }}>{Number(this.state.TotalAmount.TotalPending).toLocaleString()}</td>
                  <td style={{ color: 'white' }}>{Number(this.state.TotalAmount.TotalCashOut).toLocaleString()}</td>
                  <td style={{ color: 'white' }}>{Number(this.state.TotalAmount.TotalCashOutPending).toLocaleString()}</td>
                  <td style={{ color: 'white' }}>{Number(this.state.TotalAmount.TotalReturn).toLocaleString()}</td>
                  <td style={{ color: 'white' }}>{Number(this.state.TotalAmount.TotalReject).toLocaleString()}</td>
                </tr>
              </tbody>
              :
              <tbody>
                <tr style={{ backgroundColor: 'lightgray'}}>
                  <td colSpan="8" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                </tr>
              </tbody>}
          </Table>
        </>
          }
          {(this.props.role === 'CEO' || this.props.role === 'Account') && 
        <>
          <div style={{ marginTop: 10, fontSize: '20px', fontWeight: 'bold' }}>{language[this.props.currentLanguage].AllUserClaimummary}</div>
          <Table striped bordered hover responsive style={{ width: '90%'}}>
            <thead>
              <tr style={{ textAlign: 'center'}}>
                <th>{language[this.props.currentLanguage].Company}</th>
                <th>{language[this.props.currentLanguage].Approved}</th>
                <th>{language[this.props.currentLanguage].Cashouted}</th>
                <th>{language[this.props.currentLanguage].NotCashOut}</th>
              </tr>
            </thead>
            {Object.keys(this.state.claimData).length > 0 ?
              <tbody>
                {Object.keys(this.state.claimData).map((items, index) => {
                  return(
                    <tr key={index} style={{ cursor: 'pointer' }}>
                      <td>{items}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'Approve', '已批', 'All')}>{Number(this.state.claimData[items].Approved).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'CashOut', '已交付', 'All')}>{Number(this.state.claimData[items].CashOut).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'Approve', '尚未交付', 'All')}>{Number(this.state.claimData[items].NotCashOut).toLocaleString()}</td>
                    </tr>
                  )
                })}
                <tr style={{ backgroundColor: '#4A5874'}}>
                  <td style={{ color: 'white' }}>{language[this.props.currentLanguage].Total}</td>
                  <td style={{ color: 'white' }}>{Number(this.state.TotalAmount.TotalApprove).toLocaleString()}</td>
                  <td style={{ color: 'white' }}>{Number(this.state.TotalAmount.TotalCashOut).toLocaleString()}</td>
                  <td style={{ color: 'white' }}>{Number(this.state.TotalAmount.TotalCashOutPending).toLocaleString()}</td>
                </tr>
              </tbody>
              :
              <tbody>
                <tr style={{ backgroundColor: 'lightgray'}}>
                  <td colSpan="4" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                </tr>
              </tbody>}
          </Table>
        </>
          }
          {/* {this.state.UnClaimData.length > 0 && 
        <div>
          <MainPageDetails ShowUnClaimModal={this.state.ShowUnClaimModal} handleClose={this.handleClose} WatchReceipt={this.WatchReceipt} DetailsFilterList={this.state.DetailsFilterList} SelectedValue={this.state.SelectedValue} FilterTableView={this.FilterTableView} UnClaimData={this.state.UnClaimData} ShowFilterStatus={this.state.ShowFilterStatus} howFilShowFilterBranchterBranch={this.state.ShowFilterBranch} ShowFilterDepart={this.state.ShowFilterDepart} FilterValue={this.state.FilterValue}/>
        </div>
        } */}
          <div style={{ marginTop: 10, fontSize: '20px', color: (this.props.role === 'User') ? 'black' : 'skyblue', fontWeight: 'bold' }}>{language[this.props.currentLanguage].SelfClaimSummary}</div>
          <Table striped bordered hover responsive style={{ width: '90%'}}>
            <thead>
              <tr style={{ textAlign: 'center'}}>
                <th>{language[this.props.currentLanguage].Company}</th>
                <th>{language[this.props.currentLanguage].TotalAmount}</th>
                <th>{language[this.props.currentLanguage].Approved}</th>
                <th>{language[this.props.currentLanguage].Pending}</th>
                <th>{language[this.props.currentLanguage].Cashouted}</th>
                <th>{language[this.props.currentLanguage].NotCashOut}</th>
                <th>{language[this.props.currentLanguage].Returned}</th>
                <th>{language[this.props.currentLanguage].Rejected}</th>
              </tr>
            </thead>
            {Object.keys(this.state.OwnSummaryClaimData).length > 0 ?
              <tbody>
                {Object.keys(this.state.OwnSummaryClaimData).map((items, index) => {
                  return(
                    <tr key={index} style={{ cursor: 'pointer' }}>
                      <td>{items}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'All', '总数', 'Self')}>{Number(this.state.OwnSummaryClaimData[items].Total).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'Approve', '已批', 'Self')}>{Number(this.state.OwnSummaryClaimData[items].Approved).toLocaleString()}</td>
                      <td className="button3" style={{ color: Number(this.state.OwnSummaryClaimData[items].Pending) === 0 ? '' : 'blue'}} onClick={() => this.DisplaySeletedDetails(items, 'Pending', '待批', 'Self')}>{Number(this.state.OwnSummaryClaimData[items].Pending).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'CashOut', '已交付', 'Self')}>{Number(this.state.OwnSummaryClaimData[items].CashOut).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'Approve', '尚未交付', 'Self')}>{Number(this.state.OwnSummaryClaimData[items].NotCashOut).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'Return', '被退回', 'Self')}>{Number(this.state.OwnSummaryClaimData[items].Return).toLocaleString()}</td>
                      <td className="button3" onClick={() => this.DisplaySeletedDetails(items, 'Reject', '被拒绝', 'Self')}>{Number(this.state.OwnSummaryClaimData[items].Reject).toLocaleString()}</td>
                    </tr>
                  )
                })}
                <tr style={{ backgroundColor: (this.props.role === 'User') ? '#4A5874' : 'skyblue' }}>
                  <td style={{ color: (this.props.role === 'User' ? 'white' : 'black' )}}>{language[this.props.currentLanguage].Total}</td>
                  <td style={{ color: (this.props.role === 'User' ? 'white' : 'black' )}}>{this.CalculatedAmount(this.state.OwnSummaryClaimData, 'Total')}</td>
                  <td style={{ color: (this.props.role === 'User' ? 'white' : 'black' )}}>{this.CalculatedAmount(this.state.OwnSummaryClaimData, 'Approved')}</td>
                  <td style={{ color: (this.props.role === 'User' ? 'white' : 'black' )}}>{this.CalculatedAmount(this.state.OwnSummaryClaimData, 'Pending')}</td>
                  <td style={{ color: (this.props.role === 'User' ? 'white' : 'black' )}}>{this.CalculatedAmount(this.state.OwnSummaryClaimData, 'CashOut')}</td>
                  <td style={{ color: (this.props.role === 'User' ? 'white' : 'black' )}}>{this.CalculatedAmount(this.state.OwnSummaryClaimData, 'NotCashOut')}</td>
                  <td style={{ color: (this.props.role === 'User' ? 'white' : 'black' )}}>{this.CalculatedAmount(this.state.OwnSummaryClaimData, 'Return')}</td>
                  <td style={{ color: (this.props.role === 'User' ? 'white' : 'black' )}}>{this.CalculatedAmount(this.state.OwnSummaryClaimData, 'Reject')}</td>
                </tr>
              </tbody>
              :
              <tbody>
                <tr style={{ backgroundColor: 'lightgray'}}>
                  <td colSpan="8" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                </tr>
              </tbody>}
          </Table>
          {this.state.is_visible && 
            <div className="dcc" style={{ paddingBottom: 30 }}>
              <div class="dcc scrollDesign iconpopup button2"  
                onClick={() => this.scrollToTop()}>
                {/* ↑ ⇈ */}
                <div  className="icon MoveUpArrow"> ⇧ </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
