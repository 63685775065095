import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { language } from '../language';
import { Button, Modal, Form, Table } from 'react-bootstrap';

class RedoReturnModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <Modal centered show={this.props.RedoReturnModal} size="lg" onHide={this.props.handleClose} style={{ fontSize: '12px'}}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {language[this.props.currentLanguage].OverruleReturn}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].ClaimDate}</div>
            {/* <div style={{marginRight: 10, width: '60%', backgroundColor: 'white'}}>{moment(this.props.SelectedClaimObject.UploadDateTime).format('DD-MM-YYYY')}</div> */}
            <Form.Control placeholder="从" value={moment(this.props.SelectedClaimObject.UploadDateTime).format('DD-MM-YYYY')} readOnly={true} type="text" style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px' }}/>
          </div>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Users}</div>
            {/* <div style={{width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Name}</div> */}
            <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.SelectedClaimObject.Name} disabled={true}/>
          </div>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Company}</div>
            {/* <div style={{marginRight: 10, width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Company}</div> */}
            <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Company} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px' }}/>
          </div>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Branch}</div>
            {/* <div style={{marginRight: 10, width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Branch}</div> */}
            <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Branch} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px'  }}/>
          </div>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Department}</div>
            {/* <div style={{marginRight: 10, width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Department}</div> */}
            <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Department} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px'  }}/>
          </div>
          <div className="ClaimInput">
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Subject}</div>
            {/* <div style={{marginRight: 10, width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Category}</div> */}
            <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Category} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px'  }}/>
          </div>
          <div className='ClaimInput'>
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Title}</div>
            {/* <div style={{width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Subject}</div> */}
            <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px'}} value={this.props.SelectedClaimObject.Subject} disabled={true}/>
          </div>
          <div className='ClaimInput'>
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Remark}</div>
            {/* <div style={{ width: '60%', backgroundColor: 'white', wordBreak: 'break-word' }}>{this.props.SelectedClaimObject.Comment}</div> */}
            <textarea style={{ width: '60%', backgroundColor: 'white', fontSize: '12px', minHeight: 95 }} value={this.props.SelectedClaimObject.Comment} disabled={true}></textarea>
          </div>
          <div className='ClaimInput'>
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Amount}</div>
            {/* <div style={{width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Amount}</div> */}
            <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.SelectedClaimObject.Amount} disabled={true}/>
          </div>
          <div className='ClaimInput'>
            <div className='UploadClaimTitle'>{language[this.props.currentLanguage].ReturnReason}</div>
            {/* <div style={{width: '60%', backgroundColor: 'white'}}>{this.props.SelectedClaimObject.Reason}</div> */}
            <textarea style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.SelectedClaimObject.Reason} disabled={true}></textarea>
          </div> 
          <div style={{ marginTop: 5, marginBottom: 10}}>
            <form>
              <button type="button" className="btn btn-success btn-block" style={{ marginTop: 10, marginRight: 5, marginBottom: 5 }} onClick={() => this.props.RedoActions(this.props.SelectedClaimObject.ID)}>
                {language[this.props.currentLanguage].OverruleReturn}
              </button>
            </form>
          </div>
          <div style={{ width: '80%' }}>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>{language[this.props.currentLanguage].ActionsUser}</th>
                  <th>{language[this.props.currentLanguage].Date}</th>
                  <th>{language[this.props.currentLanguage].Remark}</th>
                  <th>{language[this.props.currentLanguage].Reason}</th>
                  <th>{language[this.props.currentLanguage].Amount}</th>
                  <th>{language[this.props.currentLanguage].Action}</th>
                </tr>
              </thead>
              {this.props.HistoryData.length > 0 &&
                    <tbody>
                      {this.props.HistoryData.map((items, index) => {
                        return(
                          <tr key={index}>
                            <td>{items.ActionUser}</td>
                            <td>{moment(items.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td style={{ wordBreak: 'break-word' }}>{items.Comment}</td>
                            <td>{items.Reason}</td>
                            <td>{items.Amount}</td>
                            {/* <td>{items.Status}</td> */}
                            <td>{language[this.props.currentLanguage][items.Status]}</td>
                          </tr>
                        )
                      })}
                    </tbody>
              }
              {this.props.HistoryData.length === 0 &&
                    <tbody>
                      <tr>
                        <td colSpan="6" style={{ textAlign: 'center', fontWeight: 'bold' }}>No History Data</td>
                      </tr>
                    </tbody>
              }
            </Table>
          </div>
          <Button variant="danger" onClick={this.props.handleClose}>{language[this.props.currentLanguage].Close}</Button>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RedoReturnModal);
