import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import Header from '../Component/Header';
import { Container, Form, Button, Table, Modal, Alert } from 'react-bootstrap';
import { getCategory, postCategory, copyCategory, deleteCategory } from '../Api'
import Creatable from 'react-select/creatable';

var timer

const customStyle = {
  control: (provided) => ({ ...provided, width: 150 }), menuPortal: (provided) => ({...provided,zIndex: 9999})
}

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      categoryListResult: [],
      departmentList: [],
      categoryList: [],
      selectedDepartment: '',
      selectedCategory: '',
      selectedSub1: '',
      selectedsub2: '',
      selectedsub3: '',
      selectedsub4: '',
      selectedsub5: '',
      increaseModal: false,
      modalInput: [{ Department: '', Category: '', Sub1: '', Sub2: '', Sub3: '', Sub4: '', Sub5: '', Sub6: '' }],
      init: true,
      initDepartment: [],
      fromDepartment: '',
      toDepartment: '',
      copyModal: false,
      initialDepartmentList: [],
      initialCategoryList: [],
      initialSub1List: [],
      initialSub2List: [],
      initialSub3List: [],
      initialSub4List: [],
      initialSub5List: [],
      initialSub6List: [],
      loading: false,
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleCloseCopy = this.handleCloseCopy.bind(this)
  }

  async componentDidMount() {
    if(this.props.token === '') {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      window.location.href = '/';
    }
    this.getCategoryF()
  }

  async getCategoryF() {
    this.setState({ loading: true })
    const categoryObj = {
      department: this.state.selectedDepartment,
      Category: this.state.selectedCategory,
      sub1: this.state.selectedSub1,
      sub2: this.state.selectedsub2,
      sub3: this.state.selectedsub3,
      sub4: this.state.selectedsub4,
      sub5: this.state.selectedsub5,
    }
    const categoryListResult = await getCategory(this.props.username, this.props.token, categoryObj)
    let departmentList = categoryListResult.map((item) => item.Department)
    if (this.state.init) {
      this.setState({ initDepartment: departmentList, init: false, loading: false })
    }
    departmentList = [...new Set(departmentList)]
    let categoryList = categoryListResult.map((item) => item.Category)
    categoryList = [...new Set(categoryList)]
    if (this.state.selectedDepartment === '' && this.state.selectedCategory === '' && this.state.selectedSub1 === '' && this.state.selectedsub2 === '' && this.state.selectedsub3 === '' && this.state.selectedsub4 === '' && this.state.selectedsub5 === '') {
      let initialDepartmentList = categoryListResult.map((item) => item.Department)
      initialDepartmentList = [...new Set(initialDepartmentList)]
      initialDepartmentList = initialDepartmentList.filter((item) => !(item === '' || item === null))
      initialDepartmentList = initialDepartmentList.map((item) => ({
        value: item,
        label: item,
      }))
      let initialCategoryList = categoryListResult.map((item) => item.Category)
      initialCategoryList = [...new Set(initialCategoryList)]
      initialCategoryList = initialCategoryList.filter((item) => !(item === '' || item === null))
      initialCategoryList = initialCategoryList.map((item) => ({
        value: item,
        label: item,
      }))
      let initialSub1 = categoryListResult.map((item) => item.Sub1)
      initialSub1 = initialSub1.filter((item) => !(item === '' || item === null))
      initialSub1 = [...new Set(initialSub1)]
      initialSub1 = initialSub1.map((item) => ({
        value: item,
        label: item,
      }))
      let initialSub2 = categoryListResult.map((item) => item.Sub2)
      initialSub2 = [...new Set(initialSub2)]
      initialSub2 = initialSub2.filter((item) => !(item === '' || item === null))
      initialSub2 = initialSub2.map((item) => ({
        value: item,
        label: item,
      }))
      let initialSub3 = categoryListResult.map((item) => item.Sub3)
      initialSub3 = initialSub3.filter((item) => !(item === '' || item === null))
      initialSub3 = [...new Set(initialSub3)]
      initialSub3 = initialSub3.map((item) => ({
        value: item,
        label: item,
      }))
      let initialSub4 = categoryListResult.map((item) => item.Sub4)
      initialSub4 = initialSub4.filter((item) => !(item === '' || item === null))
      initialSub4 = [...new Set(initialSub4)]
      initialSub4 = initialSub4.map((item) => ({
        value: item,
        label: item,
      }))
      let initialSub5 = categoryListResult.map((item) => item.Sub5)
      initialSub5 = initialSub5.filter((item) => !(item === '' || item === null))
      initialSub5 = [...new Set(initialSub5)]
      initialSub5 = initialSub5.map((item) => ({
        value: item,
        label: item,
      }))
      let initialSub6 = categoryListResult.map((item) => item.Sub6)
      initialSub6 = initialSub6.filter((item) => !(item === '' || item === null))
      initialSub6 = [...new Set(initialSub6)]
      initialSub6 = initialSub6.map((item) => ({
        value: item,
        label: item,
      }))
      this.setState({ initialDepartmentList, initialCategoryList, initialSub1, initialSub2, initialSub3, initialSub4, initialSub5, initialSub6, loading: false })
    }
    this.setState({ departmentList, categoryList, categoryListResult })
  }

  async resetTable() {
    this.setState({ 
      selectedDepartment: '', 
      selectedCategory: '',
      selectedSub1: '',
      selectedsub2: '',
      selectedsub3: '',
      selectedsub4: '',
      selectedsub5: '',
    }, () => this.getCategoryF())
  }

  handleClose() {
    this.setState({
      modalInput: [{ Department: '', Category: '', Sub1: '', Sub2: '', Sub3: '', Sub4: '', Sub5: '', Sub6: '' }],
      increaseModal: false,
    })
  }

  handleCloseCopy() {
    this.setState({
      fromDepartment: '',
      toDepartment: '',
      copyModal: false,
    })
  }

  addIncreaseModal(Department, Category, Sub1, Sub2, Sub3, Sub4, Sub5) {
    let modalInput = JSON.parse(JSON.stringify(this.state.modalInput))
    if (Department && Category && Sub1 && Sub2 && Sub3 && Sub4 && Sub5) {
      modalInput.push({ Department, Category, Sub1, Sub2, Sub3, Sub4, Sub5, Sub6: '' })
    } else if (Department && Category && Sub1 && Sub2 && Sub3 && Sub4) {
      modalInput.push({ Department, Category, Sub1, Sub2, Sub3, Sub4, Sub5: '', Sub6: '' })
    } else if (Department && Category && Sub1 && Sub2 && Sub3) {
      modalInput.push({ Department, Category, Sub1, Sub2, Sub3, Sub4: '', Sub5: '', Sub6: '' })
    } else if (Department && Category && Sub1 && Sub2) {
      modalInput.push({ Department, Category, Sub1, Sub2, Sub3: '', Sub4: '', Sub5: '', Sub6: '' })
    } else if (Department && Category && Sub1) {
      modalInput.push({ Department, Category, Sub1, Sub2: '', Sub3: '', Sub4: '', Sub5: '', Sub6: '' })
    } else if (Department && Category) {
      modalInput.push({ Department, Category, Sub1: '', Sub2: '', Sub3: '', Sub4: '', Sub5: '', Sub6: '' })
    } else if (Department) {
      modalInput.push({ Department, Category: '', Sub1: '', Sub2: '', Sub3: '', Sub4: '', Sub5: '', Sub6: '' })
    } else {
      modalInput.push({ Department: '', Category: '', Sub1: '', Sub2: '', Sub3: '', Sub4: '', Sub5: '', Sub6: '' })
    } 
    this.setState({ modalInput })
  }

  changeModalInput(index, type, value) {
    let modalInput = JSON.parse(JSON.stringify(this.state.modalInput))
    modalInput[index][type] = value
    this.setState({ modalInput })
  }

  decreaseModal(index) {
    let modalInput = JSON.parse(JSON.stringify(this.state.modalInput))
    modalInput.splice(index, 1)
    this.setState({ modalInput: [] }, () => this.setState({ modalInput }))
  }

  async addCategory() {
    let RunApi = true
    let Line = ''
    let ErrorMessage = ''
    const ModalInput = this.state.modalInput

    ModalInput.forEach((items, index) => {
      if (items.Category === '' && items.Department === '' && items.Sub1 === '' && items.Sub2 === '' && items.Sub3 === '' && items.Sub4 === '' && items.Sub5 ==='' && items.Sub6 === '') {
        RunApi = false
        Line += `${index + 1}. `
        ErrorMessage = `${language[this.props.currentLanguage].ErrorEmptySelected}`
      } if(items.Sub6 !== '' && (items.Category === '' || items.Department === '' || items.Sub1 === '' || items.Sub2 === '' || items.Sub3 === '' || items.Sub4 === '' || items.Sub5 ==='')) {
        RunApi = false
        Line += `${index + 1}. `
        ErrorMessage = `${language[this.props.currentLanguage].ErrorSelectDataError}`
      } else if (items.Sub5 !== '' && (items.Category === '' || items.Department === '' || items.Sub1 === '' || items.Sub2 === '' || items.Sub3 === '' || items.Sub4 === '')) {
        RunApi = false
        Line += `${index + 1}. `
        ErrorMessage = `${language[this.props.currentLanguage].ErrorSelectDataError}`
      } else if (items.Sub4 !== '' && (items.Category === '' || items.Department === '' || items.Sub1 === '' || items.Sub2 === '' || items.Sub3 === '')) {
        RunApi = false
        Line += `${index + 1}. `
        ErrorMessage = `${language[this.props.currentLanguage].ErrorSelectDataError}`
      } else if (items.Sub3 !== '' && (items.Category === '' || items.Department === '' || items.Sub1 === '' || items.Sub2 === '')) {
        RunApi = false
        Line += `${index + 1}. `
        ErrorMessage = `${language[this.props.currentLanguage].ErrorSelectDataError}`
      } else if (items.Sub2 !== '' && (items.Category === '' || items.Department === '' || items.Sub1 === '')) {
        RunApi = false
        Line += `${index + 1}. `
        ErrorMessage = `${language[this.props.currentLanguage].ErrorSelectDataError}`
      } else if (items.Sub1 !== '' && (items.Category === '' || items.Department === '')) {
        RunApi = false
        Line += `${index + 1}. `
        ErrorMessage = `${language[this.props.currentLanguage].ErrorSelectDataError}`
      } else if (items.Category !== '' && (items.Department === '')) {
        RunApi = false
        Line += `${index + 1}. `
        ErrorMessage = `${language[this.props.currentLanguage].DepartmentErrorMessage}`
      }
    })

    if(RunApi === true) {
      const departmentList = await postCategory(this.props.username, this.props.token, this.state.modalInput)
      if (departmentList.message === 'success') {
        this.openNotification(`${language[this.props.currentLanguage].SuccessAddCategory}`,'success')
        this.handleClose()
        this.getCategoryF()
      }
      if(departmentList.error === '该项目已存在') {
        this.openNotification(`${language[this.props.currentLanguage].ErrorExistedCategory}`,'error')
      }
    } else {
      this.openNotification(`${ErrorMessage} ${language[this.props.currentLanguage].Line}: ${Line}`,'error')
    }
  }

  async copyCategory() {
    const departmentList = await copyCategory(this.props.username, this.props.token, this.state.fromDepartment, this.state.toDepartment)
    if (departmentList.message === 'success') {
      this.handleCloseCopy()
      this.getCategoryF()
    }
  }

  async deleteCategoryF(item) {
    const r = (window.confirm(`确定要删除${item.Department}-${item.Category}-${item.Sub1}-${item.Sub2}-${item.Sub3}-${item.Sub4}-${item.Sub5}-${item.Sub6}?`));
    if (r === true) {
      const departmentList = await deleteCategory(item.ID, this.props.username, this.props.token)
      if (departmentList.message === 'Deleted successful') {
        this.getCategoryF()
      }
    }
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };
  
  render() {
    return (
      <div>
        <Header/>
        <Container fluid style={{ fontSize: '15px' }}>
          <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }} show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
          <Modal centered show={this.state.increaseModal} size="lg" onHide={this.handleClose} fullscreen={true}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {language[this.props.currentLanguage].Add}
              </Modal.Title>
              <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }} show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
              </Alert>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>{language[this.props.currentLanguage].Department}</th>
                    <th>{language[this.props.currentLanguage].Subject}</th>
                    <th>{language[this.props.currentLanguage].SubSubject}1</th>
                    <th>{language[this.props.currentLanguage].SubSubject}2</th>
                    <th>{language[this.props.currentLanguage].SubSubject}3</th>
                    <th>{language[this.props.currentLanguage].SubSubject}4</th>
                    <th>{language[this.props.currentLanguage].SubSubject}5</th>
                    <th>{language[this.props.currentLanguage].SubSubject}6</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.modalInput.map((item, index) => <tr key={index}>
                    <td><Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Department, label: item.Department }} styles={customStyle} options={this.state.initialDepartmentList} onChange={(e) => this.changeModalInput(index, 'Department', e.value)}/></td>
                    <td>
                      <div style={{ alignItems: 'center' }}>
                        <Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Category, label: item.Category }} styles={customStyle} options={this.state.initialCategoryList} onChange={(e) => this.changeModalInput(index, 'Category', e.value)}/>
                        <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal(item.Department)} variant="primary">{language[this.props.currentLanguage].Add}</Button>
                      </div>
                    </td>
                    <td>
                      <div style={{ alignItems: 'center' }}>
                        <Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Sub1, label: item.Sub1 }} styles={customStyle} options={this.state.initialSub1} onChange={(e) => this.changeModalInput(index, 'Sub1', e.value)}/>
                        <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal(item.Department, item.Category)} variant="primary">{language[this.props.currentLanguage].Add}</Button>
                      </div>
                    </td>
                    <td>
                      <div style={{ alignItems: 'center' }}>
                        <Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Sub2, label: item.Sub2 }} styles={customStyle} options={this.state.initialSub2} onChange={(e) => this.changeModalInput(index, 'Sub2', e.value)}/>
                        <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal(item.Department, item.Category, item.Sub1)} variant="primary">{language[this.props.currentLanguage].Add}</Button>
                      </div>
                    </td>
                    <td>
                      <div style={{ alignItems: 'center' }}>
                        <Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Sub3, label: item.Sub3 }} styles={customStyle} options={this.state.initialSub3} onChange={(e) => this.changeModalInput(index, 'Sub3', e.value)}/>
                        <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal(item.Department, item.Category, item.Sub1, item.Sub2)} variant="primary">{language[this.props.currentLanguage].Add}</Button>
                      </div>
                    </td>
                    <td>
                      <div style={{ alignItems: 'center' }}>
                        <Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Sub4, label: item.Sub4 }} styles={customStyle} options={this.state.initialSub4} onChange={(e) => this.changeModalInput(index, 'Sub4', e.value)}/>
                        <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal(item.Department, item.Category, item.Sub1, item.Sub2, item.Sub3)} variant="primary">{language[this.props.currentLanguage].Add}</Button>
                      </div>
                    </td>
                    <td>
                      <div style={{ alignItems: 'center' }}>
                        <Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Sub5, label: item.Sub5 }} styles={customStyle} options={this.state.initialSub5} onChange={(e) => this.changeModalInput(index, 'Sub5', e.value)}/>
                        <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal(item.Department, item.Category, item.Sub1, item.Sub2, item.Sub3, item.Sub4)} variant="primary">{language[this.props.currentLanguage].Add}</Button>
                      </div>
                    </td>
                    <td>
                      <div style={{ alignItems: 'center' }}>
                        <Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Sub6, label: item.Sub6 }} styles={customStyle} options={this.state.initialSub6} onChange={(e) => this.changeModalInput(index, 'Sub6', e.value)}/>
                        <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal(item.Department, item.Category, item.Sub1, item.Sub2, item.Sub3, item.Sub4, item.Sub5)} variant="primary">{language[this.props.currentLanguage].Add}</Button>
                        <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.decreaseModal(index)} variant="danger">{language[this.props.currentLanguage].Delete}</Button>
                      </div>
                    </td>
                  </tr>)}
                  <tr>
                    <td><Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal()} variant="primary">{language[this.props.currentLanguage].Add}</Button></td>
                  </tr>
                </tbody>
              </Table>
              <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant="danger" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</Button>
                <Button variant="success" style={{marginLeft: 10}} onClick={() => this.addCategory()}>{language[this.props.currentLanguage].Confirm}</Button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal centered show={this.state.copyModal} size="sm" onHide={this.handleCloseCopy}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              复制全项目
              </Modal.Title>
            </Modal.Header>
            <Modal.Body> 
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Form.Select style={{ width: 150, marginLeft: 10}} value={this.state.fromDepartment} onChange={(e) => this.setState({ fromDepartment: e.target.value })}>
                  <option></option>
                  {this.state.initDepartment.length > 0 && this.state.initDepartment.map((item, index) => 
                    <option value={item} key={index}>{item}</option>
                  )}
                </Form.Select>
                <div style={{ marginLeft: 10, marginRight: 10 }}>➤</div>
                <Form.Control style={{ width: 150}} value={this.state.toDepartment} onChange={(e) => this.setState({toDepartment: e.target.value })}/>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10}}>
                <Button variant="danger" onClick={() => this.handleCloseCopy()}>{language[this.props.currentLanguage].Close}</Button>
                <Button variant="success" style={{marginLeft: 10}} onClick={() => this.copyCategory()}>{language[this.props.currentLanguage].Confirm}</Button>
              </div>
            </Modal.Body>
          </Modal>
          <div style={{ display: 'flex', padding: '10px 0px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
              <h2 style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Add}{language[this.props.currentLanguage].Subject}</h2>
            </div>
            <div style={{ display: 'flex' }}>
              <Button style={{ minWidth: 80 }} onClick={() => this.setState({ copyModal: true })} variant="warning">{language[this.props.currentLanguage].Copy}</Button>
              <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.resetTable()} variant="danger">Reset</Button>
              <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.setState({ increaseModal: true })} variant="primary">{language[this.props.currentLanguage].Add}</Button>
            </div>
          </div>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {language[this.props.currentLanguage].Department}
                    <Form.Select style={{ width: 150, marginLeft: 10}} value={this.state.selectedDepartment} onChange={(e) => this.setState({ selectedDepartment: e.target.value}, () => this.getCategoryF())}>
                      <option></option>
                      {this.state.departmentList.length > 0 && this.state.departmentList.map((item, index) => 
                        <option value={item} key={index}>{item}</option>
                      )}
                    </Form.Select>
                  </div>
                </th>
                <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {language[this.props.currentLanguage].Subject}
                    {this.state.selectedDepartment !== '' &&
                  <Form.Select style={{ width: 150, marginLeft: 10}} value={this.state.selectedCategory} onChange={(e) => this.setState({ selectedCategory: e.target.value}, () => this.getCategoryF())}>
                    <option></option>
                    {this.state.categoryList.length > 0 && this.state.categoryList.map((item, index) => 
                      <option value={item} key={index}>{item}</option>
                    )}
                  </Form.Select>}
                  </div>
                </th>
                <th>{language[this.props.currentLanguage].SubSubject}1</th>
                {/* <th>{language[this.props.currentLanguage].SubSubject}2</th>
                <th>{language[this.props.currentLanguage].SubSubject}3</th>
                <th>{language[this.props.currentLanguage].SubSubject}4</th>
                <th>{language[this.props.currentLanguage].SubSubject}5</th>
                <th>{language[this.props.currentLanguage].SubSubject}6</th> */}
                <th>{language[this.props.currentLanguage].Action}</th>
              </tr>
            </thead>
            {this.state.categoryListResult.length > 0 ?
              <tbody>
                {this.state.categoryListResult.map((item, index) => <tr key={index}>
                  <td>{item.Department}</td>
                  <td>{item.Category}</td>
                  <td>{item.Sub1}</td>
                  {/* <td>{item.Sub2}</td>
                  <td>{item.Sub3}</td>
                  <td>{item.Sub4}</td>
                  <td>{item.Sub5}</td>
                  <td>{item.Sub6}</td> */}
                  <td><Button onClick={() => this.deleteCategoryF(item)} variant="danger">{language[this.props.currentLanguage].Delete}</Button></td>
                </tr>)}
              </tbody>
              :
              <>
                {this.state.loading === true && 
            <tbody>
              <tr style={{ backgroundColor: 'thite'}}>
                <td colSpan="9" style={{ fontWeight: 'bold', fontSize: '50px', textAlign: 'center' }}>{language[this.props.currentLanguage].loading}</td>
              </tr>
            </tbody>}
                {this.state.loading === false && 
              <tbody>
                <tr style={{ backgroundColor: 'lightgray'}}>
                  <td colSpan="9" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                </tr>
              </tbody>
                }
              </>}
          </Table>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (username, role, token) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: username,
          role: role,
          token: token,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Category);
  