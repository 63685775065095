import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { Container, Form, Button, Alert } from 'react-bootstrap';
// import ViewReceiptModal from '../Component/ViewReceiptModal';
import { language } from '../language';
import Select from "react-select";
import DisplayTable from '../Component/DisplayTable';
import Header from '../Component/Header';
import { exportReportExcel } from '../jsonToXlsx';
import ApproverCashOutModal from '../Component/ApproverCashOutModal';
import RedoReturnModal from '../Component/RedoReturnModal';
import ReportViewDetails from '../Component/ReportViewDetails';
import { firstApproveClaimFNC, rejectClaimFNC, secondApproveClaimFNC, EditClaim, returnClaimFNC, getClaimHistoryRecordFNC, getUser, getCategory, cashOutClaimListFNC, headerFilterFNC, exportClaimListFNC, DeleteClaim, returnCategoryFNC } from '../Api';
import moment from 'moment-timezone';
var timer

class Approvel extends React.Component {
  fileArray = [];
  constructor(props) {
    super(props);
    this.state = {
      ConsirmPass: false,
      ErrorMessage: '',
      fasttype: '',
      OpenViewReceiptModal: false,
      ClaimViewReceipt: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      ApproverModal: false,
      fromDate: moment(new Date()).subtract(2, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      claimData: [],
      AllClaimData: [],
      CompanyList: [],
      BranchList: [],
      DepartmenList: [],
      CompanyBranch: [],
      CategoryList: [],
      Sub1List: [],
      Sub2List: [],
      Sub3List: [],
      Sub4List: [],
      Sub5List: [],
      Sub6List: [],
      UserList: [],
      LatestUpdateList: [
        'Pending',
        'Approve',
        'CashOut',
        'Reject',
      ],
      fastActions: false,
      fastActionApprove2: false,
      fastActionsReject: false,
      pagetype: 'Approvel',
      SelectedFilterStatus: 'All',
      ShowFilterName: 'All',
      ShowFilterCompany: 'All',
      ShowFilterDepart: 'All',
      ShowFilterBranch: 'All',
      ShowFilterSub: 'All',
      ShowFilterSubSub: 'All',
      ShowFilterApprovalStatus: 'All',
      ShowFilterApproveAmount: 'All',
      ShowFilterAmount: 'All',
      ShowFilterApprover: 'All',
      ShowFilterAccounting: 'All',
      SelectedActions: [],
      SelectedApprove2: [],
      SelectedRejects: [],
      FilterStatus: [],
      FilterName: [],
      FilterDepartment: [],
      FilterCompany: [],
      FilterBranch: [],
      FilterSub: [],
      FilterApprovalStatus: [],
      FilterApproveAmount: [],
      FilterAmount: [],
      FilterApprove: [],
      FilterAccounting: [],
      FilterSubSub: [],
      SelectedClaimObject: {},
      ReturnClicked: false,
      ReasonText: '',
      RedoReturnModal: false,
      ReportDetailsModal: false,
      TotalAmount: {TotalAmount: 0, TotalApproveAmount: 0},
      background: '#0D6EFD',
      DisplayHistory: false,
      HistoryData: [],
      OpenFilter: true,
      FirstApprove: '',
      FirstApproveStatus: '',
      Accountant: '',
      SelectCompany: '',
      SelectBranch: [],
      SelectDepartment: [],
      SelectedSub1: '',
      SelectedSub2: '',
      SelectedSub3: '',
      SelectedSub4: '',
      SelectedSub5: '',
      SelectedSub6: '',
      SelectName: 'All',
      SelectCategory: '',
      SelectLatestUpdate: '',
      KeyinAmount: '',
      ApprovedAmount: '',
      page: 1,
      countperpage: 20,
      countpagemax: 1,
      countpagemin: 1,
      TotalPages: 1,
      RunTotalPage: [],
      BorderHide: false,
      SelectedAll: false,
      AllSelectedIDs: [],
      SelectedOptionBranch: [],
      SelectedBranchs: [],
      SelectedDeparts: [],
      SelectedOptionDepart: [],
      BooleanRemoveN: false,
    }
    this.FilterTableView = this.FilterTableView.bind(this);
    // this.WatchReceipt = this.WatchReceipt.bind(this);
    this.CloseViewReceipt = this.CloseViewReceipt.bind(this);
    this.SelectedActions = this.SelectedActions.bind(this);
    this.SelectedApprove2 = this.SelectedApprove2.bind(this);
    this.SelectedRejects = this.SelectedRejects.bind(this);
    this.OpenApproveModal = this.OpenApproveModal.bind(this);
    this.OpenRedoReturnModal = this.OpenRedoReturnModal.bind(this);
    this.ConfirmAction = this.ConfirmAction.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.RedoActions = this.RedoActions.bind(this);
    this.OpenReportDetailsModal = this.OpenReportDetailsModal.bind(this);
    this.DisplayHistorySet = this.DisplayHistorySet.bind(this);
    this.SetStatePage = this.SetStatePage.bind(this);
    this.SelectedChecked = this.SelectedChecked.bind(this);
    this.RunPageViews = this.RunPageViews.bind(this);
    this.SetStateProps = this.SetStateProps.bind(this);
    this.handleXLSX = this.handleXLSX.bind(this);
    this.SelectAll = this.SelectAll.bind(this);
    this.DeleteClaims = this.DeleteClaims.bind(this);
    this.SelectedReAllID = this.SelectedReAllID.bind(this);
  }

  async componentDidMount() {
    if(this.props.token === '') {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      window.location.href = '/';
    }

    let FilterCompany = '';
    let getUserList = '';
    if (this.props.role !== 'User') {
      getUserList = await getUser(
        this.props.username, 
        this.props.token,
      );
    } else {
      getUserList = [];
    }
    const getCompanyList = JSON.parse(this.props.CompanyData)
    this.FilteredBranch(JSON.parse(this.props.CompanyData));
    FilterCompany = getCompanyList.map((item) => item.Company)
    FilterCompany = [...new Set(FilterCompany)];
    const CheckRoleBoolean = this.props.role === 'CEO' ? true : false
    this.setState({ 
      CompanyList: FilterCompany, 
      CompanyBranch: getCompanyList,
      UserList: getUserList,
      BorderHide: CheckRoleBoolean, 
    });
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)
    if (searchState !== '') {
      searchState = `{"${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}"}`
      searchState = JSON.parse(searchState)
      const fromDate = (searchState.WholeYear === 'true') ? `${searchState.Year}-01-01` : `${searchState.Year}-${searchState.Month}-01`
      const Todate = (searchState.WholeYear === 'true') ? `${searchState.Year}-12-31` : `${searchState.Year}-${searchState.Month}-${this.GetDay(searchState.Month, searchState.Year)}`
      this.setState({ SelectCompany: searchState.Company, SelectBranch: searchState.Branch, SelectCategory: searchState.Category, fromDate , toDate: Todate, SelectLatestUpdate: searchState.LatestStatus })
    }
    this.UserCallApi();
  }

  SetStateProps(value){
    this.setState({[value]: !this.state[value]})
  }

  RunTotalPage() {
    const TotalPages = this.state.TotalPages
    let RunTotalPage = this.state.RunTotalPage
    if (this.state.page === 1 && this.state.RunTotalPage.length === 0) {
      if(TotalPages > 5) {
        for(var i=0; i < 5; i++) {
          RunTotalPage.push(i+1)
        }
        this.setState({ RunTotalPage })
      } else {
        for(var z=0; z < TotalPages; z++) {
          RunTotalPage.push(z+1)
        }
        this.setState({ RunTotalPage })
      }
    }
  }

  GetDay(Month, year) {
    if(Month === '01' || Month === '03' || Month === '05' || Month === '07' || Month === '08' || Month === '10' || Month === '12') {
      return '31'
    } else if (Month === '04' || Month === '06' || Month === '09' || Month === '11') {
      return '30'
    } else if (Month === '02'){
      return moment(`${year}-${Month}`).endOf('month').format('DD')
    }
  }

  async CallHeader() {
    this.getCategories();
    const getheader = await headerFilterFNC(this.state.fromDate, this.state.toDate, 'Approver', this.props.username, this.props.token);
    if (getheader) {
      let ArrayDepart = []
      getheader.FilterDepartment.forEach((items) => {
        ArrayDepart.push({value: items, label: items})
      })
      this.setState({ 
        FilterAccounting: getheader.FilterAccounting,
        FilterAmount: getheader.FilterAmount,
        FilterApprovalStatus: getheader.FilterApprovalStatus,
        FilterApprove: getheader.FilterApprove,
        SelectedOptionDepart: ArrayDepart,
        FilterApproveAmount: getheader.FilterApproveAmount,
        FilterBranch: getheader.FilterBranch,
        FilterCompany: getheader.FilterCompany,
        FilterDepartment: getheader.FilterDepartment,
        FilterName: getheader.FilterName,
        FilterStatus: getheader.FilterStatus,
        FilterSubSub: getheader.FilterSubSub,
      })
    }
  }

  async getCategories() {
    const getCate = await returnCategoryFNC(this.state.SelectName, this.props.username, this.props.token, this.state.SelectDepartment)
    this.setState({ 
      FilterSub: getCate,
    })
  }

  FilteredBranch(CompanyData) {
    let Branchs = []
    let FilterBranch = '';
    FilterBranch = CompanyData.map((item) => item.Branch)
    FilterBranch = [...new Set(FilterBranch)];
    FilterBranch.forEach((item) => {
      Branchs.push({value: item, label: item})
    })
    this.setState({ BranchList: FilterBranch, SelectedOptionBranch: Branchs })
  }

  // async getFilterDepartment(selectedBranch) {
  //   let FilterDepartment = [];
  //   if (selectedBranch !== '') {
  //     await this.setState({ DepartmenList: []});
  //     FilterDepartment = this.state.CompanyBranch.filter((item) => (item.Branch === selectedBranch && item.Company === this.state.SelectCompany))
  //     FilterDepartment = FilterDepartment.map((item) => item.Department)
  //     FilterDepartment = [...new Set(FilterDepartment)];
  //     this.setState({ DepartmenList: FilterDepartment, SelectBranch: selectedBranch});
  //   } else {
  //     this.setState({ 
  //       DepartmenList: [],
  //       CategoryList: [],
  //     });
  //   }
  // }

  // async getFilterCategory(selectedDepartment) {
  //   if (selectedDepartment !== '') {
  //     let FilterCategory = '';
  //     await this.setState({ CategoryList: []});
  //     const getCategoryList = await getCategory(
  //       this.props.username, 
  //       this.props.token,
  //       {
  //         department: selectedDepartment,
  //         Category: '', 
  //         sub1: '', 
  //         sub2: '', 
  //         sub3: '', 
  //         sub4: '',
  //         sub5: '',
  //       },
  //     );
  //     FilterCategory = getCategoryList.map((item) => item.Category)
  //     FilterCategory = [...new Set(FilterCategory)];
  //     this.setState({ CategoryList: FilterCategory, SelectDepartment: selectedDepartment});
  //   } 
  //   else {
  //     this.setState({ 
  //       CategoryList: [],
  //     });
  //   }
  // }

  async getFilterSub1(SelectedAns, type) {
    this.ClearSubData(type);
    const AnsArray = (type === 'SelectCategory') ? 'Sub1List' : (type === 'SelectedSub1') ? 'Sub2List' : (type === 'SelectedSub2') ? 'Sub3List' : (type === 'SelectedSub3') ? 'Sub4List' : (type === 'SelectedSub4') ? 'Sub5List' : (type === 'SelectedSub5') ? 'Sub6List' : ''
    if (SelectedAns !== '') {
      const AnsArray = (type === 'SelectCategory') ? 'Sub1List' : (type === 'SelectedSub1') ? 'Sub2List' : (type === 'SelectedSub2') ? 'Sub3List' : (type === 'SelectedSub3') ? 'Sub4List' : (type === 'SelectedSub4') ? 'Sub5List' :  (type === 'SelectedSub5') ? 'Sub6List' : ''
      let FilterSub1 = '';
      await this.setState({ [AnsArray]: []});
      const getCategoryList = await getCategory(
        this.props.username, 
        this.props.token,
        {
          department: this.state.SelectDepartment,
          Category: (type === 'SelectCategory') ? SelectedAns : this.state.SelectCategory, 
          sub1: (type === 'SelectedSub1') ? SelectedAns : this.state.SelectedSub1, 
          sub2: (type === 'SelectedSub2') ? SelectedAns : this.state.SelectedSub2, 
          sub3: (type === 'SelectedSub3') ? SelectedAns : this.state.SelectedSub3, 
          sub4: (type === 'SelectedSub4') ? SelectedAns : this.state.SelectedSub4,
          sub5: (type === 'SelectedSub5') ? SelectedAns : this.state.SelectedSub5,
        },
      );
      const Answer = (type === 'SelectCategory') ? 'Sub1' : (type === 'SelectedSub1') ? 'Sub2' : (type === 'SelectedSub2') ? 'Sub3' : (type === 'SelectedSub3') ? 'Sub4' : (type === 'SelectedSub4') ? 'Sub5' : (type === 'SelectedSub5') ? 'Sub6' :  ''
      FilterSub1 = getCategoryList.map((item) => item[Answer])
      FilterSub1 = [...new Set(FilterSub1)];
      this.setState({ [AnsArray]: FilterSub1, [type]: SelectedAns});
    } 
    else {
      this.setState({ 
        [AnsArray]: [],
      });
    }
  }

  ClearSubData(type) {
    if (type === 'SelectCategory') {
      this.setState({SelectedSub1: '', SelectedSub2: '', SelectedSub3: '', SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub1') {
      this.setState({SelectedSub2: '', SelectedSub3: '', SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub2') {
      this.setState({SelectedSub3: '', SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub3') {
      this.setState({SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub4') {
      this.setState({SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub5') {
      this.setState({SelectedSub6: ''})
    }
  }

  SelectedOption(e, type) {
    if (type === 'SelectCompany') {
      if (e.target.value === '') {
        this.setState({ SelectCompany: '', ShowFilterCompany: ''  })
        this.FilteredBranch(JSON.parse(this.props.CompanyData))
      } else {
        this.getFilterBranch(e.target.value);
        this.setState({ 
          [type]: e.target.value, 
          DepartmenList: [],
          CategoryList: [],
          ShowFilterCompany: e.target.value,
        })
      }
    } else if(type === 'SelectBranch') {
      // this.getFilterDepartment(e.target.value);
      this.setState({ 
        [type]: e.target.value,
        // CategoryList: [],
        ShowFilterBranch: e.target.value,
      })
    } else if(type === 'SelectDepartment') {
      // this.getFilterCategory(e.target.value);
      this.setState({ [type]: e.target.value, ShowFilterDepart: e.target.value })
    } else if(type === 'SelectCategory' || type === 'SelectedSub1' || type === 'SelectedSub2' || type === 'SelectedSub3' || type === 'SelectedSub4' || type === 'SelectedSub5') {
      this.getFilterSub1(e.target.value, type);
      this.setState({ [type]: e.target.value})
      if (type === 'SelectCategory') {
        this.setState({ ShowFilterSub: e.target.value })
      }
      if (type === 'SelectedSub1') {
        this.setState({ ShowFilterSubSub: e.target.value })
      }
    } else if(type === 'SelectLatestUpdate') {
      this.setState({ [type]: e.target.value, SelectedFilterStatus: e.target.value })
    } else if(type === 'SelectName') {
      if (e.target.value === '') {
        this.setState({ [type]: this.props.username}, () => {
          this.getCategories();
        })
      } 
      else {
        this.setState({ [type]: e.target.value, ShowFilterName: e.target.value }, () => {
          this.getCategories();
        })
      }
     
    } else if(type === 'fromDate') {
      this.setState({ [type]:  moment(e.target.value).format('YYYY-MM-DD')})
    } else if(type === 'toDate') {
      this.setState({ [type]:  moment(e.target.value).format('YYYY-MM-DD')})
    } 
  }

  async getFilterBranch(selectedCompany) {
    let FilterBranch = [];
    if (selectedCompany !== '') {
      await this.setState({ BranchList: []});
      FilterBranch = this.state.CompanyBranch.filter((item) => item.Company === selectedCompany)
      FilterBranch = FilterBranch.map((item) => item.Branch)
      FilterBranch = [...new Set(FilterBranch)];
      this.setState({ 
        BranchList: FilterBranch, 
      });
    } else {
      this.setState({ 
        BranchList: [], 
        DepartmenList: [],
        CategoryList: [],
        LatestUpdateList: [],
      });
    }
  }

  async UserCallApi(Action){
    this.CallHeader();
    if (Action === true) {
      this.setState({ RunTotalPage: [] })
    }
    const DataObj = {
      selectedUser: this.state.SelectName,
      selectedCompany: this.state.SelectCompany, 
      selectedBranch: this.state.SelectBranch, 
      selectedDepartment: this.state.SelectDepartment, 
      selectedCategory: this.state.SelectCategory,
      subCategory: this.state.SelectedSub1,
      subCategory2: this.state.SelectedSub2,
      subCategory3: this.state.SelectedSub3,
      subCategory4: this.state.SelectedSub4,
      subCategory5: this.state.SelectedSub5,
      subCategory6: this.state.SelectedSub6,
      claimStatus: this.state.SelectLatestUpdate,
      claimAmount: this.state.KeyinAmount,
      approvedAmount: this.state.ApprovedAmount,
      FirstApprove: this.state.FirstApprove,
      FirstApproveStatus: this.state.FirstApproveStatus,
      Accountant: this.state.Accountant,
    }
    const fromDate = this.state.fromDate
    const toDate = this.state.toDate
    const getData = await cashOutClaimListFNC(fromDate, toDate, DataObj, 'Approver', this.props.username, this.state.page, this.state.countperpage, this.props.token)
    if(getData.message === 'No record for these day') {
      this.setState({ claimData: [], TotalAmount: {TotalAmount: 0, TotalApproveAmount: 0} })
    } else {
      this.setState({ claimData: getData.claimList, AllClaimData: getData.claimList, TotalAmount: getData.totalAmtOfClaim, TotalPages: Number(getData.totalPage) }, () => {
        // this.CalculateTotalAmount(this.state.claimData);
        if (this.state.claimData.length > 0) {
          this.setState({ OpenFilter: false })
        }
        this.RunTotalPage();
      })
      if (getData.claimList.length > 0) {
        this.setState({
          countpagemax: Math.ceil(
            getData.claimList[0].FullCount / this.state.countperpage,
          ),
        });
      } else {
        this.setState({
          countpagemax: 1,
          page: 1,
        });
      }
    }
  }

  // CalculateTotalAmount(claimData) {
  //   let Total = {
  //     Amount: 0,
  //     AmountApprove: 0,
  //   }
  //   claimData.forEach((items) => {
  //     Total.Amount += Number(items.Amount)
  //     Total.AmountApprove += Number(items.ApproveAmount)
  //   })
  //   this.setState({ TotalAmount: Total })
  // }

  async getClaimData(Action) {
    this.setState({
      // SelectedFilterStatus: 'All',
      // ShowFilterName: 'All',
      // ShowFilterCompany: 'All',
      // ShowFilterDepart: 'All',
      // ShowFilterBranch: 'All',
      // ShowFilterSub: 'All',
      // ShowFilterSubSub: 'All',
      // ShowFilterApprovalStatus: 'All',
      // ShowFilterApproveAmount: 'All',
      // ShowFilterAmount: 'All',
      // ShowFilterApprover: 'All',
      // ShowFilterAccounting: 'All',
      page: 1,
      countpagemax: 1,
      countpagemin: 1,
      // SelectName: 'All',
      // SelectCompany: '', 
      // SelectBranch: '', 
      // SelectDepartment: '', 
      // SelectCategory: '',
      // SelectedSub1: '',
      // SelectedSub2: '',
      // SelectedSub3: '',
      // SelectedSub4: '',
      // SelectedSub5: '',
      // SelectedSub6: '',
      // SelectLatestUpdate: '',
      // KeyinAmount: '',
      // ApprovedAmount: '',
      // FirstApprove: '',
      // FirstApproveStatus: '',
      // Accountant: '',
    })
    this.UserCallApi(Action);
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  fastActions(type) {
    if(type === 'Approve') {
      this.setState({ fastActions: !this.state.fastActions, SelectedActions: [], fasttype: 'Approve', fastActionsReject: false, SelectedRejects: [], SelectedApprove2: [], fastActionApprove2: false})
    } else if(type === 'Approve2') {
      this.setState({ fastActionApprove2: !this.state.fastActionApprove2, SelectedApprove2: [], fasttype: 'Approve2', fastActionsReject: false, SelectedRejects: [], SelectedActions: [], fastActions: false })
    } else {
      this.setState({ fastActionsReject: !this.state.fastActionsReject, SelectedRejects: [], fasttype: 'Reject', SelectedActions: [], fastActions: false, SelectedApprove2: [], fastActionApprove2: false })
    }
  }

  // WatchReceipt(ClaimReceipts) {
  //   this.setState({ ClaimViewReceipt: ClaimReceipts } , () => {
  //     this.setState({ OpenViewReceiptModal: true })
  //   })
  // }

  CloseViewReceipt() {
    this.setState({ OpenViewReceiptModal: false, ClaimViewReceipt: '' })
  }

  FilterTableView(e, Selecttype) {
    if(Selecttype === 'ApproverOption') {
      this.setState({ SelectedFilterStatus: e, SelectLatestUpdate: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'ShowFilterName') {
      this.setState({ ShowFilterName: e, SelectName: e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Department') {
      this.setState({ ShowFilterDepart: [e], SelectDepartment: (e === 'All') ? '' : [e] }, () => { this.UserCallApi(true); this.getCategories(); })
    }
    if(Selecttype === 'Company') {
      this.setState({ ShowFilterCompany: e, SelectCompany: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Branch') {
      this.setState({ ShowFilterBranch: e, SelectBranch: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Category') {
      this.setState({ ShowFilterSub: e, SelectCategory: (e === 'All') ? '' : e  }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Sub1') {
      this.setState({ ShowFilterSubSub: e, SelectedSub1: (e === 'All') ? '' : e  }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Amount') {
      this.setState({ ShowFilterAmount: e, KeyinAmount: (e === 'All') ? '' : e  }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'FirstApprove') {
      this.setState({ ShowFilterApprover: e, FirstApprove: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'FirstApproveStatus') {
      this.setState({ ShowFilterApprovalStatus: e, FirstApproveStatus: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'SelectedSub1') {
      this.setState({ ShowFilterSubSub: e, SelectedSub1: (e === 'All') ? '' : e}, () => { this.UserCallApi(true); })
    }
    // if(Selecttype === 'SecondApprove') {
    //   this.setState({ ShowFilterSecondApprover: e, SecondApprove: (e === 'All') ? '' : e }, () => { this.UserCallApi(); })
    // }
    // if(Selecttype === 'SecondApproveStatus') {
    //   this.setState({ ShowFilterSecondApprovalStatus: e, SecondApproveStatus: (e === 'All') ? '' : e }, () => { this.UserCallApi(); })
    // }
    if(Selecttype === 'ApproveAmount') {
      this.setState({ ShowFilterApproveAmount: e, ApprovedAmount: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Accountant') {
      this.setState({ ShowFilterAccounting: e, Accountant: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    this.setState({ page: 1 })
    // if(SelectedFilterStatus !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Status === SelectedFilterStatus);
    // }
    // if(ShowFilterName !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Name === ShowFilterName);
    // }
    // if(ShowFilterDepart !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Department === ShowFilterDepart);
    // }
    // if(ShowFilterCompany !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Company === ShowFilterCompany);
    // }
    // if(ShowFilterSub !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Category === ShowFilterSub);
    // }
    // if(ShowFilterSubSub !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Sub1 === ShowFilterSubSub);
    // }
    // if(ShowFilterAmount !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Amount === ShowFilterAmount);
    // }
    // if(ShowFilterApprover !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.FirstApprove === ShowFilterApprover);
    // }
    // if(ShowFilterApprovalStatus !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.FirstApproveStatus === ShowFilterApprovalStatus);
    // }
    // if(ShowFilterSecondApprover !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.SecondApprove === ShowFilterSecondApprover);
    // }
    // if(ShowFilterSecondApprovalStatus !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.SecondApproveStatus === ShowFilterSecondApprovalStatus);
    // }
    // if(ShowFilterApproveAmount !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.ApproveAmount === ShowFilterApproveAmount);
    // }
    // if(ShowFilterAccounting !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Accountant === ShowFilterAccounting);
    // }
    // if(ShowFilterBranch !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Branch === ShowFilterBranch);
    // }
    // this.setState({ claimData: AllClaimData })
  }

  SelectedActions(users) {
    let SelectedActions = JSON.parse(JSON.stringify(this.state.SelectedActions))
    if(SelectedActions.includes(users)) {
      SelectedActions = SelectedActions.filter(function (el) {
        return el !== users
      });
    } else {
      SelectedActions.push(users)
    }
    this.setState({ SelectedActions })
  }

  SelectAll(e) {
    const claimData = this.state.claimData
    let ArrayID = []
    if (e.target.checked) {
      claimData.forEach((items) => {
        ArrayID.push(items.ID)
      })
      this.setState({ SelectedAll: true })
    } else {
      ArrayID = []
      this.setState({ SelectedAll: false })
    }
    if (this.state.fastActionsReject === true) {
      this.setState({ SelectedRejects: ArrayID })
    } else if (this.state.fastActions === true) {
      this.setState({ SelectedActions: ArrayID })
    }
    this.setState({ AllSelectedIDs: ArrayID })
  }

  SelectedReAllID(users) {
    let AllSelectedIDs = JSON.parse(JSON.stringify(this.state.AllSelectedIDs))
    if(AllSelectedIDs.includes(users)) {
      AllSelectedIDs = AllSelectedIDs.filter(function (el) {
        return el !== users
      });
    } else {
      AllSelectedIDs.push(users)
    }
    this.setState({ AllSelectedIDs })
  }

  SelectedApprove2(users) {
    let SelectedApprove2 = this.state.SelectedApprove2
    if(SelectedApprove2.includes(users)) {
      SelectedApprove2 = SelectedApprove2.filter(function (el) {
        return el !== users
      });
    } else {
      SelectedApprove2.push(users)
    }
    this.setState({ SelectedApprove2 })
  }

  SelectedRejects(users) {
    let SelectedRejects = JSON.parse(JSON.stringify(this.state.SelectedRejects))
    if(SelectedRejects.includes(users)) {
      SelectedRejects = SelectedRejects.filter(function (el) {
        return el !== users
      });
    } else {
      SelectedRejects.push(users)
    }
    this.setState({ SelectedRejects })
  }

  handleClose() {
    this.setState({
      AllSelectedIDs: [],
      ReasonText: '',
      ReportDetailsModal: false,
      RedoReturnModal: false,
      ReturnClicked: false,
      SelectedActions: [],
      SelectedRejects: [],
      SelectedApprove2: [],
      ApproverModal: false,
      fastActionsReject: false,
      fastActions: false,
      fastActionApprove2: false,
      OpenViewReceiptModal: false,
      ClaimViewReceipt: '',
      SelectedAll: false,
      SelectedFilterStatus: 'All',
      SelectedClaimArray: [],
    })
    this.CloseHistorySet();
  }

  OpenApproveModal(Selectedclaim) {
    this.setState({ ApproverModal: true, SelectedClaimObject: Selectedclaim }, ()=> {
      this.DisplayHistorySet();
    })
  }

  OpenRedoReturnModal(Selectedclaim) {
    this.setState({ RedoReturnModal: true, SelectedClaimObject: Selectedclaim }, ()=> {
      this.DisplayHistorySet();
    })
  }

  OpenReportDetailsModal(Selectedclaim) {
    this.setState({ ReportDetailsModal: true, SelectedClaimObject: Selectedclaim }, ()=> {
      this.DisplayHistorySet();
    })
  }

  async ConfirmAction(Type, SelectedClaimID) {
    let SelectedClaimArray = []
    SelectedClaimArray.push(SelectedClaimID)
    if(Type === 'Approve') {
      const ApproveClaim = await firstApproveClaimFNC(SelectedClaimArray, this.props.username, this.props.token);
      if(ApproveClaim.error === 'Don\'t have authorize to update/reject claim') {
        this.openNotification('失误: 您没有此功能的权限','error')
        this.handleClose();
      } else if (ApproveClaim.error === 'Cannot approve / invalid approve') {
        this.openNotification('失误: 请联系另一位会计批准','error')
        this.handleClose();
      } else if (ApproveClaim.message === 'Successful update') {
        this.openNotification('成功: 成功批准申请','success')
        this.handleClose();
        this.UserCallApi(true);
      }
    } else if(Type === 'Approve2') {
      const r = (window.confirm('确定要批准第二申请吗？'));
      if (r === true) {
        const SecondApproveClaim = await secondApproveClaimFNC(SelectedClaimArray, this.props.username, this.props.token)
        if(SecondApproveClaim.error === 'Don\'t have authorize to update/reject claim') {
          this.openNotification('失误: 您没有此功能的权限','error')
          this.handleClose();
        } else if (SecondApproveClaim.error === 'Cannot approve / invalid approve') {
          this.openNotification('失误: 请联系另一位会计批准','error')
          this.handleClose();
        } else if (SecondApproveClaim.error === 'Cannot approve due to personalQuota') {
          this.openNotification('失误: 请联系另一位会计批准, 个人配额低于申请金额','error')
          this.handleClose();
        } else if (SecondApproveClaim.message === 'Successful update') {
          this.openNotification('成功: 成功批准申请','success')
          this.handleClose();
          this.UserCallApi(true);
        }
      } else {
        return null;
      }
    } else if (Type === 'return') {
      this.setState({ ReturnClicked: !this.state.ReturnClicked })
    } else if (Type === 'ConfirmReturn') {
      if (this.state.ReasonText === '') {
        this.openNotification(`${language[this.props.currentLanguage].ErrorReason}`,'error')
      } else {
        let ClaimIDArray = []
        ClaimIDArray.push(SelectedClaimID)
        const ReturnClaim = await returnClaimFNC(ClaimIDArray, this.state.ReasonText, this.props.username, this.props.token)
        if (ReturnClaim.message === 'Return successful'){
          this.openNotification(`${language[this.props.currentLanguage].SuccessReturn}`,'success')
          this.handleClose();
          this.UserCallApi(true);
        }
      }
    } else if (Type === 'SetReason') {
      this.setState({ ReasonText: SelectedClaimID })
    } else {
      const r = (window.confirm('确定要驳回选择申请吗？'));
      if (r === true) {
        const RejectClaim = await rejectClaimFNC(SelectedClaimArray, this.props.username, this.props.token);
        if(RejectClaim.error === 'Don\'t have authorize to update/reject claim') {
          this.openNotification('失误: 您没有此功能的权限','error')
        } else {
          this.openNotification('成功: 成功驳回申请','success')
          this.handleClose();
          this.UserCallApi(true);
        }
      } else {
        return null;
      }
    }
  }

  async ActionsAccount(SelectedActions, type) {
    if(type === 'Approve') {
      const r = (window.confirm('确定要批准选择申请吗？'));
      if (r === true) {
        const ApproveClaim = await firstApproveClaimFNC(SelectedActions, this.props.username, this.props.token);
        if(ApproveClaim.error === 'Don\'t have authorize to update/reject claim') {
          this.openNotification('失误: 您没有此功能的权限','error')
          this.handleClose();
        } else if (ApproveClaim.error === 'Cannot approve / invalid approve') {
          this.openNotification('失误: 请联系另一位会计批准','error')
          this.handleClose();
        } else if (ApproveClaim.message === 'Successful update') {
          this.openNotification('成功: 成功批准申请','success')
          this.handleClose();
          this.UserCallApi(true);
        }
      } else {
        return null;
      }
    } else if (type === 'Approve2') {
      const r = (window.confirm('确定要批准2选择申请吗？'));
      if (r === true) {
        const SecondApproveClaim = await secondApproveClaimFNC(SelectedActions, this.props.username, this.props.token)
        if(SecondApproveClaim.error === 'Don\'t have authorize to update/reject claim') {
          this.openNotification('失误: 您没有此功能的权限','error')
          this.handleClose();
        } else if (SecondApproveClaim.error === 'Cannot approve / invalid approve') {
          this.openNotification('失误: 请联系另一位会计批准','error')
          this.handleClose();
        } else if (SecondApproveClaim.message === 'Successful update') {
          this.openNotification('成功: 成功批准申请','success')
          this.handleClose();
          this.UserCallApi(true);
        }
      } else {
        return null;
      }
    } else {
      const r = (window.confirm('确定要驳回选择申请吗?'));
      if (r === true) {
        const RejectClaim = await rejectClaimFNC(SelectedActions, this.props.username, this.props.token);
        if(RejectClaim.error === 'Don\'t have authorize to update/reject claim') {
          this.openNotification('失误: 您没有此功能的权限','error')
        } else {
          this.openNotification('成功: 成功驳回申请','success')
          this.handleClose();
          this.UserCallApi(true);
        }
      } else {
        return null;
      }
    }
  }

  async RedoActions(SelectedClaim) {
    const r = (window.confirm(`${language[this.props.currentLanguage].ConfirmOverrule}`));
    if (r === true) {
      const RedoClaim = await EditClaim({ID: SelectedClaim}, this.state.SelectedClaimObject.Status ,this.props.username, this.props.token)
      if(RedoClaim.error === 'database error') {
        this.openNotification(`${language[this.props.currentLanguage].DatabaseError}`,'error')
      }
      if(RedoClaim.message === 'Redo successful') {
        this.openNotification('成功: 成功Redo/撤回申请','success')
        this.handleClose();
        this.UserCallApi(true);
      }
    } else {
      return null;
    }
  }

  async DisplayHistorySet() {
    // this.setState({ DisplayHistory: !this.state.DisplayHistory }, async() => {
    const EditID = this.state.SelectedClaimObject.ID
    const callHistoryData = await getClaimHistoryRecordFNC(EditID, this.props.username, this.props.token)
    if(callHistoryData.error === 'database error') {
      this.openNotification(`${language[this.props.currentLanguage].DatabaseError}`,'error')
      this.CloseHistorySet();
    } else {
      this.setState({ HistoryData: callHistoryData })
    }
    // })
  }

  CloseHistorySet() {
    this.setState({ HistoryData: [], DisplayHistory: false })
  }

  ClearFilter() {
    this.setState({ 
      // SelectedFilterStatus: 'All',
      // ShowFilterName: 'All',
      // ShowFilterCompany: 'All',
      // ShowFilterDepart: 'All',
      // ShowFilterBranch: 'All',
      // ShowFilterSub: 'All',
      // ShowFilterSubSub: 'All',
      // ShowFilterApprovalStatus: 'All',
      // ShowFilterApproveAmount: 'All',
      // ShowFilterAmount: 'All',
      // ShowFilterApprover: 'All',
      // ShowFilterAccounting: 'All',
      SelectCompany: '',
      SelectBranch: [],
      SelectDepartment: [],
      SelectedSub1: '',
      SelectedSub2: '',
      SelectedSub3: '',
      SelectedSub4: '',
      SelectedSub5: '',
      SelectedSub6: '',
      SelectName: 'All',
      SelectCategory: '',
      SelectLatestUpdate: '',
      KeyinAmount: '',
      ApprovedAmount: '',

      FirstApprove: '',
      FirstApproveStatus: '',
      Accountant: '',
    })
  }

  SetStatePage(Type) {
    if (Type === 'Next') {
      this.setState({ page: this.state.page + 1 },() => {
        this.changePage();
      })
    } else if (Type === 'previous') {
      this.setState({ page: this.state.page - 1 },() => { this.changePage(this.state.page);})
    } else {
      this.setState({ page: Type },() => { this.changePage(this.state.page);})
    }
  }

  changePage() {
    let RunTotalPage = this.state.RunTotalPage
    const TotalPages = this.state.TotalPages
    let Front = RunTotalPage[0]
    let Back = RunTotalPage[RunTotalPage.length - 1]
    if (TotalPages > 5 && this.state.page === Back && this.state.page !== Number(TotalPages)) {
      if (Number(TotalPages) - Back >= 2) {
        const AfterFilter = RunTotalPage.filter(item => item !== Front && item !== RunTotalPage[1]);
        AfterFilter.push(Back + 1)
        AfterFilter.push(Back + 2)
        this.setState({ RunTotalPage: AfterFilter })
      } else if (Number(TotalPages) - Back === 1) {
        const AfterFilter = RunTotalPage.filter(item => item !== Front);
        AfterFilter.push(Back + 1)
        this.setState({ RunTotalPage: AfterFilter })
      }
    }
    if (TotalPages > 5 && this.state.page === Front && this.state.page !== 1) {
      if (Front - 1 >= 2) {
        const AfterFilter = RunTotalPage.filter(item => item !== Back && item !== RunTotalPage[RunTotalPage.length - 2]);
        AfterFilter.unshift(Front - 1)
        AfterFilter.unshift(Front - 2)
        this.setState({ RunTotalPage: AfterFilter })
      } else if (Front - 1 === 1) {
        const AfterFilter = RunTotalPage
        AfterFilter.unshift(Front - 1)
        this.setState({ RunTotalPage: AfterFilter })
      }
    }
    if (this.state.page === 1 && RunTotalPage.length === 5) {
      this.setState({
        RunTotalPage: [],
      })
    }
    if (this.state.page === TotalPages && RunTotalPage.length === 5) {
      this.setState({ RunTotalPage: [] })
      let AddArray = []
      AddArray.push(Number(TotalPages) - 4)
      AddArray.push(Number(TotalPages) - 3)
      AddArray.push(Number(TotalPages) - 2)
      AddArray.push(Number(TotalPages) - 1)
      AddArray.push(Number(TotalPages))
      this.setState({ RunTotalPage: AddArray })
    }
    this.setState({ claimData: [] }, () => {
      this.UserCallApi();
    });
  }

  RunPageViews(Pages) {
    this.setState({ page: Pages },() => { this.UserCallApi(true) })
  }

  SelectedChecked(SelectedIDs) {
    let SelectedActions = this.state.SelectedActions
    if(SelectedActions.includes(SelectedIDs)) {
      SelectedActions = SelectedActions.filter(function (el) {
        return el !== SelectedIDs
      });
    } else {
      SelectedActions.push(SelectedIDs)
    }
    this.setState({ SelectedActions })
  }

  async handleXLSX(){
    // const getExportData = await exportClaimListFNC();
    const DataObj = {
      selectedUser: this.state.SelectName,
      selectedCompany: this.state.SelectCompany, 
      selectedBranch: this.state.SelectBranch, 
      selectedDepartment: this.state.SelectDepartment, 
      selectedCategory: this.state.SelectCategory,
      subCategory: this.state.SelectedSub1,
      subCategory2: this.state.SelectedSub2,
      subCategory3: this.state.SelectedSub3,
      subCategory4: this.state.SelectedSub4,
      subCategory5: this.state.SelectedSub5,
      subCategory6: this.state.SelectedSub6,
      claimStatus: this.state.SelectLatestUpdate,
      claimAmount: this.state.KeyinAmount,
      approvedAmount: this.state.ApprovedAmount,
      FirstApprove: this.state.FirstApprove,
      FirstApproveStatus: this.state.FirstApproveStatus,
      Accountant: this.state.Accountant,
    }
    const getExportData = await exportClaimListFNC(this.state.fromDate, this.state.toDate, DataObj, 'Approver', this.props.username, this.props.token);
    await exportReportExcel( getExportData.claimList, this.state.fromDate, this.state.toDate, this.props.currentLanguage, 'Approval', this.state.BooleanRemoveN)
  }

  async DeleteClaims(SelectedClaim) {
    const r = (window.confirm(`${language[this.props.currentLanguage].DeleteConfirmation}`));
    if (r === true) {
      const deleteclaim = await DeleteClaim([SelectedClaim.ID], this.props.username, this.props.token);
      if (deleteclaim.message === 'Accountant don\'t have authorize to delete claim') {
        this.openNotification(`${language[this.props.currentLanguage].Fails}`,'error')
      } else {
        if (deleteclaim.message === 'Successful deleted') {
          this.openNotification(`${language[this.props.currentLanguage].SuccessDeleted}`,'success')
          this.handleClose();
          this.UserCallApi(true);
        }
      }
    } else {
      return null;
    }
  }

  async DeleteAccount(SelectedActions) {
    const r = (window.confirm(`${language[this.props.currentLanguage].DeleteConfirmation}`));
    if (r === true) {
      const deleteclaim = await DeleteClaim(SelectedActions, this.props.username, this.props.token);
      if (deleteclaim.message === 'Accountant don\'t have authorize to delete claim') {
        this.openNotification(`${language[this.props.currentLanguage].Fails}`,'error')
      } else {
        if (deleteclaim.message === 'Successful deleted') {
          this.openNotification(`${language[this.props.currentLanguage].SuccessDeleted}`,'success')
          this.handleClose();
          this.UserCallApi(true);
        }
      }
    } else {
      return null;
    }
  }

  handleChangePackageNumber = OptionsPackageNumber => {
    let DepartArray = []
    OptionsPackageNumber.forEach((items) => {
      DepartArray.push(items.value)
    })
    this.setState({ SelectedDeparts: OptionsPackageNumber, SelectDepartment: DepartArray }, () => {this.getCategories()})
  }

  handleChangePackageBranch = OptionsPackageNumber => {
    let BranchArray = []
    OptionsPackageNumber.forEach((items) => {
      BranchArray.push(items.value)
    })
    this.setState({ SelectedBranchs: OptionsPackageNumber, SelectBranch: BranchArray })
  }

  render() {
    return (
      <div>
        <Header/>
        <Container fluid style={{ fontSize: '12px'}}>
          <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
          <RedoReturnModal SelectedClaimObject={this.state.SelectedClaimObject} handleClose={this.handleClose} RedoActions={this.RedoActions} RedoReturnModal={this.state.RedoReturnModal} HistoryData={this.state.HistoryData} />
          {this.state.ReportDetailsModal === true && 
          <ReportViewDetails ReportModal={this.state.ReportDetailsModal} SelectedClaimObject={this.state.SelectedClaimObject} h handleClose={this.handleClose} DisplayHistory={this.state.DisplayHistory} DisplayHistorySet={this.DisplayHistorySet} HistoryData={this.state.HistoryData}/>
          }
          <ApproverCashOutModal ApproverModal={this.state.ApproverModal} SelectedClaimObject={this.state.SelectedClaimObject} ConsirmPass={this.state.ConsirmPass} Password={''} ErrorMessage={this.state.ErrorMessage} ReasonText={this.state.ReasonText} uploadloading={this.state.uploadloading} ReturnClicked={this.state.ReturnClicked} handleClose={this.handleClose} ConfirmAction={this.ConfirmAction} PageType={this.state.pagetype} showNotification={this.state.showNotification} notificationType={this.state.notificationType} notificationMessage={this.state.notificationMessage} DisplayHistory={this.state.DisplayHistory} DisplayHistorySet={this.DisplayHistorySet} HistoryData={this.state.HistoryData}/>
          {/* <ViewReceiptModal OpenViewReceiptModal={this.state.OpenViewReceiptModal} CloseViewReceipt={this.CloseViewReceipt} ClaimsData={this.state.ClaimViewReceipt} DisplayHistory={this.state.DisplayHistory} DisplayHistorySet={this.DisplayHistorySet} HistoryData={this.state.HistoryData}/> */}
          <div className='ApproverCashOutHeaderCover'>
            <div className='ApproverCashOutHeaderdesign'>
              <h2 style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Approve}</h2>
              <Form.Control value={this.state.fromDate} onChange={(e) => this.setState({ fromDate: e.target.value })} type="date" style={{ marginRight: 10, width: 140, minWidth: 140 }}/>
              <div style={{ marginRight: 10 }}>-</div>
              <Form.Control value={this.state.toDate} onChange={(e) => this.setState({ toDate: e.target.value })} type="date" style={{ marginRight: 10, width: 140, minWidth: 140 }}/>
              {/* <Button variant="success" onClick={() => this.getClaimData()}>{language[this.props.currentLanguage].Search}</Button> */}
              <div className="dcc button ClaimSearch"
                style={{ backgroundColor: this.state.background,
                  fontWeight: 'bold', padding: 7 , cursor: 'pointer', borderRadius: 5, paddingLeft: 12, paddingRight: 12 }} 
                onMouseEnter={() => {
                  this.setState({ background: '#00B79B' });
                }}
                onMouseLeave={() => {
                  this.setState({ background: '#0D6EFD' });
                }}
                variant="success" onClick={() => this.getClaimData(true)}>
                <span>
                  {language[this.props.currentLanguage].Search}
                </span>
              </div>
            </div>
            {this.state.claimData.length > 0 && 
            <div className='dcc' style={{ marginLeft: 5 }}>
              {(this.state.fastActions && this.state.SelectedActions.length > 0) &&
                <Button style={{ marginRight: 10}} onClick={()=> this.ActionsAccount(this.state.SelectedActions, 'Approve')}>{language[this.props.currentLanguage].Approve}</Button>
              }
              <Button variant={this.state.BorderHide ? "success" :"secondary"} style={{ marginRight: 20}} 
                onClick={()=> this.setState({BorderHide: !this.state.BorderHide})}>{this.state.BorderHide ? language[this.props.currentLanguage].Expand : language[this.props.currentLanguage].Minimize}</Button>
              <Button style={{ marginRight: 10}} onClick={()=> this.ActionsAccount(this.state.AllSelectedIDs, 'Approve')}>{language[this.props.currentLanguage].FastApprove}</Button>
              <Button style={{ marginRight: 10, backgroundColor: 'red' }} onClick={()=> this.DeleteAccount(this.state.AllSelectedIDs, 'Multi')}>{language[this.props.currentLanguage].FastDelete}</Button>
              {this.props.role === 'CEO' && 
                <Button style={{ marginRight: 10, backgroundColor: 'red' }}  onClick={()=> this.ActionsAccount(this.state.AllSelectedIDs, 'Reject')}>{language[this.props.currentLanguage].FastReject}</Button>
              }
              {/* <Button style={{ marginRight: 10, backgroundColor: (this.state.fastActions === true) ? 'blue' : 'green' }} 
                onClick={()=> this.fastActions('Approve')}>{(this.state.fastActions === true) ? `${language[this.props.currentLanguage].CancelFastApprove}` : `${language[this.props.currentLanguage].FastApprove}`}</Button> */}
              {/* {(this.state.fastActionApprove2 && this.state.SelectedApprove2.length > 0) &&
                <Button style={{ marginRight: 10}} onClick={()=> this.ActionsAccount(this.state.SelectedApprove2, 'Approve2')}>{language[this.props.currentLanguage].SecondApprover}</Button>
              }
              <Button style={{ marginRight: 10, backgroundColor: (this.state.fastActionApprove2 === true) ? 'blue' : 'green' }} 
                onClick={()=> this.fastActions('Approve2')}>{(this.state.fastActionApprove2 === true) ? `${language[this.props.currentLanguage].CancelFastSecondApprove}` : `${language[this.props.currentLanguage].FastSecondApprove}`}</Button> */}
              {/* {(this.state.fastActionsReject && this.state.SelectedRejects.length > 0) &&
                <Button style={{ marginRight: 10}} onClick={()=> this.ActionsAccount(this.state.SelectedRejects, 'Reject')}>{language[this.props.currentLanguage].Reject}</Button>
              } */}
              {/* <Button style={{ marginRight: 10, backgroundColor: (this.state.fastActionsReject === true) ? 'blue' : 'red' }}
                onClick={()=> this.fastActions('Reject')}>{(this.state.fastActionsReject === true) ? `${language[this.props.currentLanguage].CancelFastReject}` : `${language[this.props.currentLanguage].FastReject}`}</Button> */}
            </div>
            }
          </div>
          {this.state.OpenFilter && 
        <>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%'}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5}}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto',  width: '25%' }}>{language[this.props.currentLanguage].Company}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} value={this.state.ShowFilterCompany} onChange={(e) => this.SelectedOption(e, 'SelectCompany')}>
                    <option value=''></option>
                    {this.state.CompanyList.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Branch}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  {/* <Form.Select style={{ width: 250}} value={this.state.ShowFilterBranch} onChange={(e) => this.SelectedOption(e, 'SelectBranch')}>
                    <option value=''></option>
                    {this.state.BranchList.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select> */}
                  {this.state.SelectedOptionBranch.length > 0 && 
                  <div style={{ width: 250, maxWidth: 250 }}>
                    <Select closeMenuOnSelect={false} isMulti options={this.state.SelectedOptionBranch} value={this.state.SelectedBranchs} onChange={this.handleChangePackageBranch}/>
                  </div>
                  }
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Subject}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} value={this.state.ShowFilterSub} onChange={(e) => this.SelectedOption(e, 'SelectCategory')}>
                    <option value=''></option>
                    {this.state.FilterSub.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 2</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub2')}>
                    <option value=''></option>
                    {this.state.Sub2List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 4</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub4')}>
                    <option value=''></option>
                    {this.state.Sub4List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 6</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub6')}>
                    <option value=''></option>
                    {this.state.Sub6List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div> */}
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Amount}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Control  value={this.state.KeyinAmount} readOnly={false} style={{ width: 250, textAlign: 'center', backgroundColor: 'white'  }} onChange={(e) => this.setState({ KeyinAmount: e.target.value })}/>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, marginBottom: 10}}>
              <div  style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Name}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  {this.props.role === 'User' && 
                      <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectName')} disabled={true}>
                        <option value={this.props.username}>{this.props.username}</option>
                      </Form.Select>
                  }
                  {this.props.role !== 'User' && 
                      <Form.Select style={{ width: 250}} value={this.state.ShowFilterName} onChange={(e) => this.SelectedOption(e, 'SelectName')}>
                        <option value='All'>{language[this.props.currentLanguage].All}</option>
                        {this.props.role === 'Account' && <option value={this.props.username}>{this.props.username}</option>}
                        {this.state.UserList.map((item, index) => 
                          <option key={index} value={item.ID}>{item.Name}</option>
                        )}
                      </Form.Select>
                  }
                </div>
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Department}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  {/* <Form.Select style={{ width: 250}} value={this.state.ShowFilterDepart} onChange={(e) => this.SelectedOption(e, 'SelectDepartment')}>
                    <option value=''></option>
                    {this.state.FilterDepartment.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select> */}
                  {this.state.SelectedOptionDepart.length > 0 && 
                  <div style={{ width: 250, maxWidth: 250 }}>
                    <Select closeMenuOnSelect={false} isMulti options={this.state.SelectedOptionDepart} value={this.state.SelectedDeparts} onChange={this.handleChangePackageNumber}/>
                  </div>
                  }
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 1</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub1')}>
                    <option value=''></option>
                    {this.state.Sub1List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 3</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub3')}>
                    <option value=''></option>
                    {this.state.Sub3List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 5</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub5')}>
                    <option value=''></option>
                    {this.state.Sub5List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div> */}
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].LatestStatus}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} value={this.state.SelectedFilterStatus} onChange={(e) => this.SelectedOption(e, 'SelectLatestUpdate')}>
                    <option value=''></option>
                    {this.state.LatestUpdateList.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{'Page'}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} value={this.state.countperpage} onChange={(e) => this.setState({ countperpage: e.target.value })}>
                    <option value='20'>20</option>
                    <option value='30'>30</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </Form.Select>
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].ApprovedAmount}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Control  value={this.state.ApprovedAmount} readOnly={true} style={{ width: 250, textAlign: 'center', backgroundColor: 'white'  }}/>
                </div>
              </div> */}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginRight: '13%',  marginLeft: '13%', justifyContent: 'space-between'}}>
            <div className="dcc button"
              style={{ backgroundColor: this.state.background,
                fontWeight: 'bold', padding: 7 , cursor: 'pointer', borderRadius: 5, width: 200, marginBottom: 10, marginTop: 5 }}
              variant="success" onClick={() => this.getClaimData(true)}>
              <span>
                {language[this.props.currentLanguage].Search}
              </span>
            </div>
          </div>
        </>
          }
          {this.state.OpenFilter === false && 
        <div style={{ display: 'flex', flexDirection: 'row', marginRight: '5%', justifyContent: 'space-between'}}>
          <div className="dcc button"
            style={{ backgroundColor: this.state.background,
              fontWeight: 'bold', padding: 7 , cursor: 'pointer', borderRadius: 5, width: 200, marginBottom: 10 }}
            variant="success" onClick={() => this.setState({ OpenFilter: !this.state.OpenFilter }, () => {
              // this.ClearFilter();
            })}>
            <span>
              {'OpenFilter'}
            </span>
          </div>
          {/* <div className="dcc button"
            style={{ backgroundColor: this.state.background,
              padding: 7 , cursor: 'pointer', borderRadius: 5, width: 200, marginBottom: 10 }} 
            variant="success" onClick={() => this.handleXLSX()}>
            <span>
              {'XLSX'}
            </span>
          </div> */}
        </div>}
          <div className='dcc'>
            <DisplayTable
              BorderHide={this.state.BorderHide}
              SetStateProps={this.SetStateProps}
              handleXLSX={this.handleXLSX}
              RunPageViews={this.RunPageViews}
              TotalPages={this.state.TotalPages}
              RunTotalPage={JSON.parse(JSON.stringify(this.state.RunTotalPage))}
              SelectedChecked={this.SelectedChecked}
              SetStatePage={this.SetStatePage}
              page={this.state.page}
              countpagemin={this.state.countpagemin}
              countpagemax={this.state.countpagemax}
              FilterTableView={this.FilterTableView}
              OpenReportDetailsModal={this.OpenReportDetailsModal}
              claimData={this.state.claimData}
              role={this.props.role}
              username={this.props.username}
              RedoActions={this.RedoActions}
              DeleteClaims={this.DeleteClaims}
              // WatchReceipt={this.WatchReceipt}
              fastActions={this.state.fastActions}
              SelectedApprove2={this.SelectedApprove2}
              fastActionsReject={this.state.fastActionsReject}
              fastActionApprove2={this.state.fastActionApprove2}
              pagetype={this.state.pagetype}
              FilterStatus={this.state.FilterStatus}
              FilterName={this.state.FilterName}
              FilterCompany={this.state.FilterCompany}
              FilterBranch={this.state.FilterBranch}
              FilterDepart={this.state.FilterDepartment}
              FilterAmount={this.state.FilterAmount}
              FilterApproveAmount={this.state.FilterApproveAmount}
              FilterApprove={this.state.FilterApprove}
              FilterApprovalStatus={this.state.FilterApprovalStatus}
              // FilterSecondApprover={this.state.FilterSecondApprover}
              // FilterSecondApprovalStatus={this.state.FilterSecondApprovalStatus}
              FilterAccounting={this.state.FilterAccounting}
              FilterSub={this.state.FilterSub}
              FilterSubSub={this.state.FilterSubSub}
              ShowFilterStatus={this.state.SelectedFilterStatus}
              ShowFilterCompany={this.state.ShowFilterCompany}
              ShowFilterBranch={this.state.ShowFilterBranch}
              ShowFilterDepartment={this.state.ShowFilterDepart}
              ShowFilterName={this.state.ShowFilterName}
              ShowFilterSub={this.state.ShowFilterSub}
              ShowFilterSubSub={this.state.ShowFilterSubSub}
              ShowFilterApprovalStatus={this.state.ShowFilterApprovalStatus}
              // ShowFilterSecondApprovalStatus={this.state.ShowFilterSecondApprovalStatus}
              ShowFilterApproveAmount={this.state.ShowFilterApproveAmount}
              ShowFilterAmount={this.state.ShowFilterAmount}
              ShowFilterApprover={this.state.ShowFilterApprover}
              // ShowFilterSecondApprover={this.state.ShowFilterSecondApprover}
              ShowFilterAccounting={this.state.ShowFilterAccounting}
              SelectedActions={this.SelectedActions}
              SelectAll={this.SelectAll}
              SelectedAll={this.state.SelectedAll}
              selectedIDs={this.state.SelectedActions}
              SelectedRejects={this.SelectedRejects}
              SelectedRejectsID={this.state.SelectedRejects}
              OpenApproveModal={this.OpenApproveModal}
              OpenRedoReturnModal={this.OpenRedoReturnModal}
              SelectedFilterStatus={this.state.SelectedFilterStatus}
              fasttype={this.state.fasttype}
              SelectedReAllID={this.SelectedReAllID}
              AllSelectedIDs={this.state.AllSelectedIDs}
              BooleanRemoveN={this.state.BooleanRemoveN}
              TotalAmount={this.state.TotalAmount}/>
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    CompanyData: state.CompanyData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Approvel);
