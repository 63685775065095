import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form'
import { Alert } from 'react-bootstrap';
import { changePassword } from '../Api'
import Header from '../Component/Header';
import Container from 'react-bootstrap/Container'
import { language } from '../language';

var timer

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    //   Password: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      NewPassword: '',
      ReKeyNewPassword: '',
      loading: false,
      background: '#0D6EFD',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.UsernameInput = React.createRef();
    this.PasswordInput = React.createRef();
  }

  componentDidMount() {
    if(this.props.token === '') {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      window.location.href = '/';
    }
  }

  async handleSubmit() {
    this.setState({ loading: true });
    if (this.state.NewPassword === '') {
      this.openNotification(`${language[this.props.currentLanguage].InsertNewPass}`,'error')
      this.setState({ loading: false})
    } else if (this.state.ReKeyNewPassword === '') {
      this.openNotification(`${language[this.props.currentLanguage].insertconfirmPass}`,'error')
      this.setState({ loading: false})
    } else {
      const chgPwStatus = await changePassword(
        this.props.username,
        this.state.NewPassword,
        this.state.ReKeyNewPassword,
        this.props.token,
      );
      if (chgPwStatus.error) {
        this.openNotification(chgPwStatus.error,'error')
        this.setState({ loading: false })
      } else {
        this.openNotification(chgPwStatus.message,'success')
        this.setState({ loading: false, NewPassword: '', ReKeyNewPassword: ''})
      }
    }
  }
  
  async onKeyEnter(e) {
    if (e.charCode === 13) {
      this.handleSubmit()
    }
  }

  handleChange(value, field) {
    this.setState({ [field]: value });
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  render() {
    return (
      <div>
        <Header/>
        <Container fluid>
          <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
            {/* <Form.Control ref={(input) => { this.UsernameInput = input; }} style={{ marginBottom: 10, maxWidth: 300 }} value={this.state.Username} type="password" placeholder="密码" onKeyPress={(e) => this.onKeyEnter(e)} onChange={(e) => this.handleChange(e.target.value, 'Password')}/> */}
            <Form.Control ref={(input) => { this.PasswordInput = input; }} style={{ marginBottom: 10, maxWidth: 300 }}value={this.state.NewPassword} type="password" placeholder={language[this.props.currentLanguage].NewPassword} onKeyPress={(e) => this.onKeyEnter(e)} onChange={(e) => this.handleChange(e.target.value, 'NewPassword')}/>
            <Form.Control ref={(input) => { this.PasswordInput = input; }} style={{ marginBottom: 10, maxWidth: 300 }}value={this.state.ReKeyNewPassword} type="password" placeholder={language[this.props.currentLanguage].RepeatNewPAss} onKeyPress={(e) => this.onKeyEnter(e)} onChange={(e) => this.handleChange(e.target.value, 'ReKeyNewPassword')}/>
            {/* <Button style={{ marginBottom: 10 }} onClick={() => this.handleSubmit()} variant="primary">{language[this.props.currentLanguage].Edit}</Button> */}
            <div className="dcc button ClaimSearch"
              style={{ backgroundColor: this.state.background,
                fontWeight: 'bold', padding: 7 , cursor: 'pointer', borderRadius: 5, paddingLeft: 12, paddingRight: 12}} 
              onMouseEnter={() => {
                this.setState({ background: '#00B79B' });
              }}
              onMouseLeave={() => {
                this.setState({ background: '#0D6EFD' });
              }}
              variant="success" onClick={() => this.handleSubmit()}>
              <span>
                {language[this.props.currentLanguage].Edit}
              </span>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (username, role, token) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: username,
          role: role,
          token: token,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
  