import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { language } from '../language';
import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import { ExportPdf } from '../ExportPdf';
import pdfMake from "pdfmake"
// import loading from '../image/loading.gif';
import { Button, Modal, Form } from 'react-bootstrap';

class MultiplePrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ConsirmPass: false,
      ClaimsData: '',
      ErrorMessage: '',
      Password: '',
      StraighDownload: false,
      ArrayTEst: [],
    }
  }

  DisplayPDFFile(SelectedReceipt) {
    // window.open(SelectedReceipt, '_blank', 'noopener,noreferrer');
    const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
    if(isIOS) {
      var windowReference = window.open();
      const test = SelectedReceipt; // URL of the text file
      fetch(test)
        .then(response => response.text())
        .then(text => {
          if (SelectedReceipt.includes('txt')) {
            const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
            const url = URL.createObjectURL(blob);
            windowReference.location = url;
          } else {
            windowReference.location = test;
          }
        });
    } else {
      const test = SelectedReceipt; // URL of the text file
      fetch(test)
        .then(response => response.text())
        .then(text => {
          if (SelectedReceipt.includes('txt')) {
            const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank', 'noopener,noreferrer');
          } else {
            window.open(test, '_blank', 'noopener,noreferrer');
          }
        });
    }
  }

  DownloadImages(SelectedFile) {
    const link = SelectedFile.replace('https://ace-bt.s3.ap-southeast-1.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net').replace('https://ace-bt.s3.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net')
    fetch(link)
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = link.replace(/^.*[\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
  }

  onKeyEnter(e) {
    if (e.charCode === 13) {
      this.props.ConfirmPasswordAPI(this.props.ClaimViewReceipt)
    }
  }
  
  ExportPDF() {
    const ClaimViewReceipt = this.props.ClaimViewReceipt
    let ArrayImage = []
    for(var i=0; i<ClaimViewReceipt.length; i++) {
      let objanswer = {}
      let dataresult = ''
      let count = i
      html2canvas(document.getElementById(`appear${i}`)).then((canvas)=> {
        dataresult = canvas.toDataURL();
        objanswer = {image: dataresult, width: 500}
        ArrayImage.push(objanswer)
        if (count === ClaimViewReceipt.length - 1) {
          var pdfExportSetting = {
            content: ArrayImage
          };
          pdfMake.createPdf(pdfExportSetting).download(`Receipt-${moment(new Date()).format('DD-MM-YYYY HH:mm:ss')}/MultiReceipts.pdf`);
        }
      });
    }
  }

  DownloadNow() {
    this.props.OpenReceiptFunction()
    this.setState({ StraighDownload: true })
  }

  printWindow() {
    const printableContent = document.getElementById('printablediv').innerHTML;
    const popupWindow = window.open('', '_blank', 'width=1000,height=800');
    popupWindow.document.open();
    popupWindow.document.write(`
    <html>
    <head>
      <title>Print</title>
      <style>
      @media print {
        .ReceiptContainer {
          margin-bottom: 20px;
          flex-direction: row;
          display: flex;
        }
        .CashoutReceiptTitle {
          font-weight: bold;
          font-size: 17px;
        }                
        @page {
          size: A4;
          margin-top: 0;
          margin-bottom: 0;
        }
        .pagebreak {
          clear: both;
          page-break-after: always;
      }
        .template { width: auto; left:0; top:0; }
      
        li { margin: 0 0 10px 20px !important;} 
        .modal-dialog { max-width: 100%; width: 100%;  }
        .modal-body {
          overflow: visible !important;
        }
        .non-printable { display: none; }
        .CssButtonP { display: none;}
        .CssButtonRed { display: none;}
        .printable { display: block; }
        .button { display: none; }
        .breaksss { page-break-after: always; }
        .SetHtml { margin-top: 50; }
      }
      </style>
    </head>
    <body>
      ${printableContent}
    </body>
  </html>
    `);
    popupWindow.document.close();

    popupWindow.addEventListener('afterprint', function() {
      popupWindow.close();
    });

    popupWindow.print();

  }

  componentDidUpdate(prevProps) {
    this.ExportPDF();
    this.setState({ StraighDownload: false })
  }

  componentWillUnmount() {
    this.setState({ StraighDownload: false, ConsirmPass: false })
  }

  render() {
    return (
      <Modal centered show={this.props.DisplayMultiReceipt} size="lg" onHide={this.props.handleClose} className='printable' style={{ fontSize: '12px' }} >
        {/* {this.props.ConsirmPass === false &&  */}
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {`${language[this.props.currentLanguage].CashOutClaim}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div  id="printablediv"   style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto', maxHeight: 900, breakAfter: 'page'}}>
              {this.props.ClaimViewReceipt.map((items, index) => {
                return(
                  <div className={((this.props.ClaimViewReceipt.length -1 ) === index) ? '' : 'breaksss'} id={`appear${index}`} key={index}>
                    <div className='dcc' style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 50 }}>
                      <div className='SetHtml' style={{ fontWeight: 'bold', fontSize: '26px', marginBottom: 40 }}>{'Payment Receipt'}</div>
                      <div className="ReceiptContainer" style={{ width: '100%' }}>
                        <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Company Name'}</div>
                        <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${items.Company}`}</div>
                      </div>
                      <div className="ReceiptContainer" style={{ width: '100%' }}>
                        <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Branch Name '}</div>
                        <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${items.Branch}`}</div>
                      </div>
                      <div className="ReceiptContainer" style={{ width: '100%' }}>
                        <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Department '}</div>
                        <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${items.Department}`}</div>
                      </div>
                      <div className="ReceiptContainer" style={{ width: '100%' }}>
                        <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Category Name'}</div>
                        <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${items.Category}`}</div>
                      </div>
                      {items.Sub1 !== null && 
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Sub Category Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${items.Sub1}`}</div>
                  </div>
                      }
                      <div className="ReceiptContainer" style={{ width: '100%' }}>
                        <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Subject Name'}</div>
                        <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${items.Subject}`}</div>
                      </div>
                      <div className="ReceiptContainer" style={{ width: '100%' }}>
                        <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Submitted Date'}</div>
                        <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${moment(items.UploadDateTime).format('DD-MM-YYYY')}`}</div>
                      </div>
                      <div className="ReceiptContainer" style={{ width: '100%' }}>
                        <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Paid Date'}</div>
                        <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${moment(items.ActionDateTime).format('DD-MM-YYYY')}`}</div>
                      </div>
                      <div className="ReceiptContainer" style={{ width: '100%' }}>
                        <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Remark'}</div>
                        <div style={{ width: '60%', fontSize: '17px', wordBreak: "break-word", fontWeight: '500'}}>{items.Comment}</div>
                      </div>
                      <div className="ReceiptContainer" style={{ width: '100%' }}>
                        <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Submitted Amount'}</div>
                        <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`RM ${items.Amount}`}</div>
                      </div>
                      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 65}}>
                        <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Paid Amount'}</div>
                        <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`RM ${items.ApproveAmount}`}</div>
                      </div>
                      <div className="ReceiptContainer" style={{ width: '100%', marginBottom: 100 }}>
                        <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'Paid By'}</div>
                        <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'Receive By'}</div>
                      </div>
                      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 5}}>
                        <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'_____________________________'}</div>
                        <div style={{ width: '50%', fontSize: '17px'}}>{'_____________________________'}</div>
                      </div>
                      <div className="ReceiptContainer" style={{ width: '100%' }}>
                        <div style={{ width: '50%', fontWeight: 'bold', fontSize: '20px'}}>{`${items.Accountant}`}</div>
                        <div style={{ width: '50%', fontWeight: 'bold', fontSize: '20px'}}>{`${items.Name}`}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                <button  type="button" className="CssButtonP ButtonH" style={{ marginTop: 10, marginRight: 5, width: '50%', }} onClick={() => 
                {
                  // className="btn btn-secondary btn-block "
                  const  answer = window.confirm(`${language[this.props.currentLanguage].AlertPrint}`);
                  if (answer) {
                    this.printWindow()
                  }
                }
                }>
                  {language[this.props.currentLanguage].Print}
                </button>
                <button type="button" className="CssButtonP ButtonH" style={{ marginTop: 10, marginRight: 5, width: '50%' }} onClick={() => this.ExportPDF()}>
                  {language[this.props.currentLanguage].DownloadReceipt}
                </button>
              </div>
              <button type="button" className="CssButtonRed ButtonHRed" style={{ marginTop: 10, marginRight: 5, backgroundColor: 'red' }} onClick={this.props.handleClose}>
                {language[this.props.currentLanguage].Back}
              </button>
            </div>
          </Modal.Body>
        </>
        {/* } */}
        {this.props.ConsirmPass === true && 
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {language[this.props.currentLanguage].ConfirmPassword}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="ClaimInput">
                <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Users}</div>
                <Form.Control style={{ width: '60%', textAlign: 'center', backgroundColor: 'white' }} value={(this.props.MultiCashout === true) ? this.props.SelectedClaimObject[0].UploadBy : this.props.SelectedClaimObject.UploadBy} disabled={true}/>
              </div>
              <div className='ClaimInput'>
                <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Password}</div>
                <Form.Control onKeyPress={(e) => this.onKeyEnter(e)} style={{ width: '60%', textAlign: 'center', backgroundColor: 'white'}} value={this.props.Password} onChange={(e) => this.props.OnchangePassword(e.target.value)} type="text"/>
              </div>
            </div>
            <Button variant="success" onClick={() => this.props.ConfirmPasswordAPI( this.props.SelectedClaimObject)}>{language[this.props.currentLanguage].CashOut}</Button>
            <Button variant="danger" style={{ marginLeft: 5 }} onClick={this.props.CloseConfirmPAss}>{language[this.props.currentLanguage].Back}</Button>
          </Modal.Body>
        </>
        }
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiplePrint);
