import xlsx from 'xlsx-js-style'
import { language } from './language';
import moment from 'moment-timezone'
// import { PrizeConverter } from './utility/PrizeConvertr'
// import { saveAs } from "file-saver";

function styling(value, color, type, username) {
  let colorBackground = {}
  if (color === 'g') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "dddddd"},
        bgColor: {rgb: "dddddd"}
      }
    }
  }
  if (color === 's') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "9FA7FF"},
        bgColor: {rgb: "9FA7FF"}
      }
    }
  }

  return {v: username ? `[${value}]` : value, 
    t: type === "n" ? "n" : "s", 
    s: {
      numFmt: "#,###,###",
      border: {
        top: {style: 'thin', color:{rgb: "000"}}, 
        bottom: {style: 'thin', color:{rgb: "000"}}, 
        left: {style: 'thin', color:{rgb: "000"}}, 
        right: {style: 'thin', color:{rgb: "000"}}
      },
      font:{
        sz: '12',
      },
      alignment: {
        horizontal: 'center',
        wrapText: true,
      },
      ...colorBackground,
    }
  }
}

function stylingSign(value, color, type, username) {
  return {v: username ? `[${value}]` : value, t: type === "n" ? "n" : "s", s: {
    font:{
      sz: '12',
    },
    alignment: {
      horizontal: 'center',
      wrapText: true,
    },
  }}
}

export function exportReportExcel(ReportDATA, startDate, endDate, current, page, BooleanNoN) {
  let ws = xlsx.utils.json_to_sheet([{A: `${language[current].Date}`, B: startDate, C: endDate, D: 'Page: ', E: `${page}`}],
    {header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: ''}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[current].Date}`, 's'), B: styling(`${language[current].Name}`, 's'), 
    C: styling(`${language[current].Department}`, 's'), 
    D: styling(`${language[current].Subject}`, 's'), 
    E: styling(`${language[current].SubSubject}`, 's'), 
    F: styling(`${language[current].Amount}`, 's'),
    G: styling(`${language[current].Approver}`, 's'), 
    H: styling(`${language[current].ApproverStatus}`, 's'), 
    I: styling(`${language[current].ApprovedAmount}`, 's'), 
    J: styling(`${language[current].Accounting}`, 's'), 
    K: styling(`${language[current].LatestStatus}`, 's'), 
    L: styling(`${language[current].Title}`, 's'), 
    M: styling(`${language[current].Remark}`, 's')}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'], skipHeader: true, origin: -1 })
  
  const total = {
    'F': 0, 'K': 0,  
  }
  const pattern = /\n/gi;
  const replacement = " ";
  for (let i = 0; i < ReportDATA.length; i += 1) {
    xlsx.utils.sheet_add_json(ws, [{
      A: styling(moment(ReportDATA[i].UploadDateTime, '', '', true).format('YYYY-MM-DD')), 
      B: styling(ReportDATA[i].Name), 
      C: styling(ReportDATA[i].Department || ''),
      D: styling(ReportDATA[i].Category || ''), 
      E: styling(ReportDATA[i].Sub1 || ''), 
      F: styling(ReportDATA[i].Amount ||  '', '', 'n'), 
      G: styling(ReportDATA[i].FirstApprove || ''),  
      H: styling(ReportDATA[i].FirstApproveStatus || ''), 
      I: styling(ReportDATA[i].ApproveAmount || '', '', 'n'), 
      J: styling(ReportDATA[i].Accountant || ''), 
      K: styling(ReportDATA[i].Status || ''), 
      L: styling(ReportDATA[i].Subject || ''), 
      M: styling(BooleanNoN ? ReportDATA[i].Comment.replaceAll(pattern, replacement) : ReportDATA[i].Comment), 
    }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'], skipHeader: true, origin: -1 })

    total.F += Number(ReportDATA[i].Amount)
    total.K += Number(ReportDATA[i].ApproveAmount)
  }

  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[current].Total}:`, 'g'), B: styling('', 'g'), C: styling('', 'g'),
    D: styling('', 'g'), E: styling('', 'g'), F: styling(total.F, 'g', 'n'), G: styling('', 'g'),
    H: styling('', 'g'), I: styling(total.K, 'g', 'n'), J: styling('', 'g'), K: styling('', 'g'),
    L: styling('', 'g'), M: styling('', 'g'),
  }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'], skipHeader: true, origin: -1 })

  ws['!cols'] = [{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120}, {wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 400},];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Summary`)
  wb.Sheets['Summary'] = ws
  xlsx.writeFile(wb, `${page} ${moment(startDate).format('DDMM')} - ${moment(endDate).format('DDMM')}.xlsx`)
}

export function exportMainData(ReportDATA, month, Year, current, WholeYear) {
  let ws = xlsx.utils.json_to_sheet([{A: `${language[current].WholeYear}`, B: WholeYear, C: `${language[current].Month}`, D: month, E: `${language[current].Year}`, F: Year}],
    {header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: '', F: ''}], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[current].Date}`, 's'), B: styling(`${language[current].Name}`, 's'), 
    C: styling(`${language[current].Department}`, 's'), D: styling(`${language[current].Subject}`, 's'), E: styling(`${language[current].SubSubject}`, 's'),
    F: styling(`${language[current].Amount}`, 's'), G: styling(`${language[current].Approver}`, 's'), H: styling(`${language[current].ApproverStatus}`, 's'), 
    I: styling(`${language[current].ApprovedAmount}`, 's'), J: styling(`${language[current].Accounting}`, 's'), K: styling(`${language[current].LatestStatus}`, 's'), 
    L: styling(`${language[current].Remark}`, 's')}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'], skipHeader: true, origin: -1 })
  
  const total = {
    'F': 0, 'K': 0,  
  }
  for (let i = 0; i < ReportDATA.length; i += 1) {
    xlsx.utils.sheet_add_json(ws, [{
      A: styling(moment(ReportDATA[i].UploadDateTime, '', '', true).format('YYYY-MM-DD')),
      B: styling(ReportDATA[i].Name), 
      C: styling(ReportDATA[i].Department || ''), 
      D: styling(ReportDATA[i].Category || ''), 
      E: styling(ReportDATA[i].Sub1 || ''), 
      F: styling(ReportDATA[i].Amount || '', '', 'n'), 
      G: styling(ReportDATA[i].FirstApprove || ''), 
      H: styling(ReportDATA[i].FirstApproveStatus || ''),
      I: styling(ReportDATA[i].ApproveAmount || '', '', 'n'), 
      J: styling(ReportDATA[i].Accountant || ''), 
      K: styling(ReportDATA[i].Status), 
      L: styling(ReportDATA[i].Comment), 
    }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'], skipHeader: true, origin: -1 })
  
    total.F += Number(ReportDATA[i].Amount)
    total.K += Number(ReportDATA[i].ApproveAmount)
  }


  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[current].Total}:`, 'g'), B: styling('', 'g'), C: styling('', 'g'), 
    D: styling('', 'g'), E: styling('', 'g'), F: styling(total.F, 'g', 'n'), G: styling('', 'g'), 
    H: styling('', 'g'), I: styling(total.K, 'g', 'n'), J: styling('', 'g'), K: styling('', 'g'),
    L: styling('', 'g'),
  }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'], skipHeader: true, origin: -1 })

  ws['!cols'] = [{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 350},];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Summary`)
  wb.Sheets['Summary'] = ws
  xlsx.writeFile(wb, `${month}/${Year}.xlsx`)
}


export function exportCashOutReportExcel(ReportDATA, startDate, endDate, current, BooleanNoN) {
  let ws = xlsx.utils.json_to_sheet([{A: styling(`${language[current].Date}`), B: styling(startDate), C: styling(endDate), D: ''}],
    {header: ['A', 'B', 'C', 'D'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[current].Date}`, 's'), B: styling(`${language[current].Name}`, 's'), 
    C: styling(`${language[current].Company}`, 's'), D: styling(`${language[current].Branch}`, 's'), E: styling(`${language[current].Department}`, 's'),
    F: styling(`${language[current].Subject}`, 's'), G: styling(`${language[current].SubSubject}`, 's'), H: styling(`${language[current].Amount}`, 's'), 
    I: styling(`${language[current].Approver}`, 's'), J: styling(`${language[current].FirstApproveStartus}`, 's'),
    K: styling(`${language[current].SecondApprover}`, 's'), L: styling(`${language[current].SecondApproveStatus}`, 's'), 
    M: styling(`${language[current].ApprovedAmount}`, 's'), N: styling(`${language[current].Accounting}`, 's'), 
    O: styling(`${language[current].EditedDate}`, 's'), P: styling(`${language[current].Title}`, 's'), Q: styling(`${language[current].Remark}`, 's'),
    R: styling(`${language[current].LatestStatus}`, 's'),}], 
  { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R' ], skipHeader: true, origin: -1 })
  
  const total = {
    'H': 0, 'M': 0,  
  }
  const pattern = /\n/gi;
  const replacement = " ";
  for (let i = 0; i < ReportDATA.length; i += 1) {
    console.log('type', typeof ReportDATA[i].Amount)
    xlsx.utils.sheet_add_json(ws, [{
      A: styling(moment(ReportDATA[i].UploadDateTime, '', '', true).format('YYYY-MM-DD')), 
      B: styling(ReportDATA[i].Name), 
      C: styling(ReportDATA[i].Company || ''),  
      D: styling(ReportDATA[i].Branch || ''), 
      E: styling(ReportDATA[i].Department || ''), 
      F: styling(ReportDATA[i].Category || ''), 
      G: styling(ReportDATA[i].Sub1 || ''), 
      H: styling(ReportDATA[i].Amount ? ReportDATA[i].Amount : '', '', 'n'), 
      I: styling(ReportDATA[i].FirstApprove || ''), 
      J: styling(ReportDATA[i].FirstApproveStatus || ''), 
      K: styling(ReportDATA[i].SecondApprove || ''), 
      L: styling(ReportDATA[i].SecondApproveStatus || ''), 
      M: styling(ReportDATA[i].ApproveAmount ? ReportDATA[i].ApproveAmount : '', '', 'n'), 
      N: styling(ReportDATA[i].Accountant || '-'), 
      O: styling(moment(ReportDATA[i].ActionDateTime).format('YYYY-MM-DD')), 
      P: styling(ReportDATA[i].Subject || '-'),
      Q: styling(BooleanNoN ? ReportDATA[i].Comment.replaceAll(pattern, replacement) : ReportDATA[i].Comment), 
      R: styling(ReportDATA[i].Status),
    }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })
    total.H += Number(ReportDATA[i].Amount)
    total.M += Number(ReportDATA[i].ApproveAmount)
  }


  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[current].Total}:`, 'g'), B: styling('', 'g'), C: styling('', 'g'), 
    D: styling('', 'g'), E: styling('', 'g'), F: styling('', 'g'), G: styling('', 'g'), 
    H: styling(total.H, 'g', 'n'), I: styling('', 'g'), J: styling('', 'g'), K: styling('', 'g'),
    L: styling('', 'g'), M: styling(total.M, 'g', 'n'), N: styling('', 'g'), O: styling('', 'g'), P: styling('', 'g'), Q: styling('', 'g'), 
  }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: stylingSign(`Paid By`), B: stylingSign(''), C: stylingSign(''), 
    D: stylingSign(''), E: stylingSign(''), F: stylingSign('Receive By'), G: stylingSign(''), 
    H: stylingSign(''), I: stylingSign(''), J: stylingSign(''), K: stylingSign(''),
    L: stylingSign(''), M: stylingSign(''), N: stylingSign(''), O: stylingSign(''), 
  }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: stylingSign(`________________`), B: stylingSign(''), C: stylingSign(''), 
    D: stylingSign(''), E: stylingSign(''), F: stylingSign('________________',), G: stylingSign(''), 
    H: stylingSign(''), I: stylingSign(''), J: stylingSign(''), K: stylingSign(''),
    L: stylingSign(''), M: stylingSign(''), N: stylingSign(''), O: stylingSign(''), 
  }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: stylingSign(`${ReportDATA[0].Name}`), B: stylingSign(''), C: stylingSign(''), 
    D: stylingSign(''), E: stylingSign(''), F: stylingSign(`${ReportDATA[0].Accountant || '-'}`,), G: stylingSign(''), 
    H: stylingSign(''), I: stylingSign(''), J: stylingSign(''), K: stylingSign(''),
    L: stylingSign(''), M: stylingSign(''), N: stylingSign(''), O: stylingSign(''), 
  }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })

  ws['!cols'] = [{wpx: 120},{wpx: 90},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 120},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 120},{wpx: 100},{wpx: 160},{wpx: 160},{wpx: 350},{wpx: 100},];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Summary`)
  wb.Sheets['Summary'] = ws
  xlsx.writeFile(wb, `CashOut ${moment(startDate).format('DDMM')} - ${moment(endDate).format('DDMM')}.xlsx`)
}
