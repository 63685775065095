const base = window.location.protocol === 'https:' ? 'https://claimserver.ace4dv2.live/' : 'http://118.107.232.125/';
// const base = 'http://localhost:8003/';

async function massageRes(res, resolve) {
  const result = await res.json()
  if (result.error && result.error === 'Invalid credentials!') {
    resolve(result)
  } 
  else if (result.error && result.error === 'Login session timeout') {
    localStorage.removeItem('claimtoken');
    localStorage.removeItem('claimusername');
    localStorage.removeItem('claimrole');
    localStorage.removeItem('CompanyData');
    window.location.href = '/';
  }
  else {
    resolve(result)
  }
}

function getCapchaCode() {
  return new Promise((resolve) => {
    fetch(`${base}public/getSecretCode`, {
      method: "post",
      headers: {
        Authorization: '2AdTHerUNMrm6tljs',
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    }).then((res) => {
      massageRes(res, resolve);
    });
  });
}

function getLogin(username, password, secretCode, captchaNumber) {
  return new Promise((resolve) => {
    fetch(`${base}public/login`, {
      method: 'post',
      headers: {
        Authorization: '2AdTHerUNMrm6tljs',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password, secretCode, captchaNumber }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getUser(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function EditClaim(editClaimObject, Status, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/editClaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ editClaimObject, Status, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function headerFilterFNC(fromDate, toDate, page, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/headerFilter`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fromDate, toDate, page, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function ceoSummaryFNC(selectedYear, selectedMonth, wholeYear, filterObject, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/ceoSummary`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedYear, selectedMonth, wholeYear, filterObject, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function ceoSummaryHeaderFilterFNC(selectedYear, selectedMonth, wholeYear, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/ceoSummaryHeaderFilter`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedYear, selectedMonth, wholeYear, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function cashOutClaimListFNC(fromDate, toDate, filterObject, page, Username, pageNumber, countPerPage, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/cashOutClaimList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fromDate, toDate, filterObject, page, Username, pageNumber, countPerPage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function deleteUserFNC(selectedID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/deleteUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function DeleteClaim(deleteClaimIDArray, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/deleteClaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ deleteClaimIDArray, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function deleteDepartment(selectedID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/deleteDepartment`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function deleteCategory(selectedID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/deleteCategory`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}


function returnClaimFNC(returnClaimID, reason, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/returnClaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ returnClaimID, reason, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function redoClaimFNC(claimID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/redoClaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ claimID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getClaimRecord(Username, selectedUsername, fromDate, toDate, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getClaimRecord`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, selectedUsername, fromDate, toDate }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getUserList(Username, token, Mode ) {
  return new Promise((resolve) => {
    fetch(`${base}main/getUserList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Mode }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function returnCategoryFNC(selectedName, Username, token, filterDepartment) {
  return new Promise((resolve) => {
    fetch(`${base}main/returnCategory`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedName, Username, filterDepartment }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getCategory(Username, token, categoryObj) {
  return new Promise((resolve) => {
    fetch(`${base}main/getCategory`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, categoryObj }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function PostClaim(Username, token, claimObject ) {
  return new Promise((resolve) => {
    fetch(`${base}main/createClaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, claimObject  }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getCompany(Username, token, company, branch) {
  return new Promise((resolve) => {
    fetch(`${base}main/getCompany`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, company, branch }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function postCompany(Username, token, companyList) {
  return new Promise((resolve) => {
    fetch(`${base}main/postCompany`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, companyList }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function exportClaimListFNC(fromDate, toDate, filterObject, page, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/exportClaimList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fromDate, toDate, filterObject, page, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function ApproverCashoutClaim(Username, fromDate, toDate, page, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/approverCashoutClaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, fromDate, toDate, page }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function postCategory(Username, token, categoryList) {
  return new Promise((resolve) => {
    fetch(`${base}main/postCategory`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, categoryList }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function firstApproveClaimFNC(claimIDArray, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/firstApproveClaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ claimIDArray, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function secondApproveClaimFNC(claimIDArray, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/secondApproveClaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ claimIDArray, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function rejectClaimFNC(rejectClaimIDArray, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/rejectClaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ rejectClaimIDArray, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function cashOutClaimFNC(cashOutClaimIDArray, selectedUsername, userPwd, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/cashOutClaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cashOutClaimIDArray, selectedUsername, userPwd, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function copyCategory(Username, token, fromDepartment, toDepartment) {
  return new Promise((resolve) => {
    fetch(`${base}main/copyCategory`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, fromDepartment, toDepartment }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function createUser(Username, token, createUserObj) {
  return new Promise((resolve) => {
    fetch(`${base}main/createUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, createUserObj }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getUserForPage(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getUserForPage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function changePassword(Username, newPwd, confirmNewPwd, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/changePwd`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username, 
        newPwd,
        confirmNewPwd,
      }),
  
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getClaimHistoryRecordFNC(claimID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getClaimRecordHistory`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ claimID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function summaryReportFNC(selectedYear, selectedMonth, FullYear, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/summaryReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedYear, selectedMonth, FullYear, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function summaryReportDetailFNC(selectedYear, selectedMonth, FullYear, Username, selectedCompany, selectedStatus, Type, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/summaryReportDetail`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedYear, selectedMonth, FullYear, Username, selectedCompany, selectedStatus, Type }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function updateUser(Username, token, updateUserObj) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, updateUserObj }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function claimReport(Username, token, reportFilterObject) {
  return new Promise((resolve) => {
    fetch(`${base}main/claimReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, reportFilterObject }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function reClaim(Username, token, reclaimObject) {
  return new Promise((resolve) => {
    fetch(`${base}main/reclaim`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, reclaimObject }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

export {
  getCapchaCode,
  getLogin, 
  getUser,
  getUserList,
  returnCategoryFNC,
  PostClaim,
  getCompany,
  ApproverCashoutClaim,
  exportClaimListFNC,
  redoClaimFNC,
  returnClaimFNC,
  firstApproveClaimFNC,
  secondApproveClaimFNC,
  getUserForPage,
  cashOutClaimFNC,
  rejectClaimFNC,
  EditClaim,
  headerFilterFNC,
  ceoSummaryFNC,
  ceoSummaryHeaderFilterFNC,
  cashOutClaimListFNC,
  DeleteClaim,
  getClaimRecord,
  postCompany,
  getCategory,
  deleteUserFNC,
  postCategory,
  createUser,
  getClaimHistoryRecordFNC,
  summaryReportFNC,
  summaryReportDetailFNC,
  copyCategory,
  changePassword,
  updateUser,
  claimReport,
  reClaim,
  deleteDepartment,
  deleteCategory,
};
  