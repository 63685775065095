import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import Select from "react-select";
import { Container, Form, Alert } from 'react-bootstrap';
import ReportViewDetails from '../Component/ReportViewDetails';
import Header from '../Component/Header';
import { language } from '../language';
import { getCategory, getUser, PostClaim, getClaimHistoryRecordFNC, headerFilterFNC, cashOutClaimListFNC, exportClaimListFNC, returnCategoryFNC } from '../Api'
import moment from 'moment-timezone';
// import LoadingIcon from '../image/loading.gif';
import { exportReportExcel } from '../jsonToXlsx';
var timer

class Report extends React.Component {

  fileObj = [];
  fileArray = [];
  Base64 = []
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      filterUser: '',
      selectedDate: moment().format('YYYY-MM-DD'),
      fromDate: moment(new Date()).subtract(2, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      CompanyList: [],
      BranchList: [],
      DepartmenList: [],
      UserList: [],
      CategoryList: [],
      LatestUpdateList: [
        'Pending',
        'Approve',
        'CashOut',
        'Reject',
      ],
      CompanyBranch: [],
      SelectBranch: [], 
      SelectDepartment: [], 
      SelectCategory: '',
      SelectLatestUpdate: '',
      ReportDataList: [],
      ClaimAmount: 0,
      ApproveAmt: 0,
      SelectName: 'All',
      TableLoading: true,
      ReportModal: false,
      SelectedClaimObject: {},
      SelectCompany: '',
      background: '#0D6EFD',
      DisplayHistory: false,
      HistoryData: [],
      FilterName: [],
      FilterDepartment: [],
      FilterSub: [],
      FilterSubSub: [],
      FilterApprovalStatus: [],
      FilterSecondApprovalStatus: [],
      FilterStatus: [],
      FilterAmount: [],
      FilterApprove: [],
      FilterSecondApprover: [],
      FilterAccounting: [],
      FilterApproveAmount: [],
      ShowFilterName: 'All',
      ShowFilterDepart: 'All',
      ShowFilterSub: 'All',
      ShowFilterSubSub: 'All',
      ShowFilterApprovalStatus: 'All',
      ShowFilterSecondApprovalStatus: 'All',
      ShowFilterStatus: 'All',
      ShowFilterAmount: 'All',
      ShowFilterApprover: 'All',
      ShowFilterSecondApprover: 'All',
      ShowFilterAccounting: 'All',
      ShowFilterApproveAmount: 'All',
      AllSelectValue: [],
      OpenFilter: true,
      Sub1List: [],
      Sub2List: [],
      Sub3List: [],
      Sub4List: [],
      Sub5List: [],
      Sub6List: [],
      FirstApprove: '',
      FirstApproveStatus: '',
      SecondApprove: '',
      SecondApproveStatus: '',
      Accountant: '',
      SelectedSub1: '',
      SelectedSub2: '',
      SelectedSub3: '',
      SelectedSub4: '',
      SelectedSub5: '',
      SelectedSub6: '',
      KeyinAmount: '',
      ApprovedAmount: '',
      page: 1,
      countperpage: 20,
      countpagemax: 1,
      countpagemin: 1,
      TotalPages: 1,
      RunTotalPage: [],
      BorderHide: false,
      OpenReceipt: false, 
      StraighDownload: false,
      SelectedOptionBranch: [],
      SelectedBranchs: [],
      SelectedDeparts: [],
      SelectedOptionDepart: [],
    }
    this.handleClose = this.handleClose.bind(this);
    this.ReClaimSelectedClaim = this.ReClaimSelectedClaim.bind(this);
    this.DisplayHistorySet = this.DisplayHistorySet.bind(this);
    this.SetStatePage = this.SetStatePage.bind(this);
    this.OpenReceipt = this.OpenReceipt.bind(this);
  }

  async componentDidMount() {
    if(this.props.token === '') {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      window.location.href = '/';
    }
    let FilterCompany = '';
    let getUserList = '';
    if (this.props.username === '') {
      this.props.history.push('/')
    } 
    const getCompanyList = JSON.parse(this.props.CompanyData)
    if (this.props.role !== 'User') {
      getUserList = await getUser(
        this.props.username, 
        this.props.token,
      );
    } else {
      getUserList = [];
    }

    if (getUserList.error === 'Invalid credentials!') {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      this.props.setLoginStatus('', '', '');
      alert('Token Error');
      this.props.history.push('/')
    }
    const CheckRoleBoolean = this.props.role === 'CEO' ? true : false
  
    this.FilteredBranch(JSON.parse(this.props.CompanyData));
    FilterCompany = getCompanyList.map((item) => item.Company)
    FilterCompany = [...new Set(FilterCompany)];
    this.setState({ 
      CompanyList: FilterCompany, 
      CompanyBranch: getCompanyList,
      UserList: getUserList,
      TableLoading: false,
      BorderHide: CheckRoleBoolean, 
    });
    // if (getCompanyList.error === 'Invalid credentials!' || 
    //     getUserList.error === 'Invalid credentials!') {
    //   localStorage.removeItem('claimtoken');
    //   localStorage.removeItem('claimusername');
    //   localStorage.removeItem('claimrole');
    //   localStorage.removeItem('CompanyData');
    //   this.props.setLoginStatus('', '', '');
    //   alert('Token Error');
    //   this.props.history.push('/')
    // }
    // FilterCompany = getCompanyList.map((item) => item.Company)
    // FilterCompany = [...new Set(FilterCompany)];
    // this.setState({ 
    //   CompanyList: FilterCompany, 
    //   CompanyBranch: getCompanyList, 
    //   UserList: getUserList,
    //   TableLoading: false
    // });
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)
    if (searchState !== '') {
      searchState = `{"${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}"}`
      searchState = JSON.parse(searchState)
      const fromDate = (searchState.WholeYear === 'true') ? `${searchState.Year}-01-01` : `${searchState.Year}-${searchState.Month}-01`
      const Todate = (searchState.WholeYear === 'true') ? `${searchState.Year}-12-31` : `${searchState.Year}-${searchState.Month}-${this.GetDay(searchState.Month, searchState.Year)}`
      this.setState({SelectCompany: searchState.Company, SelectBranch: searchState.Branch, SelectCategory: searchState.Category, fromDate , toDate: Todate }, () => {
        this.handleSubmit();
      })
    }
    this.handleSubmit();
    this.getCategories();
  }

  async getCategories() {
    const getCate = await returnCategoryFNC(this.state.SelectName, this.props.username, this.props.token, this.state.SelectDepartment)
    this.setState({ 
      FilterSub: getCate,
    })
  }

  // RunTotalPage() {
  //   // this.setState({ RunTotalPage: [] })
  //   const TotalPages = this.state.TotalPages
  //   let RunTotalPage = this.state.RunTotalPage
  //   if (this.state.page === 1 && RunTotalPage.length === 0) {
  //     console.log('aaaaaa')
  //     this.setState({ RunTotalPage: [] })
  //     if(TotalPages > 5) {
  //       for(var i=0; i < 5; i++) {
  //         RunTotalPage.push(i+1)
  //       }
  //       this.setState({ RunTotalPage })
  //     } else {
  //       for(var i=0; i < TotalPages; i++) {
  //         RunTotalPage.push(i+1)
  //       }
  //       this.setState({ RunTotalPage })
  //     }
  //   }
  // }

  RunTotalPage() {
    const TotalPages = this.state.TotalPages
    let RunTotalPage = this.state.RunTotalPage
    if (this.state.page === 1 && RunTotalPage.length === 0) {
      if(TotalPages > 5) {
        for(var i=0; i < 5; i++) {
          RunTotalPage.push(i+1)
        }
        this.setState({ RunTotalPage })
      } else {
        for(var z=0; z < TotalPages; z++) {
          RunTotalPage.push(z+1)
        }
        this.setState({ RunTotalPage })
      }
    }
  }

  GetDay(Month, year) {
    if(Month === '01' || Month === '03' || Month === '05' || Month === '07' || Month === '08' || Month === '10' || Month === '12') {
      return '31'
    } else if (Month === '04' || Month === '06' || Month === '09' || Month === '11') {
      return '30'
    } else if (Month === '02'){
      return moment(`${year}-${Month}`).endOf('month').format('DD')
    }
  }

  async CallHeader() {
    const getheader = await headerFilterFNC(this.state.fromDate, this.state.toDate, 'Report', this.props.username, this.props.token);
    if (getheader) {
      let ArrayDepart = []
      getheader.FilterDepartment.forEach((items) => {
        ArrayDepart.push({value: items, label: items})
      })
      this.setState({ 
        FilterAccounting: getheader.FilterAccounting,
        FilterAmount: getheader.FilterAmount,
        FilterApprovalStatus: getheader.FilterApprovalStatus,
        FilterApprove: getheader.FilterApprove,
        FilterApproveAmount: getheader.FilterApproveAmount,
        SelectedOptionDepart: ArrayDepart,
        FilterDepartment: getheader.FilterDepartment,
        FilterName: getheader.FilterName,
        FilterSecondApprovalStatus: getheader.FilterSecondApprovalStatus,
        FilterSecondApprover: getheader.FilterSecondApprover,
        FilterStatus: getheader.FilterStatus,
        // FilterSub: getheader.FilterSub,
        FilterSubSub: getheader.FilterSubSub,
      }, () => {
        if (this.props.role === 'User') {
          this.setState({ SelectName: this.state.FilterName[0]})
        }
      })
    }
  }

  FilteredBranch(CompanyData) {
    let FilterBranch = '';
    let Branchs = []
    FilterBranch = CompanyData.map((item) => item.Branch)
    FilterBranch = [...new Set(FilterBranch)];
    FilterBranch.forEach((item) => {
      Branchs.push({value: item, label: item})
    })
    this.setState({ BranchList: FilterBranch, SelectedOptionBranch: Branchs })
  }

  async getFilterBranch(selectedCompany) {
    let FilterBranch = [];
    if (selectedCompany !== '') {
      await this.setState({ BranchList: []});
      FilterBranch = this.state.CompanyBranch.filter((item) => item.Company === selectedCompany)
      FilterBranch = FilterBranch.map((item) => item.Branch)
      FilterBranch = [...new Set(FilterBranch)];
      this.setState({ 
        BranchList: FilterBranch, 
      });
    } else {
      this.setState({ 
        BranchList: [], 
        DepartmenList: [],
        CategoryList: [],
        LatestUpdateList: [],
      });
    }
  }

  async getFilterDepartment(selectedBranch) {
    let FilterDepartment = [];
    if (selectedBranch !== '') {
      await this.setState({ DepartmenList: []});
      FilterDepartment = this.state.CompanyBranch.filter((item) => (item.Branch === selectedBranch && item.Company === this.state.SelectCompany))
      FilterDepartment = FilterDepartment.map((item) => item.Department)
      FilterDepartment = [...new Set(FilterDepartment)];
      this.setState({ DepartmenList: FilterDepartment, SelectBranch: selectedBranch});
    } else {
      this.setState({ 
        DepartmenList: [],
        CategoryList: [],
      });
    }
  }

  async getFilterCategory(selectedDepartment) {
    if (selectedDepartment !== '') {
      let FilterCategory = '';
      await this.setState({ CategoryList: []});
      const getCategoryList = await getCategory(
        this.props.username, 
        this.props.token,
        {
          department: selectedDepartment,
          Category: '',
          sub1: '', 
          sub2: '', 
          sub3: '', 
          sub4: '',
          sub5: '',
        },
      );
      FilterCategory = getCategoryList.map((item) => item.Category)
      FilterCategory = [...new Set(FilterCategory)];
      this.setState({ CategoryList: FilterCategory, SelectDepartment: selectedDepartment});
    } 
    else {
      this.setState({ 
        CategoryList: [],
      });
    }
  }

  async getFilterSub1(SelectedAns, type) {
    this.ClearSubData(type);
    const AnsArray = (type === 'SelectCategory') ? 'Sub1List' : (type === 'SelectedSub1') ? 'Sub2List' : (type === 'SelectedSub2') ? 'Sub3List' : (type === 'SelectedSub3') ? 'Sub4List' : (type === 'SelectedSub4') ? 'Sub5List' : (type === 'SelectedSub5') ? 'Sub6List' : ''
    if (SelectedAns !== '') {
      const AnsArray = (type === 'SelectCategory') ? 'Sub1List' : (type === 'SelectedSub1') ? 'Sub2List' : (type === 'SelectedSub2') ? 'Sub3List' : (type === 'SelectedSub3') ? 'Sub4List' : (type === 'SelectedSub4') ? 'Sub5List' :  (type === 'SelectedSub5') ? 'Sub6List' : ''
      let FilterSub1 = '';
      await this.setState({ [AnsArray]: []});
      const getCategoryList = await getCategory(
        this.props.username, 
        this.props.token,
        {
          department: this.state.SelectDepartment,
          Category: (type === 'SelectCategory') ? SelectedAns : this.state.SelectCategory, 
          sub1: (type === 'SelectedSub1') ? SelectedAns : this.state.SelectedSub1,
          sub2: (type === 'SelectedSub2') ? SelectedAns : this.state.SelectedSub2,
          sub3: (type === 'SelectedSub3') ? SelectedAns : this.state.SelectedSub3, 
          sub4: (type === 'SelectedSub4') ? SelectedAns : this.state.SelectedSub4,
          sub5: (type === 'SelectedSub5') ? SelectedAns : this.state.SelectedSub5,
        },
      );
      const Answer = (type === 'SelectCategory') ? 'Sub1' : (type === 'SelectedSub1') ? 'Sub2' : (type === 'SelectedSub2') ? 'Sub3' : (type === 'SelectedSub3') ? 'Sub4' : (type === 'SelectedSub4') ? 'Sub5' : (type === 'SelectedSub5') ? 'Sub6' :  ''
      FilterSub1 = getCategoryList.map((item) => item[Answer])
      FilterSub1 = [...new Set(FilterSub1)];
      this.setState({ [AnsArray]: FilterSub1, [type]: SelectedAns});
    } 
    else {
      this.setState({ 
        [AnsArray]: [],
      });
    }
  }

  ClearSubData(type) {
    if (type === 'SelectCategory') {
      this.setState({SelectedSub1: '', SelectedSub2: '', SelectedSub3: '', SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub1') {
      this.setState({SelectedSub2: '', SelectedSub3: '', SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub2') {
      this.setState({SelectedSub3: '', SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub3') {
      this.setState({SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub4') {
      this.setState({SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub5') {
      this.setState({SelectedSub6: ''})
    }
  }

  SelectedOption(e, type) {
    if (type === 'SelectCompany') {
      if (e.target.value === '') {
        this.setState({ SelectCompany: '' })
        // this.FilteredBranch(JSON.parse(this.props.CompanyData))
      } else {
        this.getFilterBranch(e.target.value);
        this.setState({ 
          [type]: e.target.value, 
          // DepartmenList: [],
          // CategoryList: [],
        })
      }
    } else if(type === 'SelectBranch') {
      // this.getFilterDepartment(e.target.value);
      this.setState({ 
        [type]: e.target.value,
        // CategoryList: [],
      })
    } else if(type === 'SelectDepartment') {
      // this.getFilterCategory(e.target.value);
      this.setState({ [type]: e.target.value, ShowFilterDepart: e.target.value })
    } else if(type === 'SelectCategory' || type === 'SelectedSub1' || type === 'SelectedSub2' || type === 'SelectedSub3' || type === 'SelectedSub4' || type === 'SelectedSub5') {
      this.getFilterSub1(e.target.value, type);
      this.setState({ [type]: e.target.value})
      if (type === 'SelectCategory') {
        this.setState({ ShowFilterSub: e.target.value })
      }
      if (type === 'SelectedSub1') {
        this.setState({ ShowFilterSubSub: e.target.value })
      }
    } else if(type === 'SelectLatestUpdate') {
      this.setState({ [type]: e.target.value, ShowFilterStatus: e.target.value })
    } else if(type === 'SelectName') {
      if (e.target.value === '') {
        this.setState({ [type]: 'All'}, () => {
          this.getCategories();
        })
      } else {
        this.setState({ [type]: e.target.value, ShowFilterName: e.target.value }, () => {
          this.getCategories();
        })
      }
    } else if(type === 'fromDate') {
      this.setState({ [type]:  moment(e.target.value).format('YYYY-MM-DD')})
    } else if(type === 'toDate') {
      this.setState({ [type]:  moment(e.target.value).format('YYYY-MM-DD')})
    } 
  }

  async handleSubmit(action) {
    this.CallHeader();
    if (action === true) {
      this.setState({ RunTotalPage: [] })
    }
    const DataObj = {
      selectedUser: this.state.SelectName,
      selectedCompany: this.state.SelectCompany, 
      selectedBranch: this.state.SelectBranch, 
      selectedDepartment: this.state.SelectDepartment, 
      selectedCategory: this.state.SelectCategory,
      subCategory: this.state.SelectedSub1,
      // subCategory2: this.state.SelectedSub2,
      // subCategory3: this.state.SelectedSub3,
      // subCategory4: this.state.SelectedSub4,
      // subCategory5: this.state.SelectedSub5,
      // subCategory6: this.state.SelectedSub6,
      claimStatus: this.state.SelectLatestUpdate,
      claimAmount: this.state.KeyinAmount,
      approvedAmount: this.state.ApprovedAmount,
      FirstApprove: this.state.FirstApprove,
      FirstApproveStatus: this.state.FirstApproveStatus,
      // SecondApprove: this.state.SecondApprove,
      // SecondApproveStatus: this.state.SecondApproveStatus,
      Accountant: this.state.Accountant,
    }
   
    this.setState({ TableLoading: true , ReportDataList: []});
    const getData = await cashOutClaimListFNC(this.state.fromDate, this.state.toDate, DataObj, 'Report', this.props.username, this.state.page, this.state.countperpage, this.props.token)
    if (getData.error === 'Invalid credentials!') {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      this.props.setLoginStatus('', '', '');
      alert('Token Error');
      this.props.history.push('/')
    } else if(getData.message === 'No record for these day') {
      this.openNotification(`${language[this.props.currentLanguage].ErrorNoRecod}`,'error')
      this.setState({ TableLoading: false, ReportDataList: 0, ClaimAmount: 0, ApproveAmt: 0 })
    } else {
      // let FilterName = []
      // let FilterDepartment = []
      // let FilterSub = []
      // let FilterSubSub = []
      // let FilterApprovalStatus = []
      // let FilterSecondApprovalStatus = []
      // let FilterStatus = []
      // let FilterAmount = []
      // let FilterApprove = []
      // let FilterSecondApprover = []
      // let FilterAccounting = []
      // let FilterApproveAmount = []
      // getReport.getClaimReport.forEach((items) => {
      //   FilterName.push(items.Name)
      //   FilterDepartment.push(items.Department)
      //   FilterSub.push(items.Category)
      //   FilterSubSub.push(items.Sub1)
      //   FilterApprovalStatus.push(items.FirstApproveStatus)
      //   FilterSecondApprovalStatus.push(items.SecondApproveStatus)
      //   FilterStatus.push(items.Status)
      //   FilterAmount.push(items.Amount)
      //   FilterApprove.push(items.FirstApprove)
      //   FilterSecondApprover.push(items.SecondApprove)
      //   FilterAccounting.push(items.Accountant)
      //   FilterApproveAmount.push(items.ApproveAmount)
      // })
      // FilterName = [...new Set(FilterName)].filter(function (el) { return el !== '' && el !== null});
      // FilterDepartment = [...new Set(FilterDepartment)].filter(function (el) { return el !== '' && el !== null});
      // FilterSub = [...new Set(FilterSub)].filter(function (el) { return el !== '' && el !== null});
      // FilterSubSub = [...new Set(FilterSubSub)].filter(function (el) { return el !== '' && el !== null});
      // FilterApprovalStatus = [...new Set(FilterApprovalStatus)].filter(function (el) { return el !== '' && el !== null});
      // FilterSecondApprovalStatus = [...new Set(FilterSecondApprovalStatus)].filter(function (el) { return el !== '' && el !== null});
      // FilterStatus = [...new Set(FilterStatus)].filter(function (el) { return el !== '' && el !== null});
      // FilterAmount = [...new Set(FilterAmount)].filter(function (el) { return el !== '' && el !== null});
      // FilterApprove = [...new Set(FilterApprove)].filter(function (el) { return el !== '' && el !== null});
      // FilterSecondApprover = [...new Set(FilterSecondApprover)].filter(function (el) { return el !== '' && el !== null});
      // FilterAccounting = [...new Set(FilterAccounting)].filter(function (el) { return el !== '' && el !== null});
      // FilterApproveAmount = [...new Set(FilterApproveAmount)].filter(function (el) { return el !== '' && el !== null});
      this.setState({ 
        ReportDataList: getData.claimList,
        ClaimAmount: getData.totalAmtOfClaim.TotalAmount,
        ApproveAmt:  getData.totalAmtOfClaim.TotalApproveAmount,
        TableLoading: false,
        AllSelectValue: getData.claimList,
        TotalPages: getData.totalPage,
      }, () => {
        if (this.state.ReportDataList.length > 0) {
          this.setState({ OpenFilter: false })
        }
        this.RunTotalPage();
      });
      if (getData.claimList.length > 0) {
        this.setState({
          countpagemax: Math.ceil(
            getData.claimList[0].FullCount / this.state.countperpage,
          ),
        });
      } else {
        this.setState({
          countpagemax: 1,
          page: 1,
        });
      }
    }
  }

  async handleXLSX(){
    const DataObj = {
      selectedUser: this.state.SelectName,
      selectedCompany: this.state.SelectCompany, 
      selectedBranch: this.state.SelectBranch, 
      selectedDepartment: this.state.SelectDepartment, 
      selectedCategory: this.state.SelectCategory,
      subCategory: this.state.SelectedSub1,
      subCategory2: this.state.SelectedSub2,
      subCategory3: this.state.SelectedSub3,
      subCategory4: this.state.SelectedSub4,
      subCategory5: this.state.SelectedSub5,
      subCategory6: this.state.SelectedSub6,
      claimStatus: this.state.SelectLatestUpdate,
      claimAmount: this.state.KeyinAmount,
      approvedAmount: this.state.ApprovedAmount,
      FirstApprove: this.state.FirstApprove,
      FirstApproveStatus: this.state.FirstApproveStatus,
      Accountant: this.state.Accountant,
    }
    const getExportData = await exportClaimListFNC(this.state.fromDate, this.state.toDate, DataObj, 'Report', this.props.username, this.props.token);
    await exportReportExcel(getExportData.claimList, this.state.fromDate, this.state.toDate, this.props.currentLanguage, 'Report')
  }
  
  async onKeyEnter(e) {
    if (e.charCode === 13) {
      this.handleSubmit();
    }
  }

  OpenReportDetails(SelectedClaim) {
    // this.setState({ ReportModal: true })
    this.setState({ ReportModal: true, SelectedClaimObject: SelectedClaim }, () => {
      this.DisplayHistorySet();
    })
  }

  handleClose() {
    this.setState({
      ReportModal: false,
      OpenReceipt: false,
      SelectedClaimObject: {},
    })
    this.CloseHistorySet();
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  async ReClaimSelectedClaim(Amount) {
    const SelectedClaimObject = this.state.SelectedClaimObject
    let FinalObj = {}
    FinalObj = {
      selectedDate: this.state.selectedDate,
      Username: SelectedClaimObject.UploadBy,
      Company: SelectedClaimObject.Company,
      Branch: SelectedClaimObject.Branch,
      Department: SelectedClaimObject.Department,
      Category: SelectedClaimObject.Category,
      Sub1: SelectedClaimObject.Sub1,
      Sub2: SelectedClaimObject.Sub2,
      Sub3: SelectedClaimObject.Sub3,
      Sub4: SelectedClaimObject.Sub4,
      Sub5: SelectedClaimObject.Sub5,
      Sub6: SelectedClaimObject.Sub6,
      Title: SelectedClaimObject.Subject,
      Remark: SelectedClaimObject.Comment,
      Amount: Amount,
      Document: [],
    }
    const r = (window.confirm(`${language[this.props.currentLanguage].ConfirmReClaimMessage}`));
    if (r === true) {
      const PostClaims = await PostClaim(this.props.username, this.props.token, FinalObj)
      if (PostClaims.message === 'Upload Success') {
        this.openNotification(`${language[this.props.currentLanguage].SuccessReclaim}`,'success')
        this.handleClose();
        this.handleSubmit(true);
      }
    } else {
      return null;
    }
  }

  DisplayHistorySet() {
    this.setState({ DisplayHistory: !this.state.DisplayHistory }, async() => {
      const EditID = this.state.SelectedClaimObject.ID
      const callHistoryData = await getClaimHistoryRecordFNC(EditID, this.props.username, this.props.token)
      if(callHistoryData.error === 'database error') {
        this.openNotification(`${language[this.props.currentLanguage].DatabaseError}`,'error')
        this.CloseHistorySet();
      } else {
        this.setState({ HistoryData: callHistoryData })
      }
    })
  }

  CloseHistorySet() {
    this.setState({ HistoryData: [], DisplayHistory: false })
  }

  FilterTableView(e, Selecttype) {
    // if(Selecttype === 'SelectedSub1') {
    //   this.setState({ ShowFilterSubSub: e, SelectedSub1: (e === 'All') ? '' : e}, () => { this.UserCallApi(true); })
    // }
    if(Selecttype === 'ShowFilterStatus') {
      this.setState({ ShowFilterStatus: e, SelectLatestUpdate: (e === 'All') ? '' : e }, () => { this.handleSubmit(true); })
    }
    if(Selecttype === 'ShowFilterName') {
      this.setState({ ShowFilterName: e, SelectName: e }, () => { this.handleSubmit(true); })
    }
    if(Selecttype === 'ShowFilterDepart') {
      this.setState({ ShowFilterDepart: [e], SelectDepartment: (e === 'All') ? '' : [e] }, () => { this.handleSubmit(true); this.getCategories();})
    }
    if(Selecttype === 'ShowFilterSub') {
      this.setState({ ShowFilterSub: e, SelectCategory: (e === 'All') ? '' : e  }, () => { this.handleSubmit(true); })
    }
    if(Selecttype === 'ShowFilterSubSub') {
      this.setState({ ShowFilterSubSub: e, SelectedSub1: (e === 'All') ? '' : e  }, () => { this.handleSubmit(true); })
    }
    if(Selecttype === 'ShowFilterAmount') {
      this.setState({ ShowFilterAmount: e, KeyinAmount: (e === 'All') ? '' : e  }, () => { this.handleSubmit(true); })
    }
    if(Selecttype === 'ShowFilterApprover') {
      this.setState({ ShowFilterApprover: e, FirstApprove: (e === 'All') ? '' : e }, () => { this.handleSubmit(true); })
    }
    if(Selecttype === 'ShowFilterApprovalStatus') {
      this.setState({ ShowFilterApprovalStatus: e, FirstApproveStatus: (e === 'All') ? '' : e }, () => { this.handleSubmit(true); })
    }
    if(Selecttype === 'ShowFilterApproveAmount') {
      this.setState({ ShowFilterApproveAmount: e, ApprovedAmount: (e === 'All') ? '' : e }, () => { this.handleSubmit(true); })
    }
    if(Selecttype === 'ShowFilterAccounting') {
      this.setState({ ShowFilterAccounting: e, Accountant: (e === 'All') ? '' : e }, () => { this.handleSubmit(true); })
    }
    this.setState({ page: 1 })
  }

  ClearFilter() {
    this.setState({ 
      ShowFilterStatus: 'All',
      ShowFilterName: 'All',
      ShowFilterSub: 'All',
      ShowFilterDepart: 'All',
      ShowFilterSubSub: 'All',
      ShowFilterAmount: 'All',
      ShowFilterApprover: 'All',
      ShowFilterApprovalStatus: 'All',
      ShowFilterSecondApprover: 'All',
      ShowFilterSecondApprovalStatus: 'All',
      ShowFilterApproveAmount: 'All',
      ShowFilterAccounting: 'All',
      SelectCompany: '',
      SelectBranch: [],
      SelectDepartment: [],
      SelectedSub1: '',
      SelectedSub2: '',
      SelectedSub3: '',
      SelectedSub4: '',
      SelectedSub5: '',
      SelectedSub6: '',
      SelectName: 'All',
      SelectCategory: '',
      SelectLatestUpdate: '',
      KeyinAmount: '',
      ApprovedAmount: '',
    })
  }

  SetStatePage(Type) {
    if (Type === 'Next') {
      this.setState({ page: this.state.page + 1 },() => {
        this.changePage();
      })
    } else {
      this.setState({ page: this.state.page - 1 },() => { this.changePage(this.state.page);},
      )
    }
  }

  changePage() {
    let RunTotalPage = this.state.RunTotalPage
    const TotalPages = this.state.TotalPages
    let Front = RunTotalPage[0]
    let Back = RunTotalPage[RunTotalPage.length - 1]
    if (TotalPages > 5 && this.state.page === Back && this.state.page !== Number(TotalPages)) {
      if (Number(TotalPages) - Back >= 2) {
        const AfterFilter = RunTotalPage.filter(item => item !== Front && item !== RunTotalPage[1]);
        AfterFilter.push(Back + 1)
        AfterFilter.push(Back + 2)
        this.setState({ RunTotalPage: AfterFilter })
      } else if (Number(TotalPages) - Back === 1) {
        const AfterFilter = RunTotalPage.filter(item => item !== Front);
        AfterFilter.push(Back + 1)
        this.setState({ RunTotalPage: AfterFilter })
      }
    }
    if (TotalPages > 5 && this.state.page === Front && this.state.page !== 1) {
      if (Front - 1 >= 2) {
        const AfterFilter = RunTotalPage.filter(item => item !== Back && item !== RunTotalPage[RunTotalPage.length - 2]);
        AfterFilter.unshift(Front - 1)
        AfterFilter.unshift(Front - 2)
        this.setState({ RunTotalPage: AfterFilter })
      } else if (Front - 1 === 1) {
        const AfterFilter = RunTotalPage
        AfterFilter.unshift(Front - 1)
        this.setState({ RunTotalPage: AfterFilter })
      }
    }
    if (this.state.page === 1 && RunTotalPage.length === 5) {
      this.setState({
        RunTotalPage: [],
      })
    }
    if (this.state.page === TotalPages && RunTotalPage.length === 5) {
      this.setState({ RunTotalPage: [] })
      let AddArray = []
      AddArray.push(Number(TotalPages) - 4)
      AddArray.push(Number(TotalPages) - 3)
      AddArray.push(Number(TotalPages) - 2)
      AddArray.push(Number(TotalPages) - 1)
      AddArray.push(Number(TotalPages))
      this.setState({ RunTotalPage: AddArray })
    }
    this.setState({ claimData: [] }, () => {
      this.handleSubmit();
    });
  }

  getData(word) {
    this.setState({
      // ShowFilterStatus: 'All',
      // ShowFilterName: 'All',
      // ShowFilterSub: 'All',
      // ShowFilterDepart: 'All',
      // ShowFilterSubSub: 'All',
      // ShowFilterAmount: 'All',
      // ShowFilterApprover: 'All',
      // ShowFilterApprovalStatus: 'All',
      // ShowFilterSecondApprover: 'All',
      // ShowFilterSecondApprovalStatus: 'All',
      // ShowFilterApproveAmount: 'All',
      // ShowFilterAccounting: 'All',
      page: 1,
      countpagemax: 1,
      countpagemin: 1,
    })
    // this.ClearFilter();
    this.handleSubmit(word);
  }

  OpenReceipt() {
    this.setState({ OpenReceipt: !this.state.OpenReceipt, StraighDownload: false })
  }

  handleChangePackageNumber = OptionsPackageNumber => {
    let DepartArray = []
    OptionsPackageNumber.forEach((items) => {
      DepartArray.push(items.value)
    })
    this.setState({ SelectedDeparts: OptionsPackageNumber, SelectDepartment: DepartArray }, () => {this.getCategories();})
  }

  handleChangePackageBranch = OptionsPackageNumber => {
    let BranchArray = []
    OptionsPackageNumber.forEach((items) => {
      BranchArray.push(items.value)
    })
    this.setState({ SelectedBranchs: OptionsPackageNumber, SelectBranch: BranchArray })
  }

  render() {
    return (
      <div>
        <Header/>
        <Container fluid style={{ fontSize: '12px' }}>
          <Alert className="AlertPage" style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
          <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between'}}>
            {this.state.ReportModal === true && 
            <ReportViewDetails  OpenReceipt={this.state.OpenReceipt}   OpenReceiptFunction={this.OpenReceipt} ReportModal={this.state.ReportModal} SelectedClaimObject={this.state.SelectedClaimObject} handleClose={this.handleClose} ReClaimSelectedClaim={this.ReClaimSelectedClaim} DisplayHistory={this.state.DisplayHistory} DisplayHistorySet={this.DisplayHistorySet} HistoryData={this.state.HistoryData}/>
            }
            <div className="non-printable" style={{ display: 'flex', width: '100%',  flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', width: '50%', flexDirection: 'row', flexWrap: 'wrap'}}>
                <h2  className="non-printable" style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Report}</h2>
                <div  className="non-printable" style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto', }}>{language[this.props.currentLanguage].DateFrom}</div>
                <div  className="non-printable" style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Control placeholder="从" value={this.state.fromDate} onChange={(e) => this.SelectedOption(e, 'fromDate')} type="date" style={{ marginRight: 10, width: 180, textAlign: 'center', minWidth: 140 }}/>
                </div>
                <div  className="non-printable" style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto',}}>{language[this.props.currentLanguage].DateTo}</div>
                <div  className="non-printable" style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Control placeholder="到" value={this.state.toDate} onChange={(e) => this.SelectedOption(e, 'toDate')} type="date" style={{ marginRight: 10, width: 180,  textAlign: 'center', minWidth: 140 }}/>
                </div>
              </div>
              {/* <Button   variant={this.state.BorderHide ? "success" :"secondary"} style={{ marginRight: 20}} 
                onClick={()=> this.setState({BorderHide: !this.state.BorderHide})}>{this.state.BorderHide ? language[this.props.currentLanguage].Expand : language[this.props.currentLanguage].Minimize}</Button> */}
            
              <div  className="dcc button" 
                style={{ backgroundColor: this.state.BorderHide ? "#70ca69" :"#6c757d" ,
                  padding: 7 , marginRight: 20 ,fontWeight: 'bold',cursor: 'pointer', borderRadius: 5, width: 150, marginBottom: 10,  color:  this.state.BorderHide ? 'black' : 'white' }}
                variant="success"  onClick={()=> this.setState({BorderHide: !this.state.BorderHide})}>
                <span  >
                  {this.state.BorderHide ? language[this.props.currentLanguage].Expand : language[this.props.currentLanguage].Minimize}
                </span>
              </div>
            
            </div>
          </div>
          {this.state.OpenFilter && 
        <>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%'}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5}}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto',  width: '25%' }}>{language[this.props.currentLanguage].Company}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select 
                    value={this.state.SelectCompany}
                    style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectCompany')}>
                    <option value=''></option>
                    {this.state.CompanyList.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Branch}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  {/* <Form.Select 
                    value={this.state.SelectBranch}
                    style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectBranch')}>
                    <option value=''></option>
                    {this.state.BranchList.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select> */}
                  {this.state.SelectedOptionBranch.length > 0 && 
                  <div style={{ width: 250, maxWidth: 250 }}>
                    <Select closeMenuOnSelect={false} isMulti options={this.state.SelectedOptionBranch} value={this.state.SelectedBranchs} onChange={this.handleChangePackageBranch}/>
                  </div>
                  }
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Subject}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}}  
                    value={this.state.ShowFilterSub}
                    onChange={(e) => this.SelectedOption(e, 'SelectCategory')}>
                    <option value=''></option>
                    {this.state.FilterSub.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 2</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub2')}>
                    <option value=''></option>
                    {this.state.Sub2List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div> */}
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 4</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub4')}>
                    <option value=''></option>
                    {this.state.Sub4List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div> */}
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 6</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub6')}>
                    <option value=''></option>
                    {this.state.Sub6List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div> */}
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Amount}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Control  value={this.state.KeyinAmount} readOnly={false} style={{ width: 250, textAlign: 'center', backgroundColor: 'white'  }} onChange={(e) => this.setState({ KeyinAmount: e.target.value })}/>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, marginBottom: 10}}>
              <div  style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Name}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  {this.props.role === 'User' && 
                      <Form.Select style={{ width: 250}} 
                        value={this.state.ShowFilterName}
                        onChange={(e) => this.SelectedOption(e, 'SelectName')} disabled={true}>
                        <option value={this.state.FilterName[0]}>{this.state.FilterName[0]}</option>
                      </Form.Select>
                  }
                  {this.props.role !== 'User' && 
                      <Form.Select style={{ width: 250}} 
                        value={this.state.ShowFilterName}
                        onChange={(e) => this.SelectedOption(e, 'SelectName')}>
                        <option value='All'>{language[this.props.currentLanguage].All}</option>
                        {this.props.role === 'Account' && <option value={this.props.username}>{this.props.username}</option>}
                        {this.state.FilterName.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                  }
                </div>
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Department}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  {/* <Form.Select 
                    value={this.state.ShowFilterDepart}
                    style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectDepartment')}>
                    <option value=''></option>
                    {this.state.FilterDepartment.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select> */}
                  {this.state.SelectedOptionDepart.length > 0 && 
                  <div style={{ width: 250, maxWidth: 250 }}>
                    <Select closeMenuOnSelect={false} isMulti options={this.state.SelectedOptionDepart} value={this.state.SelectedDeparts} onChange={this.handleChangePackageNumber}/>
                  </div>
                  }
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 1</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub1')}>
                    <option value=''></option>
                    {this.state.Sub1List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div> */}
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 3</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub3')}>
                    <option value=''></option>
                    {this.state.Sub3List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div> */}
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].SubSubject} 5</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.SelectedOption(e, 'SelectedSub5')}>
                    <option value=''></option>
                    {this.state.Sub5List.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div> */}
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].LatestStatus}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} 
                    value={this.state.ShowFilterStatus}
                    onChange={(e) => this.SelectedOption(e, 'SelectLatestUpdate')}>
                    <option value=''></option>
                    {this.state.LatestUpdateList.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{'Page'}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} value={this.state.countperpage} onChange={(e) => this.setState({ countperpage: e.target.value })}>
                    <option value='20'>20</option>
                    <option value='30'>30</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </Form.Select>
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].ApprovedAmount}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Control  value={this.state.ApprovedAmount} readOnly={true} style={{ width: 250, textAlign: 'center', backgroundColor: 'white'  }}/>
                </div>
              </div> */}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginRight: '13%',  marginLeft: '13%'}}>
            <div className="dcc button"
              style={{ backgroundColor: this.state.background,
                padding: 7 , cursor: 'pointer', borderRadius: 5, width: 200, marginBottom: 10, marginTop: 5 }}
              variant="success" onClick={() => this.getData(true)}>
              <span>
                {language[this.props.currentLanguage].Search}
              </span>
            </div>
          </div>
        </>
          }
          {this.state.OpenFilter === false && 
        <div style={{ display: 'flex', flexDirection: 'row', marginRight: '5%', justifyContent: 'space-between'}}>
          <div  className="dcc button" 
            style={{ backgroundColor: this.state.background,
              padding: 7 , cursor: 'pointer', borderRadius: 5, width: 200, marginBottom: 10 }}
            variant="success" onClick={() => this.setState({ OpenFilter: !this.state.OpenFilter }, () => {
              // this.ClearFilter();
            })}>
            <span  >
              {'OpenFilter'}
            </span>
          </div>
          {/* {((this.state.ShowFilterAccounting !== 'All' && this.state.ShowFilterAccounting !== ''))&& */}
          {/* <div className="dcc button"
            style={{ backgroundColor: this.state.background,
              padding: 7 , cursor: 'pointer', borderRadius: 5, width: 200, marginBottom: 10 }}
            variant="success" onClick={() => this.handleXLSX()}>
            <span>
              {'XLSX'}
            </span>
          </div> */}
        </div>}
          {/* <Table striped bordered hover responsive style={{ width: '95%', padding: 0 }}>
          <thead>
            <tr style={{ textAlign: 'center', flexDirection: 'row'}}>
              <th>{language[this.props.currentLanguage].Date}</th>
              <th>
                <div>{language[this.props.currentLanguage].Name}</div>
                {this.state.FilterName.length > 0 && 
                <div><Form.Select style={{ width: 'auto', fontSize: '14px', maxWidth: 100 }} value={this.state.ShowFilterName} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterName')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterName.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </Form.Select></div>
                }
              </th>
              <th>
                <div>{language[this.props.currentLanguage].Department}</div>
                {this.state.FilterDepartment.length > 0 && 
                <div><Form.Select style={{ width: 'auto', fontSize: '14px', maxWidth: 100 }} value={this.state.ShowFilterDepart} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterDepart')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterDepartment.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </Form.Select></div>
                }
              </th>
              <th>
                <div>{language[this.props.currentLanguage].Subject}</div>
                {this.state.FilterSub.length > 0 && 
                <div><Form.Select style={{ width: 'auto', fontSize: '14px', maxWidth: 100 }} value={this.state.ShowFilterSub} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterSub')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterSub.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </Form.Select></div>
                }
              </th>
              <th>
                <div>{language[this.props.currentLanguage].Approver}</div>
                {this.state.FilterApprove.length > 0 && 
                <div><Form.Select style={{ width: 'auto', fontSize: '14px', maxWidth: 100 }} value={this.state.ShowFilterApprover} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterApprover')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterApprove.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </Form.Select></div>
                }
              </th>
              <th>
                <div>{language[this.props.currentLanguage].ApproverStatus}</div>
                {this.state.FilterApprovalStatus.length > 0 && 
                <div><Form.Select style={{ width: 'auto', fontSize: '14px', maxWidth: 100 }} value={this.state.ShowFilterApprovalStatus} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterApprovalStatus')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterApprovalStatus.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </Form.Select></div>
                }
              </th>
              <th>
                <div>{language[this.props.currentLanguage].SecondApprover}</div>
                {this.state.FilterSecondApprover.length > 0 && 
                <div><Form.Select style={{ width: 'auto', fontSize: '14px', maxWidth: 100 }} value={this.state.ShowFilterSecondApprover} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterSecondApprover')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterSecondApprover.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </Form.Select></div>
                }
              </th>
              <th>
                <div>{language[this.props.currentLanguage].SecondApproveStatus}</div>
                {this.state.FilterSecondApprovalStatus.length > 0 && 
                <div><Form.Select style={{ width: 'auto', fontSize: '14px', maxWidth: 100 }} value={this.state.ShowFilterSecondApprovalStatus} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterSecondApprovalStatus')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterSecondApprovalStatus.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </Form.Select></div>
                }
              </th>
              <th>
                <div>{language[this.props.currentLanguage].ApprovedAmount}</div>
                {this.state.FilterApproveAmount.length > 0 && 
                <div><Form.Select style={{ width: 'auto', fontSize: '14px', maxWidth: 100 }} value={this.state.ShowFilterApproveAmount} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterApproveAmount')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterApproveAmount.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </Form.Select></div>
                }
              </th>
              <th>
                <div>{language[this.props.currentLanguage].Accounting}</div>
                {this.state.FilterAccounting.length > 0 && 
                <div><Form.Select style={{ width: 'auto', fontSize: '14px', maxWidth: 100 }} value={this.state.ShowFilterAccounting} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterAccounting')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterAccounting.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </Form.Select></div>
                }
              </th>
              <th>{language[this.props.currentLanguage].EditedDate}</th>
              <th>
                <div>{language[this.props.currentLanguage].LatestStatus}</div>
                {this.state.FilterStatus.length > 0 && 
                <div><Form.Select style={{ width: 'auto', fontSize: '14px', maxWidth: 100 }} value={this.state.ShowFilterStatus}  onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterStatus')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterStatus.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </Form.Select></div>
                }
              </th>
              <th>{language[this.props.currentLanguage].Remark}</th>
              <th>{language[this.props.currentLanguage].View}</th>
            </tr>
          </thead>
          {this.state.ReportDataList.length > 0 ?
            <tbody>
              {this.state.ReportDataList.map((item, index) => <tr key={index}>
                <td style={{ textAlign: 'center'}}>{!item.UploadDateTime ? '-' : moment(item.UploadDateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                <td>{item.Name}</td>
                <td>{item.Department}</td>
                <td>{item.Category}</td>
                <td>{item.FirstApprove}</td>
                <td>{item.FirstApproveStatus}</td>
                <td>{item.SecondApprove}</td>
                <td>{item.SecondApproveStatus}</td>
                <td>{item.ApproveAmount}</td>
                <td>{item.Accountant}</td>
                <td style={{ textAlign: 'center'}}>{!item.ActionDateTime ? '-' : moment(item.ActionDateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                <td>{item.Status}</td>
                <td style={{ wordBreak: 'break-word' }}>{item.Comment.length > 100 ? `${item.Comment.substring(0, 50)}........` : item.Comment}</td>
                <td><Button onClick={() => this.OpenReportDetails(item)}>{language[this.props.currentLanguage].View}</Button></td>
              </tr>)}
              <div style={{ display: 'flex', flexDirection: 'row', width: 'auto', fontWeight: 'bold' }}>
                {this.state.page > this.state.countpagemin && 
                <div onClick={()=> this.SetStatePage('previous')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>{'<<<'}</div>
                }
                <div style={{ fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>{`${this.state.page}`}</div>
                {this.state.page < this.state.countpagemax && 
                <div onClick={()=> this.SetStatePage('Next')} style={{ cursor: 'pointer' }}>{'>>>'}</div>
                }
              </div>
            </tbody>
            :
            <tbody>
              <tr style={{ backgroundColor: 'lightgray'}}>
                <td colSpan="16" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>
                  {this.state.TableLoading ? <img className="TableLoginIcon" src={LoadingIcon} alt="LoadingIcon"/> : `${language[this.props.currentLanguage].NoData}`}
                </td>
              </tr>
            </tbody>}
          <thead>
            <td className='ReportAmountTable' style={{ borderWidth: 1}} colspan="5">{language[this.props.currentLanguage].Total}:</td>
            <td className='ReportAmountTable' style={{ borderWidth: 1}}>{Number(this.state.ClaimAmount).toFixed(2)}</td>
            <td className='ReportAmountTable'  style={{ borderWidth: 1}} colspan="4"/>
            <td className='ReportAmountTable'  style={{ borderWidth: 1}} >{Number(this.state.ApproveAmt).toFixed(2)}</td>
            <td className='ReportAmountTable'  style={{ borderWidth: 1}} colspan="5"/>
          </thead>
        </Table> */}
          <div className="non-printable dcc" >
            <div className="dcc" style={{ width: '80%', marginBottom: 50 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                <div  style={{ flex: "1", border: "1px solid black", padding: 5}}>
                  <Fragment>
                    <div>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: '#4A5874', padding: 5 }}>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Date}
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* <div>{language[this.props.currentLanguage].Name}</div> */}
                            <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterName} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterName')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Name}`}</option>
                              {this.state.FilterName.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select></div>
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* <div>{language[this.props.currentLanguage].Department}</div> */}
                            <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterDepart} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterDepart')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Department}`}</option>
                              {this.state.FilterDepartment.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select></div>
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* <div>{language[this.props.currentLanguage].Subject}</div> */}
                            <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterSub} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterSub')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Subject}`}</option>
                              {this.state.FilterSub.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select></div>
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* <div>{language[this.props.currentLanguage].Subject}</div> */}
                            <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterAmount} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterAmount')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Amount}`}</option>
                              {this.state.FilterAmount.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select></div>
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* <div>{language[this.props.currentLanguage].Subject}</div> */}
                            <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterSubSub} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterSubSub')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Subject}1`}</option>
                              {this.state.FilterSubSub.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select></div>
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* <div>{language[this.props.currentLanguage].Approver}</div> */}
                            <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterApprover} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterApprover')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Approver}`}</option>
                              {this.state.FilterApprove.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select></div>
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* <div>{language[this.props.currentLanguage].ApproverStatus}</div> */}
                            <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterApprovalStatus} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterApprovalStatus')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].ApproverStatus}`}</option>
                              {this.state.FilterApprovalStatus.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select></div>
                          </div>
                          {/* <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                          <div>{language[this.props.currentLanguage].SecondApprover}</div>
                          {this.state.FilterSecondApprover.length > 0 && 
                <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterSecondApprover} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterSecondApprover')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterSecondApprover.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </select></div>
                          }
                        </div> */}
                          {/* <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                          <div>{language[this.props.currentLanguage].SecondApproveStatus}</div>
                          {this.state.FilterSecondApprovalStatus.length > 0 && 
                <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterSecondApprovalStatus} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterSecondApprovalStatus')}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.FilterSecondApprovalStatus.map((item, index) => 
                    <option key={index} value={item}>{item}</option>
                  )}
                </select></div>
                          }
                        </div> */}
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* <div>{language[this.props.currentLanguage].ApprovedAmount}</div> */}
                            <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterApproveAmount} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterApproveAmount')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].ApprovedAmount}`}</option>
                              {this.state.FilterApproveAmount.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select></div>
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* <div>{language[this.props.currentLanguage].Accounting}</div> */}
                            <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterAccounting} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterAccounting')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Accounting}`}</option>
                              {this.state.FilterAccounting.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select></div>
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* <div>{language[this.props.currentLanguage].Accounting}</div> */}
                            <div><select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.ShowFilterStatus} onChange={(e) => this.FilterTableView(e.target.value, 'ShowFilterStatus')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].LatestStatus}`}</option>
                              {this.state.FilterStatus.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select></div>
                          </div>
                          <div className={this.state.BorderHide ? "resulttableTitle BorderHideTrue" : "resulttableTitle BorderHideFalse"} style={{width:'calc(100%)' }}
                            onClick={() => this.setState({BorderHide: !this.state.BorderHide})}>
                            {language[this.props.currentLanguage].Remark}
                          </div>
                          <div className="resulttableTitle" style={{width:'50%', color: 'white'}}>
                            {language[this.props.currentLanguage].View}
                          </div>
                        </div>
                      </div>
                      {this.state.ReportDataList.length > 0 && 
                  <>
                    {this.state.ReportDataList.map((item, index) => {
                      return(
                        <div className='dcc' key={index} style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (index%2) ? '#F1F1F1' : 'white' }}>
                          <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                            <div className="resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {!item.UploadDateTime ? '-' : moment(item.UploadDateTime).format('DD/MM/YYYY')}
                            </div>
                            <div className="resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.Name}
                            </div>
                            <div className=" resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.Department}
                            </div>
                            <div className=" resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.Category}
                            </div>
                            <div className=" resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.Amount}
                            </div>
                            <div className=" resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.Sub1}
                            </div>
                            <div className=" resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.FirstApprove}
                            </div>
                            <div className=" resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.FirstApproveStatus}
                            </div>
                            {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.SecondApprove}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.SecondApproveStatus}
                            </div> */}
                            <div className=" resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {Number(item.ApproveAmount).toLocaleString()}
                            </div>
                            <div  className=" resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.Accountant}
                            </div>
                            <div className=" resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: (item.Status === 'Pending') ? 'blue' : (item.Status === 'Pending' ) ? 'blue' : (item.Status === 'Reject' || item.Status === 'Return') ? 'red'  : (item.Status === 'CashOut') ? '#00C44A' : '' }}>
                              {item.Status}
                            </div>
                            <div className=" resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              { this.state.BorderHide ? item.Comment : (item.Comment.length > 20 ? `${item.Comment.substring(0, 20)}........` : item.Comment)}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'50%', padding: 1 }}>
                              <button className="MainButton" onClick={() => this.OpenReportDetails(item)}>{language[this.props.currentLanguage].View}</button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                      <div className="dcc button"
                        style={{ backgroundColor: this.state.background,
                          padding: 7 , cursor: 'pointer', borderRadius: 5, width: 80, marginBottom: 10 }}
                        variant="success" onClick={() => this.handleXLSX()}>
                        <span>
                          {'XLSX'}
                        </span>
                      </div>
                      <div className='dcc' style={{ display: 'flex', flexDirection: 'row', width: 'auto', fontWeight: 'bold' }}>
                        {this.state.page > this.state.countpagemin && 
                <div className="pagination" onClick={()=> this.SetStatePage('previous')} style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '20px' }}>
                  <a>🢠</a>
                </div>
                        }
                        {this.state.RunTotalPage[0] !== 1 && 
                        <div
                          className='pagination'
                          // style={{ fontWeight: 'bold', marginLeft: 15, marginRight: 15, borderRadius: 200, padding: 5, backgroundColor: (items === this.state.page) ? '#4A5874' : 'white', cursor: 'pointer', color: (items === this.state.page) ? 'white' : 'black' }}
                          onClick={() => this.setState({ page: 1 },() => { this.changePage() })}>
                          <a href="#" className={(this.state.page === 1) ? 'active' : ''}>{`1`}</a>
                        </div>
                        }
                        {(this.state.RunTotalPage[0] !== 1 && this.state.RunTotalPage[0] - 1 > 1)&& 
                        <div style={{ marginLeft: 5 }}>{`. . . . `}</div>
                        }
                        {this.state.RunTotalPage.map((items, iid) => {
                          return(
                            <div key={iid} style={{ cursor: 'pointer' }}
                              className='pagination'
                              // style={{ fontWeight: 'bold', marginLeft: 15, marginRight: 15, borderRadius: 200, padding: 5, backgroundColor: (items === this.state.page) ? '#4A5874' : 'white', cursor: 'pointer', color: (items === this.state.page) ? 'white' : 'black' }}
                              onClick={() => this.setState({ page: items },() => { this.changePage() })}>
                              <a className={(items === this.state.page) ? 'active' : ''}>{`${items}`}</a>
                            </div>
                          );
                        })}
                        {(this.state.RunTotalPage[this.state.RunTotalPage.length - 1] !== Number(this.state.TotalPages) && (Number(this.state.TotalPages) - this.state.RunTotalPage[this.state.RunTotalPage.length - 1] > 1)) &&
                        <div>{`. . . . `}</div>
                        }
                        {this.state.RunTotalPage[this.state.RunTotalPage.length - 1] !== Number(this.state.TotalPages) && 
                        <div
                          className='pagination'
                          // style={{ fontWeight: 'bold', marginLeft: 15, marginRight: 15, borderRadius: 200, padding: 5, backgroundColor: (items === this.state.page) ? '#4A5874' : 'white', cursor: 'pointer', color: (items === this.state.page) ? 'white' : 'black' }}
                          onClick={() => this.setState({ page: Number(this.state.TotalPages) },() => { this.changePage() })}>
                          <a href="#" className={(this.state.page === Number(this.state.TotalPages)) ? 'active' : ''}>{`${this.state.TotalPages}`}</a>
                        </div>
                        }
                        {this.state.page < this.state.countpagemax && 
                <div className="pagination" onClick={()=> this.SetStatePage('Next')} style={{ cursor: 'pointer', fontSize: '20px' }}>
                  <a>🢡</a>
                </div>
                        }
                      </div>
                      <div></div>
                    </div>
                    <div className='dcc' style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: '#4A5874' }}>
                      <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                          {'Total'}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                          {Number(this.state.ClaimAmount).toLocaleString()}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div> */}
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                          {Number(this.state.ApproveAmt).toLocaleString()}
                        </div>
                        <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div style={{ flexDirection: 'column', display: 'flex', width:'50%', padding: 1 }}>
                          {''}
                        </div>
                      </div>
                    </div>
                  </>
                      }
                      {this.state.ReportDataList.length === 0 &&
                  <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                    <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                      {'No Data'}
                    </div>
                  </div>
                      }
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    CompanyData: state.CompanyData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
