import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import Header from '../Component/Header';
import { getUserList, createUser, getCompany, updateUser, deleteUserFNC } from '../Api'
import { Container, Form, Button, Table, Modal, Alert } from 'react-bootstrap';
// import { Alert } from 'bootstrap';

var timer

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      userList: [],
      modalInput: {
        ID: '',
        Username: '',
        Name: '',
        Password: '',
        Role: 'CEO',
        Active: true,
        Quota: 0,
        Department: [],
      },
      userModal: false,
      selectedCompany: '',
      selectedBranch: '',
      selectedDepartment: '',
      allList: [],
      companyList: [],
      branchList: [],
      departmentList: [],
    }
    this.handleClose = this.handleClose.bind(this)
  }

  async componentDidMount() {
    if(this.props.token === '') {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      window.location.href = '/';
    }
    const allList = await getCompany(this.props.username, this.props.token, '', '')
    let companyList = allList.map((item) => item.Company)
    companyList = [...new Set(companyList)]
    this.setState({ allList, companyList }, () => this.getUserListF())
  }

  async getUserListF() {
    let userList = await getUserList(this.props.username, this.props.token, 'details')
    userList = userList.map((item) => {
      const departmentJSON = JSON.parse(item.Department)
      const DisplayDepartment = this.state.allList.filter((alllistitem) => departmentJSON.includes(alllistitem.ID))
      return {
        ...item,
        Department: departmentJSON,
        DisplayDepartment,
      }
    })

    this.setState({ userList })
  }

  handleClose() {
    this.setState({
      modalInput: {
        ID: '',
        Username: '',
        Name: '',
        Password: '',
        Role: 'CEO',
        Active: true,
        Quota: 0,
        Department: [],
      },
      selectedCompany: '',
      selectedBranch: '',
      selectedDepartment: '',
      userModal: false,
      branchList: [],
      departmentList: [],
    })
  }

  handleModalInputChange(value, field) {
    this.setState({
      modalInput: {
        ...this.state.modalInput,
        [field]: value
      }
    })
  }

  async createUserF() {
    if (this.state.modalInput.Username === '') {
      this.openNotification(`${language[this.props.currentLanguage].EmptyUsername}`,'error')
    } else if (this.state.modalInput.Name === '') {
      this.openNotification(`${language[this.props.currentLanguage].EmptyName}`,'error')
    } else if (this.state.modalInput.Password === '') {
      this.openNotification(`${language[this.props.currentLanguage].Password}`,'error')
    } else {
      const createUserResult = await createUser(this.props.username, this.props.token, this.state.modalInput)
      if (createUserResult.message === 'success') {
        this.openNotification(`${language[this.props.currentLanguage].SuccessAddUser}`,'success')
        this.handleClose()
        this.getUserListF()
      }
      if (createUserResult.error === 'Duplicate Username') {
        this.openNotification(`${language[this.props.currentLanguage].DuplicateUsername}`,'error')
        this.handleClose()
      }
      if (createUserResult.error === 'Duplicate Name') {
        this.openNotification(`${language[this.props.currentLanguage].DuplicateUsername}`,'error')
        this.handleClose()
      }
    }
  }

  async updateUserF() {
    if (this.state.modalInput.Username === '') {
      this.openNotification(`${language[this.props.currentLanguage].EmptyUsername}`,'error')
    } else if (this.state.modalInput.Name === '') {
      this.openNotification(`${language[this.props.currentLanguage].EmptyName}`,'error')
    } else if (this.state.modalInput.Password === '') {
      this.openNotification(`${language[this.props.currentLanguage].Password}`,'error')
    } else {
      const updateUserResult = await updateUser(this.props.username, this.props.token, this.state.modalInput)
      if (updateUserResult.message === 'success') {
        this.openNotification(`${language[this.props.currentLanguage].SuccessEditUser}`,'success')
        this.handleClose()
        this.getUserListF()
      } else if (updateUserResult.error === 'Duplicate Username') {
        this.openNotification(`${language[this.props.currentLanguage].DuplicateUsername}`,'error')
        this.handleClose()
      }
    }
  }

  filterDepartment(mode) {
    if (mode === 'company') {
      let branchList = this.state.allList.filter((item) => item.Company === this.state.selectedCompany ).map((item) => item.Branch)
      branchList = [...new Set(branchList)]
      this.setState({ branchList })
    } else if (mode === 'branch') {
      let departmentList = this.state.allList.filter((item) => item.Company === this.state.selectedCompany && item.Branch === this.state.selectedBranch).map((item) => item.Department)
      departmentList = [...new Set(departmentList)]
      this.setState({ departmentList })
    } 
  }

  addDepartment() {
    let searchedDepartment
    if (this.state.selectedCompany === 'all') {
      searchedDepartment = this.state.allList
    } else if (this.state.selectedCompany !== '' && this.state.selectedBranch === 'all') {
      searchedDepartment = this.state.allList.filter((item) => item.Company === this.state.selectedCompany)
    } else if (this.state.selectedCompany !== '' && this.state.selectedBranch !== '' && this.state.selectedDepartment === 'all') {
      searchedDepartment = this.state.allList.filter((item) => item.Company === this.state.selectedCompany && item.Branch === this.state.selectedBranch)
    } else if (this.state.selectedCompany !== '' && this.state.selectedBranch !== '' && this.state.selectedDepartment !== '') {
      searchedDepartment = this.state.allList.filter((item) => item.Company === this.state.selectedCompany && item.Branch === this.state.selectedBranch && item.Department === this.state.selectedDepartment)
    } else if (this.state.selectedCompany !== '' && this.state.selectedBranch !== '') {
      searchedDepartment = this.state.allList.filter((item) => item.Company === this.state.selectedCompany && item.Branch === this.state.selectedBranch)
    } else if (this.state.selectedCompany !== '') {
      searchedDepartment = this.state.allList.filter((item) => item.Company === this.state.selectedCompany)
    } else {
      this.openNotification(`${language[this.props.currentLanguage].Error}`,'error')
      return
    }
    const currentDepartment = JSON.parse(JSON.stringify(this.state.modalInput.Department))
    for (let i = 0; i < searchedDepartment.length; i += 1) {
      const found = currentDepartment.find((item) => item === searchedDepartment[i].ID)
      if (found) {
        // ignore
      } else {
        currentDepartment.push(searchedDepartment[i].ID)
      }
    }
    this.setState({ modalInput: {
      ...this.state.modalInput,
      Department: currentDepartment,
    }})
  }

  deleteDepartment(selectedID) {
    let currentDepartment = JSON.parse(JSON.stringify(this.state.modalInput.Department))
    currentDepartment = currentDepartment.filter((item) => item !== selectedID)
    this.setState({ modalInput: {
      ...this.state.modalInput,
      Department: currentDepartment,
    }})
  }

  editUser(profile) {
    this.setState({
      modalInput: {
        ID: profile.ID,
        Username: profile.Username,
        Name: profile.Name,
        Password: profile.Password,
        Role: profile.Role,
        Active: profile.Active,
        Quota: profile.Quota,
        Department: profile.Department,
      },
      userModal: true,
    })
  }

  async DeleteUser(SelectedUser) {
    const r = (window.confirm(`${language[this.props.currentLanguage].ConfirmDeleteUser}`));
    if (r === true) {
      const DeleteSelectedUser = await deleteUserFNC(SelectedUser, this.props.username, this.props.token)
      if(DeleteSelectedUser.error === 'database error') {
        this.openNotification(`${language[this.props.currentLanguage].DatabaseError}`,'error')
      }
      if(DeleteSelectedUser.message === 'Deleted successful') {
        this.openNotification(`${language[this.props.currentLanguage].SuccessDeletedSelected}`,'success')
        this.handleClose()
        this.getUserListF()
      }
      if(DeleteSelectedUser.error === 'Unable delete user because have one month claim record') {
        this.openNotification(`${language[this.props.currentLanguage].CannotDeleteUser}`,'error')
      }
    } else {
      return null;
    }
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  render() {
    return (
      <div>
        <Header/>
        <Container fluid style={{ fontSize: '15px' }}>
          <Alert className='AlertPage' style={{ zIndex: 999999, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
          <Modal centered show={this.state.userModal} size="lg" onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {language[this.props.currentLanguage].Users}
              </Modal.Title>
              {/* <Alert className="ModalAlertDesign" style={{ zIndex: 99, position: 'fixed', top: 80 }} show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
              <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
            </Alert> */}
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ width: 100 }}>{language[this.props.currentLanguage].Users}</div>
                <Form.Control style={{ width: 200 }} value={this.state.modalInput.Username} type="text" onChange={(e) => this.handleModalInputChange(e.target.value, 'Username')}/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ width: 100 }}>{language[this.props.currentLanguage].Name}</div>
                <Form.Control style={{ width: 200 }} value={this.state.modalInput.Name} type="text" onChange={(e) => this.handleModalInputChange(e.target.value, 'Name')}/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ width: 100 }}>{language[this.props.currentLanguage].Password}</div>
                <Form.Control style={{ width: 200 }} value={this.state.modalInput.Password} type="text" onChange={(e) => this.handleModalInputChange(e.target.value, 'Password')}/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ width: 100 }}>{language[this.props.currentLanguage].Role}</div>
                <Form.Select style={{ width: 200 }} value={this.state.modalInput.Role} onChange={(e) => this.handleModalInputChange(e.target.value, 'Role')}>
                  <option value='CEO'>CEO</option>
                  <option value='Account'>Account</option>
                  <option value='User'>User</option>
                </Form.Select>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ width: 100 }}>{language[this.props.currentLanguage].Active}</div>
                <Form.Check style={{ width: 200 }} type="checkbox" checked={this.state.modalInput.Active === 1 ? true : false} onChange={(e) => this.handleModalInputChange(e.target.checked ? 1 : 0, 'Active')}/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ width: 100 }}>{language[this.props.currentLanguage].Quota}</div>
                <Form.Control style={{ width: 200 }} value={this.state.modalInput.Quota} type="text" onChange={(e) => this.handleModalInputChange(e.target.value, 'Quota')}/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ width: 100 }}>{language[this.props.currentLanguage].Department}</div>
                <div style={{ padding: 10, width: '80%', border: '1px solid #ccc'}}>
                  {
                    this.state.modalInput.Department.map((item, index) => 
                      <div key={index} style={{ padding: 10, border: '1px solid #ccc' }}>
                        {this.state.allList.filter((alllistitem) => alllistitem.ID === item).map((item2) => <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <div style={{ marginRight: 10 }}>{item2.Company}</div>
                          -
                            <div style={{ marginLeft: 10, marginRight: 10 }}>{item2.Branch}</div>
                          -
                            <div style={{ marginLeft: 10, marginRight: 10 }}>{item2.Department}</div>
                          </div>
                          <Button onClick={() => this.deleteDepartment(item)} variant="danger">{language[this.props.currentLanguage].Delete}</Button>
                        </div>)}
                      </div>)
                  }
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '25%' }}>
                  {language[this.props.currentLanguage].Company}
                  <Form.Select value={this.state.selectedCompany} onChange={(e) => this.setState({ selectedCompany: e.target.value, selectedBranch: '', selectedDepartment: ''}, () => this.filterDepartment('company'))}>
                    <option></option>
                    <option value="all">{language[this.props.currentLanguage].All}</option>
                    {this.state.companyList.length > 0 && this.state.companyList.map((item, index) => 
                      <option value={item} key={index}>{item}</option>
                    )}
                  </Form.Select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '25%' }}>
                  {language[this.props.currentLanguage].Branch}
                  {this.state.selectedCompany !== '' && this.state.selectedCompany !== 'all' &&
                  <Form.Select value={this.state.selectedBranch} onChange={(e) => this.setState({ selectedBranch: e.target.value, selectedDepartment: ''}, () => this.filterDepartment('branch'))}>
                    <option></option>
                    <option value="all">{language[this.props.currentLanguage].All}</option>
                    {this.state.branchList.length > 0 && this.state.branchList.map((item, index) => 
                      <option value={item} key={index}>{item}</option>
                    )}
                  </Form.Select>}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '25%' }}>
                  {language[this.props.currentLanguage].Department}
                  {this.state.selectedCompany !== '' && this.state.selectedCompany !== 'all'  && this.state.selectedBranch !== 'all' && this.state.selectedBranch !== '' &&
                  <Form.Select value={this.state.selectedDepartment} onChange={(e) => this.setState({ selectedDepartment: e.target.value})}>
                    <option></option>
                    <option value="all">{language[this.props.currentLanguage].All}</option>
                    {this.state.departmentList.length > 0 && this.state.departmentList.map((item, index) =>
                      <option value={item} key={index}>{item}</option>
                    )}
                  </Form.Select>}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '25%' }}>
                  <Button style={{  }} onClick={() => this.addDepartment()} variant="primary">{language[this.props.currentLanguage].Add}</Button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</Button>
              <Button variant="success" onClick={() => this.state.modalInput.ID === '' ? this.createUserF() : this.updateUserF()}>{language[this.props.currentLanguage].submit}</Button>
            </Modal.Footer>
          </Modal>
          <div style={{ display: 'flex', padding: '10px 0px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
              <h2 style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Users}</h2>
            </div>
            <div style={{ display: 'flex' }}>
              <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.setState({ userModal: true })} variant="primary">{language[this.props.currentLanguage].Add}</Button>
            </div>
          </div>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>{language[this.props.currentLanguage].Username}</th>
                <th>{language[this.props.currentLanguage].Name}</th>
                <th>{language[this.props.currentLanguage].Role}</th>
                <th>{language[this.props.currentLanguage].Password}</th>
                <th>{language[this.props.currentLanguage].Active}</th>
                <th>{language[this.props.currentLanguage].Quota}</th>
                {/* <th>{language[this.props.currentLanguage].Department}</th> */}
                <th>{language[this.props.currentLanguage].Action}</th>
              </tr>
            </thead>
            {this.state.userList.length > 0 ?
              <tbody>
                {this.state.userList.map((item, index) => <tr key={index}>
                  <td>{item.Username}</td>
                  <td>{item.Name}</td>
                  <td>{item.Role}</td>
                  <td>{item.Password}</td>
                  <td>{item.Active}</td>
                  <td>{item.Quota}</td>
                  {/* <td>{item.DisplayDepartment.map((item2) => <div style={{display: 'flex', border: '1px solid #ccc', padding: 5}}>
                    <div style={{ marginRight: 10 }}>{item2.Company}</div>
                  -
                    <div style={{ marginLeft: 10, marginRight: 10 }}>{item2.Branch}</div>
                  -
                    <div style={{ marginLeft: 10, marginRight: 10 }}>{item2.Department}</div>
                  </div>)}</td> */}
                  <td>
                    <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.editUser(item)} variant="primary">{language[this.props.currentLanguage].Edit}</Button>
                    <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.DeleteUser(item.ID)} variant="danger">{language[this.props.currentLanguage].Delete}</Button>
                  </td>
                </tr>)}
              </tbody>
              :
              <tbody>
                <tr style={{ backgroundColor: 'lightgray'}}>
                  <td colSpan="6" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                </tr>
              </tbody>}
          </Table>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (username, role, token) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: username,
          role: role,
          token: token,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(User);
  