import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import html2canvas from 'html2canvas';
import pdfMake from "pdfmake"
import { Button, Modal, Form, Table } from 'react-bootstrap';
import { firstApproveClaimFNC,secondApproveClaimFNC, cashOutClaimFNC, EditClaim, rejectClaimFNC, returnClaimFNC } from '../Api';
import moment from 'moment-timezone';

// var timer 

class ViewReceiptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Password: '',
      OpenReceipt: false,
      ReturnClicked: false,
      ReasonText: '',
    }
  }

  OpenSelectedReceipt(SelectedViewReceipt, type) {
    if (type === 'download') {
      const link = SelectedViewReceipt.replace('https://ace-bt.s3.ap-southeast-1.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net').replace('https://ace-bt.s3.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net')
      fetch(link)
        .then(response => response.blob())
        .then(blob => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.download = link.replace(/^.*[\\/]/, '');
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
    } else {
      const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
      if(isIOS) {
        var windowReference = window.open();
        const test = SelectedViewReceipt; // URL of the text file
        fetch(test)
          .then(response => response.text())
          .then(text => {
            if (SelectedViewReceipt.includes('txt')) {
              const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
              const url = URL.createObjectURL(blob);
              windowReference.location = url;
            } else {
              windowReference.location = test;
            }
          });

      } else {
        const test = SelectedViewReceipt; // URL of the text file
        fetch(test)
          .then(response => response.text())
          .then(text => {
            if (SelectedViewReceipt.includes('txt')) {
              const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank', 'noopener,noreferrer');
            } else {
              window.open(test, '_blank', 'noopener,noreferrer');
            }
          });
      }
    }
  }

  async ReturnReceipt(type) {
    let ArrayId = []
    ArrayId.push(this.props.ClaimsData.ID)
    if(type === '批准') {
      if(this.props.ClaimsData.Status === 'Pending') {
        const ApproveClaim = await firstApproveClaimFNC(ArrayId, this.props.username, this.props.token)
        if(ApproveClaim.error === 'Don\'t have authorize to update/reject claim') {
          this.props.openNotification('失误: 您没有此功能的权限','error')
        } 
        if(ApproveClaim.error === 'Cannot approve / invalid approve') {
          this.props.openNotification('失误: 请联系另一位会计批准','error')
        } 
        if(ApproveClaim.message === 'Successful update') {
          this.props.handleClose();
          this.props.getClaimData();
          this.props.openNotification(`成功: 成功批准申请`,'success')
        }
        this.props.CloseViewReceipt();
      } else if(this.props.ClaimsData.Status === 'FirstApprove') {
        const ApproveClaim = await secondApproveClaimFNC(ArrayId, this.props.username, this.props.token)
        if(ApproveClaim.error === 'Don\'t have authorize to update/reject claim') {
          this.props.openNotification('失误: 您没有此功能的权限','error')
        } 
        if(ApproveClaim.error === 'Cannot approve / invalid approve') {
          this.props.openNotification('失误: 请联系另一位会计批准','error')
        }
        if (ApproveClaim.error === 'Cannot approve due to personalQuota') {
          this.openNotification('失误: 请联系另一位会计批准, 个人配额低于申请金额','error')
        }
        if(ApproveClaim.message === 'Successful update') {
          this.props.openNotification(`成功: 成功批准申请`,'success')
          this.props.handleClose();
          this.props.getClaimData();
        }
        this.props.CloseViewReceipt();
      }
    }
    if (type === '出纳') {
      this.props.OpenonfirmPAss();
    }
    if (type === '撤回') {
      const OverRule = await EditClaim({ID: this.props.ClaimsData.ID}, this.props.ClaimsData.Status, this.props.username, this.props.token)
      if(OverRule.message === 'Please upload pdf file with maximum 5 page or below') {
        this.setState({ uploadloading: false })
        this.openNotification(`${language[this.props.currentLanguage].PdfToomanyPage}`,'error')
      }
      if(OverRule.message === 'Redo successful') {
        this.setState({ uploadloading: false })
        this.props.openNotification(`${language[this.props.currentLanguage].SuccessruleOver}`,'success')
        this.props.CloseViewReceipt();
        this.props.handleClose();
        this.props.getClaimData();
      }
    }
    if(type === '驳回申请') {
      const r = (window.confirm('确定要驳回选择申请吗？'));
      if (r === true) {
        const RejectClaim = await rejectClaimFNC(ArrayId, this.props.username, this.props.token);
        if(RejectClaim.error === 'Don\'t have authorize to update/reject claim') {
          this.props.openNotification('失误: 您没有此功能的权限','error')
        } else {
          this.props.openNotification('成功: 成功驳回申请','success')
          this.props.CloseViewReceipt();
          this.props.handleClose();
          this.props.getClaimData();
        }
      } else {
        return null;
      }
    }
    if(type === '下载单据') {
      this.setState({ OpenReceipt: true }, () => {
        this.ExportPDF();
      })
    }
    if(type === '查看单据') {
      this.setState({ OpenReceipt: true })
    }
    if(type === 'return') {
      this.setState({ ReturnClicked: !this.state.ReturnClicked })
    }
    if(type === 'ConfirmReturn') {
      const ReasonText = this.state.ReasonText
      if(ReasonText === '') {
        this.props.openNotification(`${language[this.props.currentLanguage].ErrorReason}`,'error')
      } else {
        const ReturnClaim = await returnClaimFNC(ArrayId, ReasonText, this.props.username, this.props.token)
        if (ReturnClaim.message === 'Return successful'){
          this.props.openNotification(`${language[this.props.currentLanguage].SuccessReturn}`,'success')
          this.props.CloseViewReceipt();
          this.props.handleClose();
          this.props.getClaimData();;
        }
      }
    }
  }

  onKeyEnter(e) {
    if (e.charCode === 13) {
      this.ConfirmPasswordAPI(this.props.ClaimsData)
    }
  }

  async ConfirmPasswordAPI(SelectedClaim) {
    const Password = this.state.Password
    const CashOut = await cashOutClaimFNC(SelectedClaim.ID, SelectedClaim.UploadBy, Password, this.props.username, this.props.token)
    if(CashOut.error === 'Don\'t have authorize to update/reject claim') {
      this.props.openNotification(`${language[this.props.currentLanguage].DontHaveAutho}`,'error')
    } else if (CashOut.error === 'Cannot approve / invalid approve') {
      this.props.openNotification(`${language[this.props.currentLanguage].ErrorCannotCashOut}`,'error')
    } else if (CashOut.error === 'Unable cashout due to wrong password') {
      this.props.openNotification(`${language[this.props.currentLanguage].ErrorPassword}`,'error')
    } else if (CashOut.message === 'Successful cashout') {
      this.props.openNotification(`${language[this.props.currentLanguage].SuccessCashOut}`,'success')
      this.props.CloseViewReceipt();
      this.props.handleClose();
      this.props.getClaimData();
    }
  }

  OnchangePassword(e) {
    this.setState({ Password: e.target.value })
  }

  OpenReceiptFunction() {
    this.setState({ OpenReceipt: !this.state.OpenReceipt })
  }

  ExportPDF() {
    html2canvas(document.getElementById("appear")).then(canvas => {
      var data = canvas.toDataURL();
      var pdfExportSetting = {
        content: [
          {
            image: data,
            width: 500
          }
        ]
      };
      pdfMake.createPdf(pdfExportSetting).download(`Receipt-${moment(new Date()).format('DD-MM-YYYY HH:mm:ss')}/${this.props.ClaimsData.Name}.pdf`);
    });
    this.OpenReceiptFunction();
  }
  
  printWindow() {
    window.print();
  }

  render() {
    return (
      <Modal centered show={this.props.OpenViewReceiptModal} size="lg" onHide={this.props.CloseViewReceipt} className='printable' style={{ fontSize: '12px'}}>
        {this.props.ConfirmPass === false && 
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            View Claim Receipt (点击图片观看)
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.OpenReceipt === false && 
            <>
              <div>
                <div className="ClaimInput">
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Date}:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{moment(this.props.ClaimsData.UploadDateTime).format('DD-MM-YYYY')}</div> */}
                  <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={moment(this.props.ClaimsData.UploadDateTime).format('DD-MM-YYYY')} disabled={true}/>
                </div>
                <div className="ClaimInput">
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Users}:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.ClaimsData.UploadBy}</div> */}
                  <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.ClaimsData.Name} disabled={true}/>
                </div>
                <div className="ClaimInput">
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Company}:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.ClaimsData.Company}</div> */}
                  <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.ClaimsData.Company} disabled={true}/>
                </div>
                <div className="ClaimInput">
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Branch}:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.ClaimsData.Branch}</div> */}
                  <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.ClaimsData.Branch} disabled={true}/>
                </div>
                <div className="ClaimInput">
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Department}:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.ClaimsData.Department}</div> */}
                  <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.ClaimsData.Department} disabled={true}/>
                </div>
                <div className="ClaimInput">
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Subject}:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.ClaimsData.Category}</div> */}
                  <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.ClaimsData.Category} disabled={true}/>
                </div>
                <div className="ClaimInput">
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}1:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.ClaimsData.Sub1}</div> */}
                  <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.ClaimsData.Sub1} disabled={true}/>
                </div>
                <div className="ClaimInput">
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}2:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.ClaimsData.Sub2}</div> */}
                  <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.ClaimsData.Sub2} disabled={true}/>
                </div>
                <div className='ClaimInput'>
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Title}:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.ClaimsData.Subject}</div> */}
                  <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.ClaimsData.Subject} disabled={true}/>
                </div>
                <div className='ClaimInput'>
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Remark}:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white', wordBreak: 'break-word' }}>{this.props.ClaimsData.Comment}</div> */}
                  <textarea style={{ width: '60%', borderColor: 'lightgray', backgroundColor: 'white', fontSize: '12px', minHeight: 95}} value={this.props.ClaimsData.Comment} disabled={true}></textarea>
                </div>
                <div className='ClaimInput'>
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Amount}:</div>
                  {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.ClaimsData.Amount}</div> */}
                  <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px'}} value={this.props.ClaimsData.Amount} type="Number" disabled={true}/>
                </div>
                {this.state.ReturnClicked === true && 
            <>
              <div className='ClaimInput'>
                <div className='UploadClaimTitle'>{language[this.props.currentLanguage].ReturnReason}</div>
                <textarea style={{ width: '60%', backgroundColor: 'white' }} value={this.state.ReasonText} onChange={(e) => this.setState({ ReasonText: e.target.value })}></textarea>
              </div>
            </>
                }
                <form>
                  <div className="form-group multi-preview" style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}>
                    {(this.props.ClaimsData.ReceiptImgURL || []).map((url, ind) => (
                      // <div key={ind} style={{ display: 'flex', flexDirection: 'column'}}>
                      //   <img className="button2" style={{ width: 200, height: 200, padding: 1, cursor: 'pointer', borderRadius: 5 }} onClick={() => this.OpenSelectedReceipt(url.ReceiptImg, 'View')} src={url.ReceiptImg} alt="images" />
                      //   <Button className='button3' onClick={() => this.OpenSelectedReceipt(url.ReceiptImg, 'download')}>{language[this.props.currentLanguage].Download}</Button>
                      // </div>
                      <div key={ind} style={{ display: 'flex', flexDirection: 'column', width: '90%'}}>
                        <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                          <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '90%' }}>{url.ReceiptImg}</div>
                          <div className='dcc' onClick={() => this.OpenSelectedReceipt(url.ReceiptImg, 'View')} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
              <div class>
                {this.props.SelectedTypes.SelectedType === '待批' && this.props.SelectedTypes.SelectedTable === 'All' && 
                <>
                  <Button className='button3' style={{ marginRight: 5, width: '20%' }} onClick={() => this.ReturnReceipt('批准')}>{'批准'}</Button>
                  <Button className='button3' style={{ marginRight: 5, width: '20%', backgroundColor: 'red', borderColor: 'red' }} onClick={() => this.ReturnReceipt('驳回申请')}>{'驳回申请'}</Button>
                  <Button className="button3" style={{ marginRight: 5, width: '20%', backgroundColor: 'darkred', borderColor: 'darkred' }} onClick={() => this.ReturnReceipt('return')}>
                    {(this.state.ReturnClicked === true) ? `${language[this.props.currentLanguage].CancelReturnClaim}` : `${language[this.props.currentLanguage].ReturnClaim}`}
                  </Button>
                  {this.state.ReturnClicked === true && 
                  <Button className='button3' style={{ marginRight: 5, width: '20%', backgroundColor: 'green', borderColor: 'green' }} onClick={() => this.ReturnReceipt('ConfirmReturn')}>{language[this.props.currentLanguage].ConfirmReturnClaim}</Button>
                  }
                </>
                }
                {this.props.SelectedTypes.SelectedType === '已批' && this.props.SelectedTypes.SelectedTable === 'All' && this.props.ClaimsData.Status === 'SecondApprove' &&
                <>
                  <Button className='button3' style={{ marginRight: 5, width: '20%' }} onClick={() => this.ReturnReceipt('出纳')}>{'出纳'}</Button>
                  <Button className='button3' style={{ marginRight: 5, width: '20%', backgroundColor: 'red', borderColor: 'red' }} onClick={() => this.ReturnReceipt('驳回申请')}>{'驳回申请'}</Button>
                  <Button className="button3" style={{ marginRight: 5, width: '20%', backgroundColor: 'darkred', borderColor: 'darkred' }} onClick={() => this.ReturnReceipt('return')}>
                    {(this.state.ReturnClicked === true) ? `${language[this.props.currentLanguage].CancelReturnClaim}` : `${language[this.props.currentLanguage].ReturnClaim}`}
                  </Button>
                  {this.state.ReturnClicked === true && 
                  <Button className='button3' style={{ marginRight: 5, width: '20%', backgroundColor: 'green', borderColor: 'green' }} onClick={() => this.ReturnReceipt('ConfirmReturn')}>{language[this.props.currentLanguage].ConfirmReturnClaim}</Button>
                  }
                </>
                }
                {this.props.SelectedTypes.SelectedType === '尚未交付' && this.props.SelectedTypes.SelectedTable === 'All' && this.props.ClaimsData.Status === 'SecondApprove' &&
                <>
                  <Button className='button3' style={{ marginRight: 5, width: '20%' }} onClick={() => this.ReturnReceipt('出纳')}>{'出纳'}</Button>
                  <Button className='button3' style={{ marginRight: 5, width: '20%', backgroundColor: 'red', borderColor: 'red' }} onClick={() => this.ReturnReceipt('驳回申请')}>{'驳回申请'}</Button>
                  <Button className="button3" style={{ marginRight: 5, width: '20%', backgroundColor: 'darkred', borderColor: 'darkred' }} onClick={() => this.ReturnReceipt('return')}>
                    {(this.state.ReturnClicked === true) ? `${language[this.props.currentLanguage].CancelReturnClaim}` : `${language[this.props.currentLanguage].ReturnClaim}`}
                  </Button>
                  {this.state.ReturnClicked === true && 
                  <Button className='button3' style={{ marginRight: 5, width: '20%', backgroundColor: 'green', borderColor: 'green' }} onClick={() => this.ReturnReceipt('ConfirmReturn')}>{language[this.props.currentLanguage].ConfirmReturnClaim}</Button>
                  }
                </>
                }
                {this.props.SelectedTypes.SelectedType === '被退回' && 
              <Button className='button3' style={{ marginRight: 5, width: '30%' }} onClick={() => this.ReturnReceipt('撤回')}>{'撤回'}</Button>
                }
                {this.props.SelectedTypes.SelectedType === '已交付' && this.props.SelectedTypes.SelectedTable === 'All' && this.props.ClaimsData.Status === 'CashOut' &&
                <>
                  <Button className='button3' style={{ marginRight: 5, backgroundColor: 'gray', color: 'white', borderColor: 'gray', width: '25%' }} onClick={() => this.ReturnReceipt('下载单据')}>{'下载单据'}</Button>
                  <Button className='button3' style={{ marginRight: 5, backgroundColor: 'gray', color: 'white', borderColor: 'gray', width: '25%' }} onClick={() => this.ReturnReceipt('查看单据')}>{'查看单据'}</Button>
                  {/* <Button style={{ marginRight: 5, backgroundColor: 'gray', color: 'white', borderColor: 'gray', width: '30%' }} onClick={() => this.printWindow()}>{'Print'}</Button> */}
                </>
                }
              </div>
              <div style={{ marginTop: 5, width: '80%' }}>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>{language[this.props.currentLanguage].ActionsUser}</th>
                      <th>{language[this.props.currentLanguage].Date}</th>
                      <th>{language[this.props.currentLanguage].Remark}</th>
                      <th>{language[this.props.currentLanguage].Reason}</th>
                      <th>{language[this.props.currentLanguage].Amount}</th>
                      <th>{language[this.props.currentLanguage].Action}</th>
                    </tr>
                  </thead>
                  {this.props.HistoryData.length > 0 &&
                    <tbody>
                      {this.props.HistoryData.map((items, index) => {
                        return(
                          <tr key={index}>
                            <td>{items.ActionUser}</td>
                            <td>{moment(items.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td style={{ wordBreak: 'break-word' }}>{items.Comment}</td>
                            <td>{items.Reason}</td>
                            <td>{items.Amount}</td>
                            {/* <td>{items.Status}</td> */}
                            <td>{language[this.props.currentLanguage][items.Status]}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  }
                  {this.props.HistoryData.length === 0 &&
                    <tbody>
                      <tr>
                        <td colSpan="6" style={{ textAlign: 'center', fontWeight: 'bold' }}>No History Data</td>
                      </tr>
                    </tbody>
                  }
                </Table>
              </div>
            </>
            }

            {this.state.OpenReceipt === true && 
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                <div id="appear" className='dcc' style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                  <div style={{ fontWeight: 'bold', fontSize: '26px', marginBottom: 40 }}>{'Payment Receipt'}</div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Company Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.ClaimsData.Company}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Branch Name '}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.ClaimsData.Branch}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Category Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.ClaimsData.Category}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Department '}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.ClaimsData.Department}`}</div>
                  </div>
                  {this.props.ClaimsData.Sub1 !== null && 
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Sub Category Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.ClaimsData.Sub1}`}</div>
                  </div>
                  }
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Subject Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.ClaimsData.Subject}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Submitted Date'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${moment(this.props.ClaimsData.UploadDateTime).format('DD-MM-YYYY')}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Paid Date'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${moment(this.props.ClaimsData.ActionDateTime).format('DD-MM-YYYY')}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Remark'}</div>
                    <div style={{ width: '60%', fontSize: '17px', wordBreak: "break-word", fontWeight: '500'}}>{this.props.ClaimsData.Comment}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Submitted Amount'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`RM ${this.props.ClaimsData.Amount}`}</div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 65}}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Paid Amount'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`RM ${this.props.ClaimsData.ApproveAmount}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%', marginBottom: 80 }}>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'Paid By'}</div>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'Receive By'}</div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 5}}>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'_____________________________'}</div>
                    <div style={{ width: '50%', fontSize: '17px'}}>{'_____________________________'}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{`${this.props.ClaimsData.Accountant}`}</div>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{`${this.props.ClaimsData.Name}`}</div>
                  </div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                  <button type="button" className="CssButtonP ButtonH" style={{ marginTop: 10, marginRight: 5, width: '50%' }} onClick={() => this.printWindow()}>
                    {language[this.props.currentLanguage].Print}
                  </button>
                  <button type="button" className="CssButtonP ButtonH" style={{ marginTop: 10, marginRight: 5, width: '50%' }} onClick={() => this.ExportPDF()}>
                    {language[this.props.currentLanguage].DownloadReceipt}
                  </button>
                </div>
                {/* <button type="button" className="btn btn-secondary btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.ExportPDF()}>
                  {language[this.props.currentLanguage].DownloadReceipt}
                </button> */}
                <button type="button" className="CssButtonRed ButtonHRed" style={{ marginTop: 10, marginRight: 5, backgroundColor: 'red' }} onClick={() => this.OpenReceiptFunction()}>
                  {language[this.props.currentLanguage].Back}
                </button>
              </div>
            }

            {this.state.OpenReceipt !== true && 
              <Button variant="danger" onClick={this.props.CloseViewReceipt}>{language[this.props.currentLanguage].Close}</Button>
            }
          </Modal.Body>
        </>}
        {this.props.ConfirmPass === true && 
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {language[this.props.currentLanguage].ConfirmPassword}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="ClaimInput">
                <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Users}</div>
                <Form.Control style={{ width: '60%', textAlign: 'center', backgroundColor: 'white' }} value={this.props.ClaimsData.UploadBy} disabled={true}/>
              </div>
              <div className='ClaimInput'>
                <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Password}</div>
                <Form.Control onKeyPress={(e) => this.onKeyEnter(e)} style={{ width: '60%', textAlign: 'center', backgroundColor: 'white'}} value={this.state.Password} onChange={(e) => this.OnchangePassword(e)} type="text"/>
              </div>
            </div>
            <Button variant="success" onClick={() => this.ConfirmPasswordAPI(this.props.ClaimsData)}>{language[this.props.currentLanguage].CashOut}</Button>
            <Button variant="danger" style={{ marginLeft: 5 }} onClick={this.props.CloseConfirmPAss}>{language[this.props.currentLanguage].Back}</Button>
          </Modal.Body>
        </>}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewReceiptModal);
