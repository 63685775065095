import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import { language } from '../language';
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import React from 'react';
import { TimeZone } from '../Component/TimeZoneKL'
import { connect } from 'react-redux';
import moment from 'moment-timezone'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedExample: this.props.currentLanguage,
    }
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.runtime(),
      1000
    );
  }

  runtime() {
    this.setState({
      date: moment(this.state.date).add(1, 's').format('hh:mm:ss a YYYY-MM-DD')
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  setLanguage() {
    if(this.state.SelectedExample === 'cn') {
      this.setState({ SelectedExample: 'en' })
      this.props.setLanguage('en')
    } else {
      this.setState({ SelectedExample: 'cn' })
      this.props.setLanguage('cn')
    }
  }

  LogOutFucntion() {
    const r = (window.confirm(`${language[this.props.currentLanguage].LogoutConfirm}`));
    if (r === true) {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      window.location.href = '/';
    } else {
      return null;
    }
  }

  render() {
    return (
      <div>
        {this.props.username && <Navbar sticky="top" expand="lg" variant="dark" className='HeaderBold' style={{ backgroundColor: '#FE6C33'}}>
          <Container fluid>
            {this.props.role === 'Specialist' ?
              <Navbar.Brand href="/User">{language[this.props.currentLanguage].Users}</Navbar.Brand>
              : this.props.role === 'Account' ? 
                <Navbar.Brand href="/Claim">{language[this.props.currentLanguage].Claim}</Navbar.Brand>
                :
                <Navbar.Brand 
                  className='non-printable' href="/main">{language[this.props.currentLanguage].HomePage}</Navbar.Brand>
            }
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                {this.props.role === 'Specialist' && 
                <>
                  <Nav.Link href="/company">{language[this.props.currentLanguage].AddDepartment}</Nav.Link>
                  <Nav.Link href="/category">{language[this.props.currentLanguage].AddSubject}</Nav.Link>
                </>
                }
                {this.props.role === 'CEO' && 
                <>
                  <Nav.Link href="/SummaryList">{language[this.props.currentLanguage].SummaryList}</Nav.Link>
                  <Nav.Link href="/Claim" >
                    {language[this.props.currentLanguage].Claim}
                  </Nav.Link>
                  <Nav.Link href="/Approvel">{language[this.props.currentLanguage].Approve}</Nav.Link>
                  <Nav.Link href="/CashOut">{language[this.props.currentLanguage].CashOut}</Nav.Link>
                  <Nav.Link href="/Report">{language[this.props.currentLanguage].Report}</Nav.Link>
                  <Nav.Link href="/changePassword">{language[this.props.currentLanguage].ChangePassword}</Nav.Link>
                </>
                }
                {this.props.role === 'User' && 
                <>
                  <Nav.Link href="/Claim">{language[this.props.currentLanguage].Claim}</Nav.Link>
                  <Nav.Link href="/Report">{language[this.props.currentLanguage].Report}</Nav.Link>
                  <Nav.Link href="/changePassword">{language[this.props.currentLanguage].ChangePassword}</Nav.Link>
                </>
                }
                {this.props.role === 'Account' && 
                <>
                  {/* <Nav.Link href="/Claim">{language[this.props.currentLanguage].Claim}</Nav.Link> */}
                  {/* <Nav.Link href="/Approvel">{language[this.props.currentLanguage].Approve}</Nav.Link> */}
                  <Nav.Link href="/CashOut">{language[this.props.currentLanguage].CashOut}</Nav.Link>
                  <Nav.Link href="/Report">{language[this.props.currentLanguage].Report}</Nav.Link>
                  <Nav.Link href="/changePassword">{language[this.props.currentLanguage].ChangePassword}</Nav.Link>
                </>
                }
              </Nav>
              <Nav>
                <Nav.Link href="#" disabled style={{color: 'white'}}>
                  {/* <Form.Control as="select" value={this.state.SelectedExample} onChange={(e) => this.props.setLanguage(e.target.value)}>
                    <option value="cn" selected={this.props.currentLanguage === 'cn'}>{'中文'}</option>
                    <option value="en" selected={this.props.currentLanguage === 'en'}>{'英文'}</option>
                  </Form.Control> */}
                </Nav.Link>
                <div className='dcc' style={{ marginRight: 5 }}>{TimeZone()}</div>
                <Button variant="success" style={{fontWeight: 'bold'}} value={this.state.SelectedExample} onClick={() => this.setLanguage()}>{language[this.props.currentLanguage][this.state.SelectedExample]}</Button>
              </Nav>
              <Nav>
                <Nav.Link href="#" disabled style={{color: 'black', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Login}：{this.props.username} ({this.props.role})</Nav.Link>
                <Button style={{fontWeight: 'bold'}}  variant="danger" onClick={() => { this.LogOutFucntion() }}>{language[this.props.currentLanguage].LogOut}</Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);
