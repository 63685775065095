import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import Login from './Page/Login';
import Main from './Page/Main';
import Company from './Page/Company';
import Category from './Page/Category';
import User from './Page/User';
import ChangePassword from './Page/ChangePassword';
import Report from './Page/Report';
import Claim from './Page/Claim';
import Approvel from './Page/Approvel';
import CashOut from './Page/CashOut';
import 'bootstrap/dist/css/bootstrap.min.css';
import Reducers from './reducers/reducer';
import SummaryList from './Page/SummaryList';
// import DetailsSummary from './Page/DetailsSummary';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import Header from './Component/Header';

function App() {
  const middlewares = applyMiddleware(thunk);

  const store = createStore(
    Reducers, {
      username: localStorage.getItem('claimusername') || '',
      role: localStorage.getItem('claimrole') || '',
      token: localStorage.getItem('claimtoken') || '',
      CompanyData: localStorage.getItem('CompanyData') || '',
      currentLanguage: localStorage.getItem('currentLanguage') || 'cn',
    },
    middlewares,
  )
  return (
    <Provider store={store}>
      <BrowserRouter style={{ position: 'relative' }}>
        {/* <Header/> */}
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/Header" component={Header}/>
          <Route exact path="/main" component={Main} />
          <Route exact path="/company" component={Company} />
          <Route exact path="/SummaryList" component={SummaryList} />
          {/* <Route exact path="/DetailsSummary" component={DetailsSummary} /> */}
          <Route exact path="/category" component={Category} />
          <Route exact path="/User" component={User} />
          <Route exact path="/ChangePassword" component={ChangePassword} />
          <Route exact path="/Report" component={Report} />
          <Route exact path="/Approvel" component={Approvel} />
          <Route exact path="/CashOut" component={CashOut} />
          <Route exact path="/Claim" component={Claim} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}
export default App;
