import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import { Container, Button, Form } from 'react-bootstrap';
import moment from 'moment-timezone';

class MainPageDetails extends React.Component {

  fileObj = [];
  fileArray = [];
  Base64 = []
  constructor(props) {
    super(props);
    this.state = {
      SelectedActions: [],
      DisplayCheck: false,
      SelectedType: '',
      ErrorMessage: false,
      Message: '',
      MessageType: '',
    }
  }

  TotalAmount(ClaimData) {
    let TotalAmount = {
      Amount: 0,
    }
    ClaimData.forEach((items) => {
      TotalAmount.Amount += Number(items.Amount)
    })
    return TotalAmount.Amount
  }

  SelectedActions(SelectedIDs) {
    let SelectedActions = JSON.parse(JSON.stringify(this.state.SelectedActions))
    if(SelectedActions.includes(SelectedIDs)) {
      SelectedActions = SelectedActions.filter(function (el) {
        return el !== SelectedIDs
      });
    } else {
      SelectedActions.push(SelectedIDs)
    }
    this.setState({ SelectedActions })
  }

  SelectedActionsAll(e) {
    let ArrayID = []
    const UnClaimData = this.props.UnClaimData
    if (e.target.checked) {
      UnClaimData.forEach((items) => {
        ArrayID.push(items.ID)
      })
    } else {
      ArrayID = []
    }
    this.setState({ SelectedActions: ArrayID })
  }

  render() {
    return (
      <Container fluid style={{ marginBottom: 10, fontSize: '13px' }}>
        <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
          <div className='MainPageDateContainer'>
            <div className='dcc' style={{ display: 'flex', flexDirection: 'row'  }}>
              <div style={{ marginRight: 10, fontWeight: 'bold'}}>{`${language[this.props.currentLanguage].SummaryDetails}: *${this.props.SelectedValue.Company}* / ${language[this.props.currentLanguage].SelectedType}: *${(this.props.SelectedValue.Type)}*`}</div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginRight: 10, fontWeight: 'bold', fontSize: '20px', cursor: 'pointer'}} onClick={this.props.handleClose}>{`X`}</div></div>
          </div>
        </div>
        <div style={{display: 'flex', textAlign: 'right', justifyContent: 'end', width: '80%' }}>
          {/* {(this.props.SelectedValue.Type === '已批' || this.props.SelectedValue.Type === '尚未交付') && 
                <>
                  {this.state.SelectedType === '出纳' && this.state.SelectedActions.length > 0 && 
                  <button className='ButtonDesign' style={{ padding: 3 }}
                    onClick={() => this.props.RunApi('出纳', this.state.SelectedActions)}>{'出纳'}</button>
                  }
                  {this.state.SelectedType === '驳回' && this.state.SelectedActions.length > 0 && 
                  <button className='ButtonDesign' style={{ padding: 3 }}
                    onClick={() => this.props.RunApi('驳回', this.state.SelectedActions)}>{'驳回'}</button>
                  }
                  <button className='ButtonDesign' style={{ padding: 3 }}
                    onClick={() => this.setState({ DisplayCheck: !this.state.DisplayCheck, SelectedType: (this.state.DisplayCheck === false) ? '出纳' : '', SelectedActions: (this.state.DisplayCheck === false) ? this.state.SelectedActions : []})}
                    variant="warning">{'快速出纳'}</button>
                  <button className='ButtonDesign' style={{ padding: 3 }}
                    onClick={() => this.setState({ DisplayCheck: !this.state.DisplayCheck, SelectedType: (this.state.DisplayCheck === false) ? '驳回' : '', SelectedActions: (this.state.DisplayCheck === false) ? this.state.SelectedActions : [] })}
                    variant="warning">{'快速驳回'}</button>
                </>
            } */}
          <button className='ButtonDesign MainButtonShortlong' style={{ padding: 5, marginBottom: 5, backgroundColor: this.props.BorderHide ? "#198754" :"#5c636a" }}
            onClick={() => this.props.SetStateProps('BorderHide')}
          >{this.props.BorderHide ? language[this.props.currentLanguage].Expand : language[this.props.currentLanguage].Minimize}</button>
          <button className='ButtonDesign MainButton' style={{ padding: 5, marginBottom: 5 }}
            onClick={() => this.props.exportFile()}
          >{'XLSX'}</button>
          {(this.props.SelectedValue.Type === '待批') && 
                <>
                  {this.state.SelectedType === '批准' && this.state.SelectedActions.length > 0 && 
                  <button className='ButtonDesign MainButton' style={{ padding: 3 }}
                    onClick={() => this.props.RunApi('批准', this.state.SelectedActions, this.props.SelectedValue.Company)}>{'批准'}</button>
                  }
                  {/* {this.state.SelectedType === '驳回' && this.state.SelectedActions.length > 0 && 
                  <button className='ButtonDesign' style={{ padding: 3 }}
                    onClick={() => this.props.RunApi('驳回', this.state.SelectedActions)}>{'驳回'}</button>
                  } */}
                  <button className='ButtonDesign MainButton' style={{ padding: 5, marginBottom: 5 }}
                    onClick={() => this.setState({ DisplayCheck: !this.state.DisplayCheck, SelectedType: (this.state.DisplayCheck === false) ? '批准' : '', SelectedActions: (this.state.DisplayCheck === false) ? this.state.SelectedActions : []})}
                    variant="warning">{'快速批准'}</button>
                  {/* <button className='ButtonDesign' style={{ padding: 3 }}
                    onClick={() => this.setState({ DisplayCheck: !this.state.DisplayCheck, SelectedType: (this.state.DisplayCheck === false) ? '驳回' : '', SelectedActions: (this.state.DisplayCheck === false) ? this.state.SelectedActions : [] })}
                    variant="warning">{'快速驳回'}</button> */}
                </>
          }
        </div>
        <div className="dcc" style={{ width: '80%', marginBottom: 50 }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
            <div  style={{ flex: "1", border: "1px solid black", padding: 5}}>
              <Fragment>
                <div>
                  <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                    <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: '#4A5874', padding: 5 }}>
                      <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        {/* <div>{language[this.props.currentLanguage].Date}</div> */}
                        <select style={{ width: 'auto', maxWidth: 80 }} value={this.props.ShowFilterDate} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilterDate')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Date}`}</option>
                          {this.props.DetailsFilterList.DisplayDate.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                      <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        {/* <div>{language[this.props.currentLanguage].Name}</div> */}
                        <select style={{ width: 'auto', maxWidth: 80}} value={this.props.ShowFilterName} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilterName')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Name}`}</option>
                          {this.props.DetailsFilterList.NameFilter.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                      <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        {/* <div>{language[this.props.currentLanguage].Branch}</div> */}
                        <select style={{ width: 'auto', maxWidth: 80}} value={this.props.ShowFilterBranch} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilterBranch')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Branch}`}</option>
                          {this.props.DetailsFilterList.BranchFilter.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                      <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        {/* <div>{language[this.props.currentLanguage].Department} </div> */}
                        <select style={{ width: 'auto', maxWidth: 80}} value={this.props.ShowFilterDepart} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilterDepart')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Department}`}</option>
                          {this.props.DetailsFilterList.DepartFilter.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                      <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        {/* <div>{language[this.props.currentLanguage].Subject} </div> */}
                        <select style={{ width: 'auto', maxWidth: 80}} value={this.props.ShowFilterSubject} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilterSubject')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Subject}`}</option>
                          {this.props.DetailsFilterList.SubjectFilter.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                      <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        {language[this.props.currentLanguage].Amount}
                      </div>
                      <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        {/* <div>{language[this.props.currentLanguage].Approver}  </div> */}
                        <select style={{ width: 'auto', maxWidth: 80}} value={this.props.ShowFilterApprove} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilterApprove')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Approver}`}</option>
                          {this.props.DetailsFilterList.ApproveFilter.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                      <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        {/* <div>{language[this.props.currentLanguage].ApproverStatus}  </div> */}
                        <select style={{ width: 'auto', maxWidth: 80}} value={this.props.ShowFilterStatus} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilterStatus')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].ApproverStatus}`}</option>
                          {this.props.DetailsFilterList.StatusFilter.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                      {/* <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        <div>{language[this.props.currentLanguage].SecondApprover} </div>
                        <select style={{ width: 'auto', maxWidth: 80}} value={this.props.ShowFilter2ndApprove} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilter2ndApprove')}>
                          <option value='All'>{language[this.props.currentLanguage].All}</option>
                          {this.props.DetailsFilterList.SecondApproveFilter.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div> */}
                      {this.props.SelectedValue.Type === '已交付' &&
                      <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        {/* <div>{language[this.props.currentLanguage].Accounting} </div> */}
                        <select style={{ width: 'auto', maxWidth: 80}} value={this.props.ShowFilterAccount} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilterAccount')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Accounting}`}</option>
                          {this.props.DetailsFilterList.AccountFilter.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                      }
                      <div className={this.props.BorderHide ? "resulttableTitle BorderHideTrue" : "resulttableTitle BorderHideFalse"}
                        onClick={() => this.props.SetStateProps('BorderHide')}
                        style={{width:'calc(100%)', color: 'white'}}>
                        <div>{language[this.props.currentLanguage].Remark} </div>
                      </div>
                      <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        <div className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ marginRight: 5 }}>{language[this.props.currentLanguage].Action}</div>
                          {this.state.DisplayCheck && 
                          <Form.Check
                            className='CheckBoxDesign'
                            type="checkbox"
                            onChange={(e) => this.SelectedActionsAll(e)}
                          />
                          }
                        </div>
                        {/* {(this.props.SelectedValue.Type === '待批') && 
                <>
                  {this.state.SelectedType === '批准' && this.state.SelectedActions.length > 0 && 
                  <button className='ButtonDesign' style={{ padding: 3 }}
                    onClick={() => this.props.RunApi('批准', this.state.SelectedActions)}>{'批准'}</button>
                  }
                  <button className='ButtonDesign' style={{ padding: 3 }}
                    onClick={() => this.setState({ DisplayCheck: !this.state.DisplayCheck, SelectedType: (this.state.DisplayCheck === false) ? '批准' : '', SelectedActions: (this.state.DisplayCheck === false) ? this.state.SelectedActions : []})}
                    variant="warning">{'快速批准'}</button>
                </>
                        } */}
                      </div>
                      {/* <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                        {language[this.props.currentLanguage].Action}
                      </div> */}
                    </div>
                  </div>
                  {this.props.UnClaimData.length > 0 && 
                  <>
                    {this.props.UnClaimData.map((items, index) => {
                      return(
                        <div className='dcc' key={index} style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: (index%2) ? '#F1F1F1' : 'white' }}>
                          <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                            <div className="resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {moment(items.UploadDateTime).format('DD-MM-YYYY')}
                            </div>
                            <div className="resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {items.Name}
                            </div>
                            <div className="resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {items.Branch}
                            </div>
                            <div className="resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {items.Department}
                            </div>
                            <div className="resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {items.Category}
                            </div>
                            <div className="resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {Number(items.Amount).toLocaleString()}
                            </div>
                            <div className="resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {items.FirstApprove}
                            </div>
                            <div className="resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: (items.Status === 'CashOut') ? 'Green' : (items.Status === 'Reject' || items.Status === 'Return') ? 'red' : (items.Status === 'Pending') ? 'blue' : 'black' }}>
                              {items.Status}
                            </div>
                            {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {items.SecondApprove}
                            </div> */}
                            {this.props.SelectedValue.Type === '已交付' && 
                            <div  className="resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {items.Accountant}
                            </div>
                            }
                            <div  className="resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              { this.props.BorderHide ? items.Comment : (items.Comment.length > 20 ? `${items.Comment.substring(0, 20)}........` : items.Comment)}
                            </div>
                            <div className='dcc' style={{ flexDirection: 'row', display: 'flex', width:'calc(100%)', padding: 1 }}>
                              <button className='ButtonDesign MainButton' style={{ padding: 3, marginRight: 5 }} onClick={() => this.props.WatchReceipt(items)} variant="warning">{language[this.props.currentLanguage].View}</button>
                              {this.state.DisplayCheck && 
                               <Form.Check
                                 className='CheckBoxDesign'
                                 type="checkbox"
                                 checked={this.state.SelectedActions.includes(items.ID)}
                                 onChange={(e) => this.SelectedActions(items.ID)}
                               />
                              }
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className='dcc' style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: '#4A5874' }}>
                      <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', fontSize: '13px', fontWeight: 'bold' }}>
                          {language[this.props.currentLanguage].Total}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', fontSize: '13px', fontWeight: 'bold' }}>
                          {this.TotalAmount(this.props.UnClaimData).toLocaleString()}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)'}}>
                          {''}
                        </div>
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)'}}>
                          {''}
                        </div> */}
                        {this.props.SelectedValue.Type === '已交付' && 
                        <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        }
                        <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                          {''}
                        </div>
                        <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', padding: 1 }}>
                          {''}
                        </div>
                      </div>
                    </div>
                  </>
                  }
                  {this.props.UnClaimData.length === 0 &&
                  <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                    <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                      {language[this.props.currentLanguage].NoClaimData}
                    </div>
                  </div>
                  }
                </div>
              </Fragment> 
            </div> 
          </div>
        </div>
        <Button variant="danger" onClick={this.props.handleClose}>{language[this.props.currentLanguage].Close}</Button>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPageDetails);
