const language = {
  en: {
    daysOfWeek: [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ],
    SelectedCashoutError: 'Error: There are unfinished Claims in the selected Claims',
    MultiReceipt: 'Multi Receipt',
    HomePage: 'Home',
    WholeYear: 'Whole Year',
    Month: 'Month :',
    Year: 'Year :',
    '01': 'Jan',
    '02': 'Feb',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dev',
    Print: 'Print',
    AlertPrint: 'Do you sure want to print ?',
    cn: 'CHINESE',
    en: 'ENGLISH',
    Company: 'Company',
    Total: 'Total',
    Return: 'Returned',
    Returned: 'Returned',
    Rejected: 'Rejected',
    Cashouted: 'CashOuted',
    Pending: 'Pending',
    Approved: 'Approved',
    TotalAmount: 'Total Amount',
    Date: 'Date',
    Name: 'Name',
    Branch: 'Branch',
    All: 'All',
    NotAllowMultipleUserClaim: 'Error Not Allow To select Multiple Users Claims',
    PdfToomanyPage: 'Error: PDF exeed maximum size',
    Department: 'Department',
    Amount: 'Amount',
    Approver: 'Approver',
    FirstApproveStartus: 'Approval Status',
    LogoutConfirm: 'Are you Sure want to Logout ?',
    ApproverStatus: 'Approver Status',
    FirstApprove: '1st Approved',
    SecondApprove: '2nd Approved',
    SecondApprover: '2nd Approver',
    SecondApproveStatus: '2nd Approval Status',
    LatestStatus: 'Latest Status',
    ApprovedAmount: 'Approvd Amount',
    Action: 'Action',
    View: 'Views',
    NoClaimData: 'No Claim Datas',
    Close: 'Close',
    AddDepart: 'Add Department',
    Users: 'Users',
    Report: 'Report',
    ChangePassword: 'Change Password',
    Login: 'Login',
    LogOut: 'LogOut',
    EditedDate: 'Edit Date',
    ReClaim: 'ReClaim',
    FastDelete: 'Multi Delete',
    StopFastDelete: 'Cancel Multi Delete',
    Delete: 'Delete',
    UploadClaim: 'Add Claim',
    Upload: 'Upload',
    Edit: 'Edit',
    Approve: 'Approval',
    CashOut: 'CashOut',
    Receipt: 'Receipt',
    ViewReceipt: 'View Receipt',
    loading: 'Loading......',
    DownloadReceipt: 'Download Receipt',
    NoData: 'No Data',
    FastSecondApprove: 'Multi 2nd Approval',
    FastApprove: 'Multi Approval',
    CancelFastSecondApprove: 'Cancel Multi 2nd Approval',
    CancelFastApprove: 'Cancel Multi Approval',
    overrule: 'OverRule',
    FastReject: 'Multi Reject',
    Reject: 'Reject', 
    CancelFastReject: 'Cancel Multi Reject',
    AllUserClaimummary: 'All Users Claims Summary',
    SelfClaimSummary: 'Mine Claims Summary',
    NotCashOut: 'haven\'t CashOut',
    SuccessReclaim: 'Success: Successful Uploaded ReClaim',
    ConfirmReClaimMessage: 'Are You Sure Want to ReClaim this Record ?',
    Fails: 'Fail',
    CompanyErrorMEssage: 'Error: Please Select Company',
    BranchErrorMessage: 'Error: Please Select Branch',
    DepartmentErrorMessage: 'Error: Please Select Department', 
    SummaryList: 'SummaryList',
    AmountErrorMessage: 'Error: Please Keyin Amount',
    ClaimNotAllowDelete: 'Error: Selected Claim Cannot be Delete',
    DeleteConfirmation: 'Are You Sure You want to delete ?',
    SuccessDeleted: 'Success: Successful deleted',
    SuccessDeletedSelected: 'Success: Successful deleted Selected Claim',
    Title: 'Title',
    ActionsUser: 'Action Users',
    Remark: 'Remark',
    Download: 'Download',
    NotAllowEditError: 'Error: Selected Claim not allow to Edit',
    SuccessEdit: 'Success: Successful Edit',
    SuccessUploadClainm: 'success: Successw Upload Claim',
    Subject: 'Subject',
    DateFrom: 'Date(From)',
    DateTo: 'Date(to)',
    Search: 'Search',
    SubSubject: 'Sub Subject',
    Accounting: 'Accounting',
    DiplayActionHistory: 'Display Action History',
    HideActionHistory: 'Hide Action History',
    AddDepartment: 'Add Department',
    AddSubject: 'Add Subject',
    UpdateClaim: 'Update Claim',
    Back: 'Back',
    Password: 'Password',
    ConfirmPassword: 'Confirm Password',
    SecondApproveRequest: '2nd Approval',
    ApproveClaim: 'Approve Claim',
    RejectClaim: 'Reject Claim',
    ConfirmReturnClaim: 'Confirm Return Claim',
    CancelReturnClaim: 'Cancel Return Claim',
    ReturnClaim: 'return Claim',
    ReturnReason: 'return Reason',
    Redo: 'Redo Receipt',
    Reason: 'Reason',
    ClaimDate: 'Claim Date',
    CashOutClaim: 'CashOut Claim',
    SuccessruleOver: 'Success: Success ruleover Claim',
    DatabaseError: 'Error: Database Error',
    ConfirmOverrule: 'Are you Sure to OverRule Claim ?',
    SuccessReturn: 'Success: Success Return Claim',
    ErrorReason: 'Error: Please Keyin Reason for Return',
    SuccessCashOut: 'Success: Success CashOut',
    ErrorPassword: 'Error: Wrong Password',
    ErrorCannotCashOut: 'Error: Cannot CashOut',
    DontHaveAutho: 'Error: You Dont have Authority for this Functions',
    ErrorEmptyPass: 'Error: Please Keyin Password',
    ConfirmCashOut: 'Are You Confirm CashOut Selected Claim ?',
    SuccessReject: 'Success: Successful to Reject Claim',
    ConfirmReject: 'Are you sure to Reject Selected Claim ?',
    ErrorSelectSubject: 'Error: Please Select Subject',
    Add: 'Add',
    Confirm: 'Confirm',
    Copy: 'Copy',
    NewPassword: 'New Password',
    RepeatNewPAss: 'Repeat New Password',
    InsertNewPass: 'Please insert New Password',
    insertconfirmPass: 'Please Confirm New Password',
    ErrorNoRecod: 'Error: No Record',
    submit: 'submit',
    Username: 'Username',
    Role: 'Role',
    Active: 'Active',
    Quota: 'Quota',
    ConfirmDeleteUser: 'Confirm Delete Selected USer ?',
    CannotDeleteUser: 'Error: Cannot Delete User Due to user have Claim in 30 days',
    DuplicateUsername: 'Error: Username Already been used',
    Duplicatename: 'Error: Name Already been used',
    SuccessAddUser: 'Success: Successful add user',
    SuccessEditUser: 'Success: Success Edited User Details',
    Status: 'Status',
    OverruleReturn: 'Overrule Return Claim',
    SummaryDetails: 'Summary Details / Selected Company',
    SelectedType: 'Selected Type',
    Claim: 'Claim',
    ErrorEmptySelected: 'Error: Cannot Empty',
    ErrorSelectCompanyandBranch: 'Error: Please Select Company and Branch',
    ErrorSelectCompany: 'Error: Please Select Company',
    ErrorExistedDepartment: 'Error: Existed Department',
    ErrorExistedCategory: 'Error: Existed Category',
    SuccessAddCompany: 'Success: Successful add Data',
    SuccessAddCategory: 'Success: Successfully add Categorys',
    Line: 'Line',
    ErrorSub1EmptyDe: 'Error: please select Department and Category',
    ErrorSub2EmptyDe: 'Error: please select Department, Category and Sub1',
    ErrorSub3EmptyDe: 'Error: please select Department, Category, Sub1 and Sub2',
    ErrorSub4EmptyDe: 'Error: please select Department, Category, Sub1, Sub2 and Sub3',
    ErrorSub5EmptyDe: 'Error: please select Department, Category, Sub1, Sub2, Sub3 and Sub4',
    ErrorSub6EmptyDe: 'Error: please select Department, Category, Sub1, Sub2, Sub3, Sub4 and Sub5',
    ErrorSelectDataError: 'Error: Select data is abnormal',
    EmptyUsername: 'Empty Username',
    EmptyName: 'Empty Name',
    EmptyPassword: 'Empty Password',
    Error: 'Error',
    ServiceItems: 'Service Items',
    WrongCapcha: 'Wrong Capcha',
    Expand: 'Expand',
    Minimize: 'Minimize',
  },
  cn: {
    daysOfWeek: [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六',
    ],
    SelectedCashoutError: '错误: 选择单据内存在尚未交付单据',
    MultiReceipt: '多重单据',
    HomePage: '主页',
    WholeYear: '全年',
    Month: '月份 :',
    Year: '年份 :',
    '01': '1月',
    '02': '2月',
    '03': '3月',
    '04': '4月',
    '05': '5月',
    '06': '6月',
    '07': '7月',
    '08': '8月',
    '09': '9月',
    '10': '10月',
    '11': '11月',
    '12': '12月',
    Print: '打印',
    AlertPrint: '你确定要打印吗 ?',
    cn: '华语',
    en: '英语',
    Company: '公司',
    Total: '总共',
    Returned: '被退回',
    Return: '被退回',
    Rejected: '被拒绝',
    Cashouted: '已交付',
    Pending: '待批',
    Approved: '已批',
    TotalAmount: '总数',
    Date: '日期',
    Name: '名字',
    Branch: '分行',
    All: '全部',
    Department: '部门',
    NotAllowMultipleUserClaim: '错误: 不允许选择多个用户的申请',
    PdfToomanyPage: '错误: PDF以超出限制大小',
    Amount: '金额',
    Approver: '审批',
    FirstApproveStartus: '审批状态',
    LogoutConfirm: '确定要退出吗?',
    ApproverStatus: '审批状态',
    SecondApprove: '第二审批',
    SecondApprover: '第二审批',
    FirstApprove: '第一审批',
    SecondApproveStatus: '第二审批状态',
    LatestStatus: '最新进展',
    ApprovedAmount: '审批金额',
    Action: '行动',
    View: '查看',
    NoClaimData: '没有申请数据',
    Close: '关闭',
    AddDepart: '增加部门',
    Users: '用户',
    Report: '报告',
    ChangePassword: '更换密码',
    Login: '登入',
    LogOut: '登出',
    EditedDate: '更改日期',
    ReClaim: '重新申请',
    FastDelete: '快速删除',
    StopFastDelete: '取消快速删除',
    Delete: '删除',
    UploadClaim: '申请Claim',
    Upload: '上传',
    Edit: '更改',
    Approve: '批准',
    CashOut: '出纳',
    Receipt: '单据',
    ViewReceipt: '查看单据',
    loading: '加载中......',
    DownloadReceipt: '下载单据',
    NoData: '没数据',
    FastSecondApprove: '快速第二批准',
    FastApprove: '快速批准',
    CancelFastSecondApprove: '取消快速第二批准',
    CancelFastApprove: '取消快速批准',
    overrule: '撤回',
    FastReject: '快速驳回',
    Reject: '驳回', 
    CancelFastReject: '取消快速驳回',
    AllUserClaimummary: '所有用户申请概括',
    SelfClaimSummary: '自己申请概括',
    NotCashOut: '尚未交付',
    SuccessReclaim: '成功: ReClaim已经成功上传',
    ConfirmReClaimMessage: '确定要ReClaim记录吗?',
    Fails: '失败',
    CompanyErrorMEssage: '错误: 请选择公司',
    BranchErrorMessage: '错误: 请选择分行',
    DepartmentErrorMessage: '错误: 请选择部门',
    SummaryList: '汇总表',
    AmountErrorMessage: '错误: 请输入数目',
    ClaimNotAllowDelete: '失误: 选择申请不允许删除',
    DeleteConfirmation: '确定要删除记录吗 ?',
    SuccessDeleted: '成功: 成功删除',
    SuccessDeletedSelected: '成功: 成功删除选择用户',
    Title: '标题',
    ActionsUser: '行动用户',
    Remark: '备注',
    Download: '下载',
    NotAllowEditError: '失误: 选择申请不允许更改',
    SuccessEdit: '成功: 成功更改',
    SuccessUploadClainm: '成功: 申请提交成功',
    Subject: '项目',
    DateFrom: '日期(从)',
    DateTo: '日期(到)',
    Search: '搜寻',
    SubSubject: '副项目',
    Accounting: '会计',
    DiplayActionHistory: '显示行动历史',
    HideActionHistory: '隐藏行动历史',
    AddDepartment: '增加部门',
    AddSubject: '增加项目',
    UpdateClaim: '更新 Claim',
    Back: '返回',
    Password: '密码',
    ConfirmPassword: '确定密码',
    SecondApproveRequest: '第二批准申请',
    ApproveClaim: '批准申请',
    RejectClaim: '驳回申请',
    ReturnClaim: 'return申请',
    ConfirmReturnClaim: '确定return申请',
    CancelReturnClaim: '取消return申请',
    ReturnReason: 'return原因',
    Redo: '撤回',
    Reason: '原因',
    ClaimDate: '申请日期',
    CashOutClaim: '出纳申请',
    SuccessruleOver: '成功: 成功Redo/撤回申请',
    DatabaseError: '失误: 数据库存在问题',
    ConfirmOverrule: '确定要redo/撤回选择申请吗 ?',
    SuccessReturn: '成功: 成功Return申请',
    ErrorReason: '错误: 请输入原因',
    SuccessCashOut: '成功: 成功出纳申请',
    ErrorPassword: '失误: 密码错误',
    ErrorCannotCashOut: '失误: 不可出纳',
    DontHaveAutho: '失误: 您没有此功能的权限',
    ErrorEmptyPass: '失误: 请输入密码',
    ConfirmCashOut: '确定要出纳选择申请吗？',
    SuccessReject: '成功: 成功驳回申请',
    ConfirmReject: '确定要驳回选择申请吗 ?',
    ErrorSelectSubject: '错误: 请选择项目',
    Add: '增加',
    Confirm: '确定',
    Copy: '拷贝',
    NewPassword: '新密码',
    RepeatNewPAss: '重复新密码',
    InsertNewPass: '请输入新密码',
    insertconfirmPass: '请输入重复新密码',
    ConfirmDeleteUser: '确定要删除选择用户',
    ErrorNoRecod: '错误: 没有记录',
    submit: '提交',
    Username: '用户名',
    Role: '角色',
    Active: '活跃',
    Quota: '批准配额',
    CannotDeleteUser: '错误: 用户在30天内存在申请',
    DuplicateUsername: '错误: 用户用户名重叠',
    Duplicatename: '错误: 用户名重叠',
    SuccessAddUser: '成功: 成功增加用户',
    SuccessAddCompany: '成功: 成功增加公司',
    SuccessEditUser: '成功: 成功更新用户资料',
    Status: '状态',
    OverruleReturn: '撤回return申请',
    SummaryDetails: '概括细节 / 选择公司',
    SelectedType: '选择类型',
    Claim: '申请',
    ErrorEmptySelected: '错误: 不可放空',
    ErrorSelectCompanyandBranch: '错误: 请选择公司与分行',
    ErrorSelectCompany: '错误: 请选择公司',
    ErrorExistedDepartment: '错误: 該部門已存在',
    ErrorExistedCategory: '错误: 该项目已存在',
    Line: '排',
    SuccessAddCategory: '成功: 成功添加项目',
    ErrorSub1EmptyDe: '错误: 请选择部门, 项目',
    ErrorSub2EmptyDe: '错误: 请选择部门, 项目与副项目1',
    ErrorSub3EmptyDe: '错误: 请选择部门, 项目, 副项目1与副项目2',
    ErrorSub4EmptyDe: '错误: 请选择部门, 项目, 副项目1, 副项目2与副项目3',
    ErrorSub5EmptyDe: '错误: 请选择部门, 项目, 副项目1, 副项目2, 副项目3与副项目4',
    ErrorSub6EmptyDe: '错误: 请选择部门, 项目, 副项目1, 副项目2, 副项目3, 副项目4与副项目5',
    ErrorSelectDataError: '错误: 选择数据出现异常',
    EmptyUsername: '空用户名',
    EmptyName: '空名字',
    EmptyPassword: '空密码',
    Error: '错误',
    ServiceItems: '服务项目',
    WrongCapcha: '错误Capcha',
    Expand: '扩张',
    Minimize: '最少化',
  },
}
  
exports.language = language
  