import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import { getBase64 } from '../Component/getBase64';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import { getCompany, getCategory, EditClaim } from '../Api';
import moment from 'moment-timezone';
var timer

class MainEditModal extends React.Component {
  fileObj = [];
  fileArray = [];
  newfileArray = []
  Base64 = []
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment().format('YYYY-MM-DD'),
      CompanyArray: [],
      BranchArray: [],
      CategoriesArray: [],
      sub1Array: [],
      sub2Array: [],
      sub3Array: [],
      sub4Array: [],
      sub5Array: [],
      sub6Array: [],
      DepartmentArray: [],
      ClaimTitle: this.props.ClaimsData.Subject,
      Remark: this.props.ClaimsData.Comment,
      AmountClaim: this.props.ClaimsData.Amount,
      Company: this.props.ClaimsData.Company,
      Branch: this.props.ClaimsData.Branch,
      Department: this.props.ClaimsData.Department,
      Category: this.props.ClaimsData.Category,
      sub1: this.props.ClaimsData.Sub1,
      sub2: this.props.ClaimsData.Sub2,
      sub3: this.props.ClaimsData.Sub3,
      sub4: this.props.ClaimsData.Sub4,
      sub5: this.props.ClaimsData.Sub5,
      sub6: this.props.ClaimsData.Sub6,
      Base64file: '',
      file: '',
    }
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
  }

  componentDidMount() {
    let uniqueOrderID = []
    const GetAllCompany = JSON.parse(this.props.CompanyData)
    GetAllCompany.forEach((items) => {
      uniqueOrderID.push(items.Company)
    })
    uniqueOrderID = [...new Set(uniqueOrderID)];
    this.setState({ CompanyArray: uniqueOrderID, GetAllCompany})

    if(this.props.ClaimsData.ReceiptImgURL !== undefined) {
      this.props.ClaimsData.ReceiptImgURL.forEach((items) => {
        // this.fileArray.push(items.ReceiptImg)
        this.fileArray.push({Img: items.ReceiptImg, Base64: ''})
      })
    }

    this.SelectedOption(this.state.Company, 'Company')
    this.SelectedOption(this.state.Branch, 'Branch')
    this.SelectedOption(this.state.Department, 'Department')
    this.SelectedOption(this.state.Category, 'Category')
    this.SelectedOption(this.state.sub1, 'sub1')
    this.SelectedOption(this.state.sub2, 'sub2')
    this.SelectedOption(this.state.sub3, 'sub3')
    this.SelectedOption(this.state.sub4, 'sub4')
    this.SelectedOption(this.state.sub5, 'sub5')
  }

  OpenSelectedReceipt(SelectedViewReceipt, type) {
    if (type === 'download') {
      const link = SelectedViewReceipt.replace('https://ace-bt.s3.ap-southeast-1.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net').replace('https://ace-bt.s3.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net')
      fetch(link)
        .then(response => response.blob())
        .then(blob => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.download = link.replace(/^.*[\\/]/, '');
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
    } else {
      // window.open(SelectedViewReceipt, '_blank', 'noopener,noreferrer');
      const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
      if(isIOS) {
        var windowReference = window.open();
        const test = SelectedViewReceipt; // URL of the text file
        fetch(test)
          .then(response => response.text())
          .then(text => {
            if (SelectedViewReceipt.includes('txt')) {
              const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
              const url = URL.createObjectURL(blob);
              windowReference.location = url;
            } else {
              windowReference.location = test;
            }
          });
      } else {
        const test = SelectedViewReceipt; // URL of the text file
        fetch(test)
          .then(response => response.text())
          .then(text => {
            if (SelectedViewReceipt.includes('txt')) {
              const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank', 'noopener,noreferrer');
            } else {
              window.open(test, '_blank', 'noopener,noreferrer');
            }
          });
      }
    }
  }

  async SelectedOption(e, type) {
    if(type === 'Company') {
      let uniqueBranch = []
      const ReturnArray = await getCompany(this.props.username, this.props.token, e,'');
      ReturnArray.forEach((items) => {
        uniqueBranch.push(items.Branch)
      })
      uniqueBranch = [...new Set(uniqueBranch)];
      if(this.state.Company !== e) { this.ClearOptions('Company'); }
      this.setState({ BranchArray: uniqueBranch })
    }
    if(type === 'Branch') {
      let uniqueDepartment = []
      const getDep = await getCompany(this.props.username, this.props.token, this.state.Company, e);
      getDep.forEach((items) => {
        uniqueDepartment.push(items.Department)
      })
      uniqueDepartment = [...new Set(uniqueDepartment)];
      if(this.state.Branch !== e) { this.ClearOptions('Branch'); }
      this.setState({ DepartmentArray: uniqueDepartment })
    }
    if(type === 'Department') {
      let PassbackObj = {}
      PassbackObj = { department: e, Category: '', sub1: '', sub2: '', sub3: '', sub4: '', sub5: '' }
      const getCate = await getCategory(this.props.username, this.props.token, PassbackObj);
      let CategoriesA = []
      getCate.forEach((items) => {
        CategoriesA.push(items.Category)
      })
      CategoriesA = [...new Set(CategoriesA)];
      if(this.state.Department !== e) { this.ClearOptions('Department'); }
      this.setState({ CategoriesArray: CategoriesA })
    }
    if (type === 'Category' || type === 'sub1' || type === 'sub2' || type === 'sub3' || type === 'sub4' || type === 'sub5') {
      this.ClearOptions(type);
      this.setState({ [type]: e }, async()=> {
        let PassbackObj = {}
        PassbackObj = {
          department: this.state.Department,
          Category: this.state.Category, sub1: this.state.sub1, sub2: this.state.sub2, sub3: this.state.sub3, sub4: this.state.sub4,
          sub5: this.state.sub5,
        }
        const Filtertype = (type === 'Category') ? 'Sub1' : (type === 'sub1') ? 'Sub2' : (type === 'sub2') ? 'Sub3' : (type === 'sub3') ? 'Sub4' : (type === 'sub4') ? 'Sub5' : 'Sub6'
        const getSub = await getCategory(this.props.username, this.props.token, PassbackObj);
        let CategoriesA = []
        getSub.forEach((items) => {
          CategoriesA.push(items[Filtertype])
        })
        CategoriesA = [...new Set(CategoriesA)];
        const FilterEmpty = CategoriesA.filter(item => (item !== '' && item !== null));
        if (type === 'Category') {
          // if(this.state.Category !== e) { this.ClearOptions('Category'); }
          this.setState({ sub1Array: FilterEmpty })
        }
        if(type === 'sub1') {
          this.setState({sub2Array: FilterEmpty })
        }
        if(type === 'sub2') {
          this.setState({ sub3Array: FilterEmpty})
        }
        if(type === 'sub3') {
          this.setState({ sub4Array: FilterEmpty })
        }
        if(type === 'sub4') {
          this.setState({ sub5Array: FilterEmpty})
        }
        if(type === 'sub5') {
          this.setState({ sub6Array: FilterEmpty })
        }
      })
    }
    this.setState({ [type]: e })
  }

  ClearOptions(CurrentSelect) {
    if (CurrentSelect === 'Company') {
      this.setState({ Branch: '', Department: '', Category: '', sub1: '', sub2: '', sub3: '', sub4: '', sub5: '', sub6: '',
        DepartmentArray: [], CategoriesArray: [], sub1Array: [], sub2Array: [], sub3Array: [], sub4Array: [], sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'Branch') {
      this.setState({ Department: '', CategoriesArray: [], sub1Array: [], sub2Array: [], sub3Array: [], sub4Array: [], sub5Array: [], sub6Array: [],
        Category: '', sub1: '', sub2: '', sub3: '', sub4: '', sub5: '', sub6: '' })
    }
    if (CurrentSelect === 'Department') {
      this.setState({ Category: '', sub1: '', sub2: '', sub3: '', sub4: '', sub5: '', sub6: '',
        sub1Array: [], sub2Array: [], sub3Array: [], sub4Array: [], sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'Category') {
      this.setState({ sub1: '', sub2: '', sub3: '', sub4: '', sub5: '', sub6: '', sub2Array: [], sub3Array: [], sub4Array: [], sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'sub1') {
      this.setState({ sub2: '', sub3: '', sub4: '', sub5: '', sub6: '', sub3Array: [], sub4Array: [], sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'sub2') {
      this.setState({ sub3: '', sub4: '', sub5: '', sub6: '', sub4Array: [], sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'sub3') {
      this.setState({ sub4: '', sub5: '', sub6: '', sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'sub4') {
      this.setState({ sub5: '', sub6: '', sub6Array: [] })
    }
    if (CurrentSelect === 'sub5') {
      this.setState({ sub6: '' })
    }
  }

  async ReturnReceipt() {
    const newfileArray = this.newfileArray
    const fileArray = this.fileArray
    let NewReceipt = []
    let OldChangedReceipt = []

    newfileArray.forEach((items) => {
      NewReceipt.push(items.Base64)
    })
    fileArray.forEach((items) => {
      OldChangedReceipt.push(items.Img)
    })
    let Base64Files = []
    if(NewReceipt !== '') {
      NewReceipt.forEach((items) => {
        if(items.includes('data:image')) {
          Base64Files.push({filetype: 'image', base64: items})
        } else {
          Base64Files.push({filetype: 'pdf', base64: items})
        }
      })
    }

    let FinalObj = {}
    FinalObj = {
      selectedDate: this.state.selectedDate,
      Username: this.props.ClaimsData.UploadBy,
      Company: this.state.Company,
      Branch: this.state.Branch,
      Department: this.state.Department,
      Category: this.state.Category,
      Sub1: this.state.sub1,
      Sub2: this.state.sub2,
      Sub3: this.state.sub3,
      Sub4: this.state.sub4,
      Sub5: this.state.sub5,
      Sub6: this.state.sub6,
      Title: this.state.ClaimTitle,
      Remark: this.state.Remark,
      Amount: this.state.AmountClaim,
      AffectedImageArray: (OldChangedReceipt.length > 0) ? OldChangedReceipt : [],
      Document: (Base64Files.length > 0) ? Base64Files : [],
    }
    if (this.state.Company === '') {
      this.props.openNotification(`${language[this.props.currentLanguage].CompanyErrorMEssage}`,'error')
    } else if (this.state.Branch === '') {
      this.props.openNotification(`${language[this.props.currentLanguage].BranchErrorMessage}`,'error')
    } else if (this.state.Department === '') {
      this.props.openNotification(`${language[this.props.currentLanguage].DepartmentErrorMessage}`,'error')
    } else if (this.state.Category === '') {
      this.props.openNotification(`${language[this.props.currentLanguage].ErrorSelectSubject}`,'error')
    } else if (this.state.AmountClaim === '') {
      this.props.openNotification(`${language[this.props.currentLanguage].AmountErrorMessage}`,'error')
    } else {
      this.setState({ uploadloading: true })
      FinalObj.ID = this.props.ClaimsData.ID
      const editClaim = await EditClaim(FinalObj, this.state.SelectedStatus, this.props.username, this.props.token)
      if (editClaim.message === 'Update successful') {
        this.props.openNotification(`${language[this.props.currentLanguage].SuccessEdit}`,'success')
        this.props.CloseViewReceipt();
        this.props.handleClose();
        this.props.getClaimData();
      }
    }
  }

  uploadMultipleFiles(e) {
    const BeforeFileArray = this.newfileArray || []
    this.newfileArray = []
    this.fileObj = []
    BeforeFileArray.forEach((items) => {
      this.newfileArray.push(items)
    })
    if (e.target.files.length > 0) {
      let Base64Array = []
      this.fileObj.push(e.target.files)
      for (let i = 0; i < this.fileObj[0].length; i++) {
        getBase64(this.fileObj[0][i]).then(
          Base64data => this.Base64.push(Base64data)
        )
        this.newfileArray.push({Img: URL.createObjectURL(this.fileObj[0][i]), Base64: Base64Array[i]})
        this.SetBase64Function();
      }
      this.setState({ file: this.newfileArray })
    }
  }

  SetBase64Function() {
    let setBase64Array = []
    timer = setTimeout(() => {
      setBase64Array = this.Base64
      for(var i=0; i<this.newfileArray.length; i++) {
        this.newfileArray[i] = { ...this.newfileArray[i], Base64: setBase64Array[i] }
      }
    }, 100);
    this.setState({ file: this.newfileArray })
  }

  DisplayPDFFile(SelectedFile) {
    window.open(SelectedFile, '_blank', 'noopener,noreferrer');
  }

  DeleteImage(url, index, Array) {
    if(Array === 'Old') {
      let fileArray = this.fileArray
      const afterFilter = fileArray.filter(item => item.Img !== url.Img);
      this.fileArray = []
      afterFilter.forEach((items) => {
        this.fileArray.push(items)
      })
      this.setState({ file: this.fileArray })
    } else {
      let newfileArray = this.newfileArray
      const afterFilter = newfileArray.filter(item => item.Base64 !== newfileArray[index].Base64)
      this.newfileArray = []
      this.Base64 = []
      afterFilter.forEach((items) => {
        this.newfileArray.push(items)
        this.Base64.push(items.Base64)
      })
      this.setState({ file: this.newfileArray })
    }
  }

  render() {
    return (
      <Modal centered show={this.props.OpenEdittablemodal} size="lg" onHide={this.props.CloseViewReceipt}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            查看自己申请
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Date}</div>
              <Form.Control style={{ width: '60%', backgroundColor: 'white' }} value={moment(this.props.ClaimsData.UploadDateTime).format('DD-MM-YYYY')} disabled={true}/>
            </div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Users}</div>
              <Form.Control style={{ width: '60%', backgroundColor: 'white' }} value={this.props.ClaimsData.UploadBy} disabled={true}/>
            </div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Company}<div style={{ color: 'red' }}>*</div></div>
              <Form.Select style={{ width: '60%' }} value={this.state.Company} onChange={(e) => this.SelectedOption(e.target.value, 'Company')}>
                <option value=''>-</option>
                {this.state.CompanyArray.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Branch}<div style={{ color: 'red' }}>*</div></div>
              <Form.Select style={{ width: '60%'}} value={this.state.Branch} onChange={(e) => this.SelectedOption(e.target.value, 'Branch')}>
                <option value=''>-</option>
                {this.state.BranchArray.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Department}<div style={{ color: 'red' }}>*</div></div>
              <Form.Select style={{ width: '60%'}} value={this.state.Department} onChange={(e) => this.SelectedOption(e.target.value, 'Department')}>
                <option value=''>-</option>
                {this.state.DepartmentArray.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Subject}<div style={{ color: 'red' }}>*</div></div>
              <Form.Select style={{ width: '60%'}} value={this.state.Category} onChange={(e) => this.SelectedOption(e.target.value, 'Category')}>
                <option value=''>-</option>
                {this.state.CategoriesArray.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}1</div>
              <Form.Select style={{ width: '60%'}} value={this.state.sub1} onChange={(e) => this.SelectedOption(e.target.value, 'sub1')}>
                <option value=''>-</option>
                {this.state.sub1Array.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}2</div>
              <Form.Select style={{ width: '60%'}} value={this.state.sub2} onChange={(e) => this.SelectedOption(e.target.value, 'sub2')}>
                <option value=''>-</option>
                {this.state.sub2Array.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </div>
            {/* <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}3</div>
              <Form.Select style={{ width: '60%'}} value={this.state.sub3} onChange={(e) => this.SelectedOption(e.target.value, 'sub3')}>
                <option value=''>-</option>
                {this.state.sub3Array.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}4</div>
              <Form.Select style={{ width: '60%'}} value={this.state.sub4} onChange={(e) => this.SelectedOption(e.target.value, 'sub4')}>
                <option value=''>-</option>
                {this.state.sub4Array.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}5</div>
              <Form.Select style={{ width: '60%'}} value={this.state.sub5} onChange={(e) => this.SelectedOption(e.target.value, 'sub5')}>
                <option value=''>-</option>
                {this.state.sub5Array.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </div>
            <div className="ClaimInput">
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}6</div>
              <Form.Select style={{ width: '60%'}} value={this.state.sub6} onChange={(e) => this.SelectedOption(e.target.value, 'sub6')}>
                <option value=''>-</option>
                {this.state.sub6Array.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </div> */}
            <div className='ClaimInput'>
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Title}</div>
              <Form.Control style={{ width: '60%'}} value={this.state.ClaimTitle} onChange={(e) => this.setState({ ClaimTitle: e.target.value })}/>
            </div>
            <div className='ClaimInput'>
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Remark}</div>
              <textarea style={{ width: '60%', borderColor: 'lightgray', minHeight: 95}} value={this.state.Remark} onChange={(e) => this.setState({ Remark: e.target.value })}></textarea>
            </div>
            <div className='ClaimInput'>
              <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Amount}<div style={{ color: 'red' }}>*</div></div>
              <Form.Control style={{ width: '60%' }} value={this.state.AmountClaim} type="Number" onChange={(e) => this.setState({ AmountClaim: e.target.value })}/>
            </div>
            <form>
              <div className="form-group multi-preview" style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}>
                {(this.fileArray || []).map((url, ind) => (
                  // <div key={ind} style={{ display: 'flex', flexDirection: 'column'}}>
                  //   <img className="button2" style={{ width: 200, height: 200, padding: 1, cursor: 'pointer' }} src={url.Img} alt="images" onClick={() => this.DisplayPDFFile(url.Img)}/>
                  //   <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  //     <Button className="button3" style={{ width: '50%' }} onClick={() => this.OpenSelectedReceipt(url.Img, 'download')}>{language[this.props.currentLanguage].Download}</Button>
                  //     <Button className="button3" style={{ width: '50%', backgroundColor: 'red', borderColor: 'red' }} onClick={() => this.DeleteImage(url, ind, 'Old')}>{language[this.props.currentLanguage].Delete}</Button>
                  //   </div>
                  // </div>
                  <div key={ind} style={{ display: 'flex', flexDirection: 'column', width: '90%'}}>
                    <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                      <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '80%' }}>{url.Img}</div>
                      <div className='dcc' onClick={() => this.DisplayPDFFile(url.Img)} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                      <div className='dcc' onClick={() => this.DeleteImage(url, ind, 'Old')} style={{ cursor: 'pointer', padding: 3, color: 'red', width: '10%'  }}>{language[this.props.currentLanguage].Delete}</div>
                    </div>
                  </div>
                ))}
                {(this.newfileArray || []).map((url, ind) => (
                  // <div key={ind} style={{ display: 'flex', flexDirection: 'column'}}>
                  //   <img className="button2" style={{ width: 200, height: 200, padding: 1, cursor: 'pointer' }} src={url.Img} alt="images" onClick={() => this.DisplayPDFFile(url.Img)}/>
                  //   <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  //     <Button className="button3" style={{ width: '50%' }} onClick={() => this.OpenSelectedReceipt(url.Img, 'download')}>{language[this.props.currentLanguage].Download}</Button>
                  //     <Button className="button3" style={{ width: '50%', backgroundColor: 'red', borderColor: 'red' }} onClick={() => this.DeleteImage(url, ind, 'new')}>{language[this.props.currentLanguage].Delete}</Button>
                  //   </div>
                  // </div>
                  <div key={ind} style={{ display: 'flex', flexDirection: 'column', width: '90%'}}>
                    <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                      <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '80%' }}>{url.Img}</div>
                      <div className='dcc' onClick={() => this.DisplayPDFFile(url.Img)} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                      <div className='dcc' onClick={() => this.DeleteImage(url, ind, 'new')} style={{ cursor: 'pointer', padding: 3, color: 'red', width: '10%'  }}>{language[this.props.currentLanguage].Delete}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="form-group" style={{ marginBottom: 5 }}>
                <input disabled={(this.state.SelectedStatus === 'Return') ? true : false} type="file" accept=".jpg,.png,.jpeg,.pdf" className="form-control" onChange={this.uploadMultipleFiles} multiple />
              </div>
            </form>
          </div>
          <div>
            {this.props.SelectedTypes.SelectedType === '待批' && this.props.SelectedTypes.SelectedTable === 'Self' && 
              <Button className='button3' style={{ marginRight: 5 }} onClick={() => this.ReturnReceipt()}>{'Edit'}</Button>
            }
          </div>
          <div style={{ marginTop: 5, width: '80%' }}>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>{language[this.props.currentLanguage].ActionsUser}</th>
                  <th>{language[this.props.currentLanguage].Date}</th>
                  <th>{language[this.props.currentLanguage].Remark}</th>
                  <th>{language[this.props.currentLanguage].Reason}</th>
                  <th>{language[this.props.currentLanguage].Amount}</th>
                  <th>{language[this.props.currentLanguage].Action}</th>
                </tr>
              </thead>
              {this.props.HistoryData.length > 0 &&
                    <tbody>
                      {this.props.HistoryData.map((items, index) => {
                        return(
                          <tr key={index}>
                            <td>{items.ActionUser}</td>
                            <td>{moment(items.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td style={{ wordBreak: 'break-word' }}>{items.Comment}</td>
                            <td>{items.Reason}</td>
                            <td>{items.Amount}</td>
                            {/* <td>{items.Status}</td> */}
                            <td>{language[this.props.currentLanguage][items.Status]}</td>
                          </tr>
                        )
                      })}
                    </tbody>
              }
              {this.props.HistoryData.length === 0 &&
                    <tbody>
                      <tr>
                        <td colSpan="6" style={{ textAlign: 'center', fontWeight: 'bold' }}>No History Data</td>
                      </tr>
                    </tbody>
              }
            </Table>
          </div>
          <Button variant="danger" onClick={this.props.CloseViewReceipt}>{language[this.props.currentLanguage].Close}</Button>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    CompanyData: state.CompanyData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainEditModal);
