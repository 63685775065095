import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import moment from 'moment-timezone';
import { Form } from 'react-bootstrap';

class DisplayTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: '#0D6EFD',
    }
  }

  render() {
    return (
    // <Table striped bordered hover responsive style={{ fontSize: '13px'}}>
    //   <thead>
    //     <tr style={{ textAlign: 'center'}}>
    //       <th>{language[this.props.currentLanguage].Date}</th>
    //       <th>
    //         <div>{language[this.props.currentLanguage].Name}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterName} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilterName')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterName.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       <th>
    //         <div>{language[this.props.currentLanguage].Company}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterCompany} onChange={(e) => this.props.FilterTableView(e.target.value, 'Company')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterCompany.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       <th>
    //         <div>{language[this.props.currentLanguage].Branch}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterBranch} onChange={(e) => this.props.FilterTableView(e.target.value, 'Branch')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterBranch.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       <th>
    //         <div>{language[this.props.currentLanguage].Department}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterDepartment} onChange={(e) => this.props.FilterTableView(e.target.value, 'Department')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterDepart.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       {/* <th>{language[this.props.currentLanguage].Subject}</th> */}
    //       <th>
    //         <div>{language[this.props.currentLanguage].Subject}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterSub} onChange={(e) => this.props.FilterTableView(e.target.value, 'Category')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterSub.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       {/* <th>{language[this.props.currentLanguage].SubSubject}</th> */}
    //       <th>
    //         <div>{language[this.props.currentLanguage].SubSubject}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterSubSub} onChange={(e) => this.props.FilterTableView(e.target.value, 'Sub1')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterSubSub.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       {/* <th>{language[this.props.currentLanguage].Amount}</th> */}
    //       <th>
    //         <div>{language[this.props.currentLanguage].Amount}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterAmount} onChange={(e) => this.props.FilterTableView(e.target.value, 'Amount')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterAmount.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       {/* <th>{language[this.props.currentLanguage].Approver}</th> */}
    //       <th>
    //         <div>{language[this.props.currentLanguage].Approver}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterApprover} onChange={(e) => this.props.FilterTableView(e.target.value, 'FirstApprove')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterApprove.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       {/* <th>{language[this.props.currentLanguage].FirstApproveStartus}</th> */}
    //       <th>
    //         <div>{language[this.props.currentLanguage].FirstApproveStartus}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterApprovalStatus} onChange={(e) => this.props.FilterTableView(e.target.value, 'FirstApproveStatus')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterApprovalStatus.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       {/* <th>{language[this.props.currentLanguage].SecondApprover}</th> */}
    //       <th>
    //         <div>{language[this.props.currentLanguage].SecondApprover}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterSecondApprover} onChange={(e) => this.props.FilterTableView(e.target.value, 'SecondApprove')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterSecondApprover.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       {/* <th>{language[this.props.currentLanguage].SecondApproveStatus}</th> */}
    //       <th>
    //         <div>{language[this.props.currentLanguage].SecondApproveStatus}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterSecondApprovalStatus} onChange={(e) => this.props.FilterTableView(e.target.value, 'SecondApproveStatus')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterSecondApprovalStatus.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       {/* <th>{language[this.props.currentLanguage].ApprovedAmount}</th> */}
    //       <th>
    //         <div>{language[this.props.currentLanguage].ApprovedAmount}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterApproveAmount} onChange={(e) => this.props.FilterTableView(e.target.value, 'ApproveAmount')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterApproveAmount.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       {/* <th>{language[this.props.currentLanguage].Accounting}</th> */}
    //       <th>
    //         <div>{language[this.props.currentLanguage].Accounting}</div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterAccounting} onChange={(e) => this.props.FilterTableView(e.target.value, 'Accountant')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterAccounting.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
    //         </div>
    //       </th>
    //       <th>{language[this.props.currentLanguage].EditedDate}</th>
    //       <th>{language[this.props.currentLanguage].Remark}</th>
    //       <th>
    //         <div>{language[this.props.currentLanguage].LatestStatus} </div>
    //         <div className='TableFilterListContainer'>
    //           <Form.Select style={{ width: 'auto', maxWidth: 100 }} value={this.props.ShowFilterStatus} onChange={(e) => this.props.FilterTableView(e.target.value, 'ApproverOption')}>
    //             <option value='All'>{language[this.props.currentLanguage].All}</option>
    //             {this.props.FilterStatus.map((item, index) => 
    //               <option key={index} value={item}>{item}</option>
    //             )}
    //           </Form.Select>
      //         </div>
      //       </th>
      //       <th>{language[this.props.currentLanguage].Action}</th>
      //       {this.props.fastActions === true && 
      //         <td></td>
      //       }
      //       {this.props.reClaimfastActions === true && 
      //         <td></td>
      //       }
      //     </tr>
      //   </thead>
      //   {this.props.claimData.length > 0 ?
      //     <tbody>
      //       {this.props.claimData.map((item, index) => <tr key={index}>
      //         <td>{moment(item.UploadDateTime).format('YYYY-MM-DD')}</td>
      //         <td style={{ padding: 10 }}>{item.Name}</td>
      //         <td>{item.Company}</td>
      //         <td>{item.Branch}</td>
      //         <td>{item.Department}</td>
      //         <td>{item.Category}</td>
      //         <td>{item.Sub1}</td>
      //         <td>{item.Amount}</td>
      //         <td>{item.FirstApprove}</td>
      //         <td>{item.FirstApproveStatus}</td>
      //         <td>{item.SecondApprove}</td>
      //         <td>{item.SecondApproveStatus}</td>
      //         <td>{item.ApproveAmount}</td>
      //         <td>{item.Accountant}</td>
      //         <td>{(moment(item.ActionDateTime).format('YYYY-MM-DD') === 'Invalid date') ? '' : moment(item.ActionDateTime).format('YYYY-MM-DD')}</td>
      //         <td style={{ wordBreak: 'break-word', minWidth: 100 }}>{item.Comment.length > 100 ? `${item.Comment.substring(0, 50)}........` : item.Comment}</td>
      //         <td style={{ color: (item.Status === 'Reject' || item.Status === 'Return') ? 'red' : (item.Status === 'CashOut') ? '#00C44A' : '' }}>{item.Status}</td>
      //         <td>
      //           {this.props.pagetype === 'HomePage' ? 
      //             <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
      //               {((this.props.role === 'Account' && item.UploadBy === this.props.username) || this.props.role !== 'Account') && 
      //           <Button className='ButtonDesign' style={{ padding: 3 }} onClick={() => this.props.OpenClaimModal('Edit', item)} variant="primary">{language[this.props.currentLanguage].Edit}</Button>
      //               }
      //               {/* {((this.props.role === 'Account' && item.UploadBy === this.props.username) || this.props.role === 'CEO') &&  */}
      //               {((item.UploadBy === this.props.username) && this.props.role === 'CEO') && 
      //           <Button className='ButtonDesign' style={{ padding: 3 }} onClick={() => this.props.DeleteClaims(item)} variant="danger">{language[this.props.currentLanguage].Delete}</Button>
      //               }
      //             </div>
      //             : this.props.pagetype === 'Approvel' ? 
      //               <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' , alignItems: 'center' }}> 
      //                 {(item.Status !== 'Reject' && item.Status !== 'Return') &&
      //                   <div style={{ width: '50%'}}><Button className='ButtonDesign' style={{ padding: 3 }} onClick={() => this.props.OpenApproveModal(item)} variant="primary">{language[this.props.currentLanguage].Approve}</Button></div>
      //                 }
      //                 {item.Status === 'Return' && 
      //                   <div style={{ width: '50%'}}><Button className='ButtonDesign' style={{ padding: 3 }} onClick={() => this.props.OpenRedoReturnModal(item)} variant="primary">{language[this.props.currentLanguage].overrule}</Button></div>
      //                 }
      //                 {item.Status === 'Reject' && 
      //                   <div style={{ width: '50%'}}><Button className='ButtonDesign' style={{ padding: 3 }} onClick={() => this.props.OpenReportDetailsModal(item)} variant="primary">{language[this.props.currentLanguage].View}</Button></div>
      //                 }
      //                 {/* {item.ReceiptImgURL !== undefined && 
      //                 <div style={{ width: '50%'}}><Button className='ButtonDesign' style={{ padding: 3 }} onClick={() => this.props.WatchReceipt(item.ReceiptImgURL)} variant="warning">查看</Button></div>
      //                 } */}
      //               </div>
      //               :
      //               <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center'}}>
      //                 {(item.Status !== 'Reject' && item.Status !== 'Return') &&
      //                   <div style={{ width: '50%'}}><Button className='ButtonDesign' style={{ padding: 3 }} onClick={() => this.props.OpenApproveModal(item)} variant="primary">{(item.Status === 'CashOut') ? language[this.props.currentLanguage].View : language[this.props.currentLanguage].CashOut}</Button></div>
      //                 }
      //                 {item.Status === 'Return' &&
      //                   <div style={{ width: '50%'}}><Button className='ButtonDesign' style={{ padding: 3 }} onClick={() => this.props.OpenRedoReturnModal(item)} variant="primary">{language[this.props.currentLanguage].overrule}</Button></div>
      //                 }
      //                 {/* {item.ReceiptImgURL !== undefined && 
      //         <div style={{ width: '50%'}}><Button className='ButtonDesign' style={{ padding: 3 }} onClick={() => this.props.WatchReceipt(item.ReceiptImgURL)} variant="warning">查看</Button></div>
      //                 } */}
      //                 {/* {item.Status === 'CashOut' &&
      //                   <div style={{ width: '50%'}}><Button className='ButtonDesign' style={{ padding: 3 }} onClick={() => this.props.ExportReceipt(item, 'Download')} variant="secondary">{language[this.props.currentLanguage].Receipt}</Button></div>
      //                 } */}
      //               </div>}
      //         </td>
      //         {this.props.fastActions === true && (this.props.fasttype === 'Delete') &&  (item.Status !== 'Reject') && (item.Status !== 'CashOut') &&
      //           <td>
      //             {((this.props.role === 'Account' && item.UploadBy === this.props.username) || this.props.role !== 'Account') &&
      //             <div>
      //               <Form.Check
      //                 className='CheckBoxDesign'
      //                 type="checkbox"
      //                 onChange={(e) => this.props.SelectedActions(item.ID)}
      //               />
      //             </div> 
      //             }
      //           </td>
      //         }
      //         {this.props.fastActions === true && (this.props.fasttype === 'Approve') && 
      //           <td>
      //             {item.Status === 'Pending' && 
      //             <div>
      //               <Form.Check
      //                 className='CheckBoxDesign'
      //                 type="checkbox"
      //                 onChange={(e) => this.props.SelectedActions(item.ID)}
      //               />
      //             </div>
      //             }
      //           </td>
      //         }
      //         {this.props.fastActionApprove2 === true && (this.props.fasttype === 'Approve2') && 
      //           <td>
      //             {item.Status === 'FirstApprove' && 
      //             <div>
      //               <Form.Check
      //                 className='CheckBoxDesign'
      //                 type="checkbox"
      //                 onChange={(e) => this.props.SelectedApprove2(item.ID)}
      //               />
      //             </div>
      //             }
      //           </td>
      //         }
      //         {this.props.fastCashOutClaim === true && (this.props.fasttype === 'CashOut') && (item.Status !== 'CashOut') && (item.Status !== 'Return') && 
      //         <td>
      //           {item.Status !== 'Pending' && 
      //           <div>
      //             <Form.Check
      //               className='CheckBoxDesign'
      //               type="checkbox"
      //               onChange={(e) => this.props.SelectedCashOut(item)}
      //             />
      //           </div>
      //           }
      //         </td>
      //         }
      //         {this.props.fastActionsReject === true && this.props.fasttype === 'Reject' && item.Status !== 'CashOut' && 
      //           <td>
      //             {(item.Status !== 'Reject' && item.Status !== 'Return') && 
      //             <div>
      //               <Form.Check
      //                 className='CheckBoxDesign'
      //                 type="checkbox"
      //                 onChange={(e) => this.props.SelectedRejects(item.ID)}
      //               />
      //             </div>
      //             }
      //           </td>
      //         }
      //         {this.props.reClaimfastActions === true && this.props.fasttype === 'ReClaim' && item.Status === 'CashOut' &&
      //           <td>
      //             <div>
      //               <Form.Check
      //                 style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
      //                 type="checkbox"
      //                 onChange={(e) => this.props.SelectedReClaim(item.ID)}
      //               />
      //             </div>
      //           </td>
      //         }
      //       </tr>)}
      //       <div style={{ display: 'flex', flexDirection: 'row', width: 'auto'}}>
      //         {this.props.page > this.props.countpagemin && 
      //           <div onClick={()=> this.props.SetStatePage('previous')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>{'<<<'}</div>
      //         }
      //         <div style={{ fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>{`${this.props.page}`}</div>
      //         {this.props.page < this.props.countpagemax && 
      //           <div onClick={()=> this.props.SetStatePage('Next')} style={{ cursor: 'pointer' }}>{'>>>'}</div>
      //         }
      //         {/* <div onClick={()=> this.props.SetStatePage('Next')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>{'>>>'}</div> */}
      //       </div>
      //     </tbody>
      //     :
      //     <tbody>
      //       <tr style={{ backgroundColor: 'lightgray'}}>
      //         <td colSpan="18" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
      //       </tr>
      //     </tbody>}
      //   <tbody style={{ fontWeight: 'bold' }}>
      //     <tr>
      //       <td colSpan="7" style={{ textAlign: 'center' }}>{language[this.props.currentLanguage].Total}</td>
      //       <td>{Number(this.props.TotalAmount.TotalAmount).toFixed(2)}</td>
      //       <td colSpan="4"></td>
      //       <td>{Number(this.props.TotalAmount.TotalApproveAmount).toFixed(2)}</td>
      //       <td colSpan="5"></td>
      //     </tr>
      //   </tbody>
      // </Table>
      <div className="dcc" style={{ width: '80%', marginBottom: 50 }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
          <div  style={{ flex: "1", border: "1px solid black", padding: 5}}>
            <Fragment>
              <div>
                <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                  <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: '#4A5874', padding: 5 }}>
                    <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {language[this.props.currentLanguage].Date}
                    </div>
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {/* <div>{language[this.props.currentLanguage].Name}</div> */}
                      {this.props.pagetype === 'HomePage' && 
                       <div>{language[this.props.currentLanguage].Name}</div>
                      }
                      {this.props.pagetype !== 'HomePage' && 
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterName} onChange={(e) => this.props.FilterTableView(e.target.value, 'ShowFilterName')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Name}`}</option>
                          {this.props.FilterName.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                      }
                    </div>
                    {/* <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterCompany} onChange={(e) => this.props.FilterTableView(e.target.value, 'Company')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Company}`}</option>
                          {this.props.FilterCompany.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterBranch} onChange={(e) => this.props.FilterTableView(e.target.value, 'Branch')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Branch}`}</option>
                          {this.props.FilterBranch.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div> */}
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {/* <div>{language[this.props.currentLanguage].Department}</div> */}
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterDepartment} onChange={(e) => this.props.FilterTableView(e.target.value, 'Department')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Department}`}</option>
                          {this.props.FilterDepart.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {/* <div>{language[this.props.currentLanguage].Subject}</div> */}
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterSub} onChange={(e) => this.props.FilterTableView(e.target.value, 'Category')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Subject}`}</option>
                          {this.props.FilterSub.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {/* <div>{language[this.props.currentLanguage].Subject}</div> */}
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterSubSub} onChange={(e) => this.props.FilterTableView(e.target.value, 'SelectedSub1')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Subject}1`}</option>
                          {this.props.FilterSubSub.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    {/* {this.props.pagetype === 'Approvel' &&  */}
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {/* <div>{language[this.props.currentLanguage].Amount}</div> */}
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterAmount} onChange={(e) => this.props.FilterTableView(e.target.value, 'Amount')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Amount}`}</option>
                          {this.props.FilterAmount.map((item, index) => 
                            <option key={index} value={item}>{Number(item).toLocaleString()}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    {/* } */}
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {/* <div>{language[this.props.currentLanguage].Approver}</div> */}
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterApprover} onChange={(e) => this.props.FilterTableView(e.target.value, 'FirstApprove')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Approver}`}</option>
                          {this.props.FilterApprove.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {/* <div>{language[this.props.currentLanguage].FirstApproveStartus}</div> */}
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterApprovalStatus} onChange={(e) => this.props.FilterTableView(e.target.value, 'FirstApproveStatus')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].FirstApproveStartus}`}</option>
                          {this.props.FilterApprovalStatus.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    {/* <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      <div>{language[this.props.currentLanguage].SecondApprover}</div>
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterSecondApprover} onChange={(e) => this.props.FilterTableView(e.target.value, 'SecondApprove')}>
                          <option value='All'>{language[this.props.currentLanguage].All}</option>
                          {this.props.FilterSecondApprover.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div> */}
                    {/* <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      <div>{language[this.props.currentLanguage].SecondApproveStatus}</div>
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterSecondApprovalStatus} onChange={(e) => this.props.FilterTableView(e.target.value, 'SecondApproveStatus')}>
                          <option value='All'>{language[this.props.currentLanguage].All}</option>
                          {this.props.FilterSecondApprovalStatus.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div> */}
                    {this.props.pagetype !== 'Approvel' && 
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {/* <div>{language[this.props.currentLanguage].ApprovedAmount}</div> */}
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterApproveAmount} onChange={(e) => this.props.FilterTableView(e.target.value, 'ApproveAmount')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].ApprovedAmount}`}</option>
                          {this.props.FilterApproveAmount.map((item, index) => 
                            <option key={index} value={item}>{Number(item).toLocaleString()}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    }
                    {this.props.pagetype !== 'Approvel' && 
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {/* <div>{language[this.props.currentLanguage].Accounting}</div> */}
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterAccounting} onChange={(e) => this.props.FilterTableView(e.target.value, 'Accountant')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Accounting}`}</option>
                          {this.props.FilterAccounting.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    }
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      {/* <div>{language[this.props.currentLanguage].LatestStatus} </div> */}
                      <div className='TableFilterListContainer'>
                        <select style={{ width: 'auto', maxWidth: 80, fontSize: '14px' }} value={this.props.ShowFilterStatus} onChange={(e) => this.props.FilterTableView(e.target.value, 'ApproverOption')}>
                          <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].LatestStatus}`}</option>
                          {this.props.FilterStatus.map((item, index) => 
                            <option key={index} value={item}>{item}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className={this.props.BorderHide ? "dcc BorderHideTrue" : "dcc BorderHideFalse"} 
                      onClick={() => this.props.SetStateProps('BorderHide')}
                      style={{  width:'calc(90%)', color: 'white'}}>
                      {language[this.props.currentLanguage].Remark}
                    </div>
                    <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                      <div className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: 5 }}>{language[this.props.currentLanguage].Action}</div> 
                        <Form.Check
                          className='CheckBoxDesign'
                          type="checkbox"
                          checked={this.props.SelectedAll}
                          onChange={(e) => this.props.SelectAll(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.claimData.length > 0 && 
              <>
                {this.props.claimData.map((item, index) => {
                  return(
                    <div className='dcc' key={index} style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 5, backgroundColor: (index%2) ? '#F1F1F1' : 'white' }}>
                      <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {moment(item.UploadDateTime).format('DD/MM/YYYY')}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.Name}
                        </div>
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.Company}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.Branch}
                        </div> */}
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.Department}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.Category}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.Sub1}
                        </div>
                        {/* {this.props.pagetype === 'Approvel' &&  */}
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {Number(item.Amount).toLocaleString()}
                        </div>
                        {/* } */}
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.FirstApprove}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.FirstApproveStatus}
                        </div>
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.SecondApprove}
                        </div> */}
                        {/* <div className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.SecondApproveStatus}
                        </div> */}
                        {this.props.pagetype !== 'Approvel' && 
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {Number(item.ApproveAmount).toLocaleString()}
                        </div>
                        }
                        {this.props.pagetype !== 'Approvel' && 
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          {item.Accountant}
                        </div>
                        }
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: (item.Status === 'Reject' || item.Status === 'Return') ? 'red' : (item.Status === 'CashOut') ? '#00C44A' : (item.Status === 'Pending') ? 'blue' : '' }}>
                          {item.Status}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                          { this.props.BorderHide ? item.Comment : (item.Comment.length > 20 ? `${item.Comment.substring(0, 20)}........` : item.Comment)}
                        </div>
                        <div className='dcc' style={{ flexDirection: 'row', display: 'flex', width:'calc(100%)' }}>
                          {this.props.pagetype === 'HomePage' ? 
                            <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                              {((this.props.role === 'Account' && item.UploadBy === this.props.username) || this.props.role !== 'Account') && 
                <button className="PrimaryButton" style={{ padding: 3, color: 'white', marginRight: 2 }} onClick={() => this.props.OpenClaimModal('Edit', item)}>{language[this.props.currentLanguage].Edit}</button>
                              }
                              {/* backgroundColor: '#0D6EFD' backgroundColor: 'red'*/}
                              {/* {((this.props.role === 'Account' && item.UploadBy === this.props.username) || this.props.role === 'CEO') &&  */}
                              {((item.UploadBy === this.props.username) && this.props.role === 'CEO') && 
                <button className="AlertButton" style={{ padding: 3, color: 'white' }} onClick={() => this.props.DeleteClaims(item)}>{language[this.props.currentLanguage].Delete}</button>
                              }
                            </div>
                            : this.props.pagetype === 'Approvel' ? 
                              <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' , alignItems: 'center' }}> 
                                {(item.Status !== 'Reject' && item.Status !== 'Return') &&
                        <div><button className="PrimaryButton" style={{ padding: 3, backgroundColor: '#0D6EFD', color: 'white', width: 'auto' }} onClick={() => this.props.OpenApproveModal(item)}>{language[this.props.currentLanguage].Approve}</button></div>
                                }
                                {item.Status === 'Return' && 
                        <div><button  className="DefaultButton" style={{ padding: 3, backgroundColor: '#0D6EFD', color: 'white', width: 'auto' }} onClick={() => this.props.OpenRedoReturnModal(item)}>{language[this.props.currentLanguage].overrule}</button></div>
                                }
                                {item.Status === 'Reject' && 
                        <div><button className="DefaultButton" style={{ padding: 3, width: 'auto' }} onClick={() => this.props.OpenReportDetailsModal(item)}>{language[this.props.currentLanguage].View}</button></div>
                                }
                                {(this.props.role === 'CEO') && 
                <button className="AlertButton" style={{ padding: 3, color: 'white', marginLeft: 5 }} onClick={() => this.props.DeleteClaims(item)}>{language[this.props.currentLanguage].Delete}</button>
                                }
                              </div>
                              :
                              <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center'}}>
                                {(item.Status !== 'Reject' && item.Status !== 'Return') &&
                        <div><button  className="DefaultButton" style={{ padding: 3, backgroundColor: (item.Status === 'CashOut') ? '' : '#0D6EFD', color: (item.Status === 'CashOut') ? 'black' : 'white', width: 'auto' }} onClick={() => this.props.OpenApproveModal(item)}>{(item.Status === 'CashOut') ? language[this.props.currentLanguage].View : language[this.props.currentLanguage].CashOut}</button></div>
                                }
                                {item.Status === 'Return' &&
                        <div><button  className="DefaultButton" style={{ padding: 3, backgroundColor: 'green', color: 'white', width: 'auto' }} onClick={() => this.props.OpenRedoReturnModal(item)}>{language[this.props.currentLanguage].overrule}</button></div>
                                }
                                {(this.props.role === 'CEO') &&
                <button className="AlertButton" style={{ padding: 3, color: 'white', marginLeft: 5 }} onClick={() => this.props.DeleteClaims(item)}>{language[this.props.currentLanguage].Delete}</button>
                                }
                              </div>}
                          {/* {this.props.fastActions === true && (this.props.fasttype === 'Delete') &&  (item.Status !== 'Reject') && (item.Status !== 'CashOut') &&
                <>
                  {((this.props.role === 'Account' && item.UploadBy === this.props.username) || this.props.role !== 'Account') &&
                  <div style={{ marginLeft: 5 }}>
                    <Form.Check
                      className='CheckBoxDesign'
                      type="checkbox"
                      checked={this.props.selectedIDs.includes(item.ID)}
                      onChange={(e) => this.props.SelectedActions(item.ID)}
                    />
                  </div> 
                  }
                </>
                          }
                          {this.props.fastActions === true && (this.props.fasttype === 'Approve') && 
                <>
                  {item.Status === 'Pending' && 
                  <div style={{ marginLeft: 5 }}>
                    <Form.Check
                      className='CheckBoxDesign'
                      type="checkbox"
                      checked={this.props.selectedIDs.includes(item.ID)}
                      onChange={(e) => this.props.SelectedActions(item.ID)}
                    />
                  </div>
                  }
                </>
                          }
                          {this.props.fastActionApprove2 === true && (this.props.fasttype === 'Approve2') && 
                <>
                  {item.Status === 'FirstApprove' && 
                  <div style={{ marginLeft: 5 }}>
                    <Form.Check
                      className='CheckBoxDesign'
                      type="checkbox"
                      checked={this.props.selectedIDs.includes(item.ID)}
                      onChange={(e) => this.props.SelectedApprove2(item.ID)}
                    />
                  </div>
                  }
                </>
                          }
                          {this.props.fastCashOutClaim === true && (this.props.fasttype === 'CashOut') && (item.Status !== 'CashOut') && (item.Status !== 'Return') && 
              <>
                {item.Status !== 'Pending' && 
                <div style={{ marginLeft: 5 }}>
                  <Form.Check
                    className='CheckBoxDesign'
                    type="checkbox"
                    checked={this.props.selectedIDs.includes(item.ID)}
                    onChange={(e) => this.props.SelectedCashOut(item)}
                  />
                </div>
                }
              </>
                          }
                          {this.props.fastActionsReject === true && this.props.fasttype === 'Reject' && item.Status !== 'CashOut' && 
                <>
                  {(item.Status !== 'Reject' && item.Status !== 'Return') && 
                  <div style={{ marginLeft: 5 }}>
                    <Form.Check
                      className='CheckBoxDesign'
                      type="checkbox"
                      checked={this.props.SelectedRejectsID.includes(item.ID)}
                      onChange={(e) => this.props.SelectedRejects(item.ID)}
                    />
                  </div>
                  }
                </>
                          }
                          {this.props.reClaimfastActions === true && this.props.fasttype === 'ReClaim' && item.Status === 'CashOut' &&
                <>
                  <div style={{ marginLeft: 5 }}>
                    <Form.Check
                      style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                      type="checkbox"
                      checked={this.props.selectedIDs.includes(item.ID)}
                      onChange={(e) => this.props.SelectedReClaim(item.ID)}
                    />
                  </div>
                </>
                          } */}
                          <div style={{ marginLeft: 5 }}>
                            <Form.Check
                              style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                              type="checkbox"
                              checked={this.props.AllSelectedIDs.includes(item.ID)}
                              onChange={(e) => this.props.SelectedReAllID(item.ID)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className='dcc' style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: '#4A5874' }}>
                  <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', fontSize: '13px', fontWeight: 'bold', color: 'white' }}>
                      {'Total'}
                    </div>
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                      {''}
                    </div>
                    {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                      {''}
                    </div>
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                      {''}
                    </div> */}
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', fontSize: '13px' }}>
                      {''}
                    </div>
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                      {''}
                    </div>
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', fontSize: '13px', fontWeight: 'bold' }}>
                      {''}
                    </div>
                    {/* {this.props.pagetype === 'Approvel' &&  */}
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', fontSize: '13px', fontWeight: 'bold' }}>
                      {Number(this.props.TotalAmount.TotalAmount).toLocaleString()}
                    </div>
                    {/* } */}
                    {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                      {''}
                    </div> */}
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                      {''}
                    </div>
                    {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                      {''}
                    </div> */}
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', fontSize: '13px', fontWeight: 'bold'  }}>
                      {''}
                    </div>
                    {this.props.pagetype !== 'Approvel' && 
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', fontSize: '13px', fontWeight: 'bold' }}>
                      {Number(this.props.TotalAmount.TotalApproveAmount).toLocaleString()}
                    </div>
                    }
                    {this.props.pagetype !== 'Approvel' && 
                    <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                      {''}
                    </div>
                    }
                    <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                      {''}
                    </div>
                    <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                      {''}
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', padding: 1 }}>
                      {''}
                    </div>
                  </div>
                </div>
                {/* <div className='dcc' style={{ display: 'flex', flexDirection: 'row', width: 'auto'}}>
                  {this.props.page > this.props.countpagemin && 
                <div className='pagination' onClick={()=> this.props.SetStatePage('previous')} style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '20px' }}>
                  <a>{'🢠'}</a>
                </div>
                  }
                  {this.props.RunTotalPage.map((items, iid) => {
                    return(
                      <div key={iid}
                        className='pagination'
                        // style={{ fontWeight: 'bold', marginLeft: 15, marginRight: 15, borderRadius: 200, padding: 5, backgroundColor: (items === this.props.page) ? '#4A5874' : 'white', cursor: 'pointer', color: (items === this.props.page) ? 'white' : 'black' }}
                        onClick={() => this.props.RunPageViews(items)}>
                        {/* {`${items}`} 
                        <a className={(items === this.props.page) ? 'active' : ''}>{`${items}`}</a>
                      </div>
                    );
                  })}
                  {this.props.page < this.props.countpagemax && 
                <div   className='pagination' onClick={()=> this.props.SetStatePage('Next')} style={{ cursor: 'pointer', fontSize: '20px' }}>
                  <a>{'🢡'}</a>
                </div>
                  }
                </div> */}
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <div className="dcc button"
                      style={{ backgroundColor: this.state.background,
                        padding: 7 , cursor: 'pointer', borderRadius: 5, width: 80, marginBottom: 10, marginRight: 5 }}
                      variant="success" onClick={() => this.props.handleXLSX()}>
                      <span>
                        {'XLSX'}
                      </span>
                    </div>
                    <div className="dcc button"
                      style={{ backgroundColor: this.props.BooleanRemoveN ? '#198754' : '#0d6efd',
                        padding: 7 , cursor: 'pointer', borderRadius: 5, width: 80, marginBottom: 10 }}
                      variant="success" 
                      onClick={() => this.props.SetStateProps('BooleanRemoveN')}>
                      <span>
                        {language[this.props.currentLanguage].Remark}
                      </span>
                    </div>
                  </div>
                  <div className='dcc' style={{ display: 'flex', flexDirection: 'row', width: 'auto', fontWeight: 'bold' }}>
                    {this.props.page > this.props.countpagemin && 
                <div onClick={()=> this.props.SetStatePage('previous')} style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '20px' }}>{'🢠'}</div>
                    }
                    {this.props.RunTotalPage[0] !== 1 && 
                        <div
                          className='pagination'
                          // style={{ fontWeight: 'bold', marginLeft: 15, marginRight: 15, borderRadius: 200, padding: 5, backgroundColor: (items === this.state.page) ? '#4A5874' : 'white', cursor: 'pointer', color: (items === this.state.page) ? 'white' : 'black' }}
                          onClick={() => this.props.SetStatePage(1)}>
                          <a href="#" className={(this.props.page === 1) ? 'active' : ''}>{`1`}</a>
                        </div>
                    }
                    {(this.props.RunTotalPage[0] !== 1 && this.props.RunTotalPage[0] - 1 > 1) && 
                        <div style={{ marginLeft: 5 }}>{`. . . . `}</div>
                    }
                    {this.props.RunTotalPage.map((items, iid) => {
                      return(
                        <div key={iid}
                          className='pagination'
                          // style={{ fontWeight: 'bold', marginLeft: 15, marginRight: 15, borderRadius: 200, padding: 5, backgroundColor: (items === this.state.page) ? '#4A5874' : 'white', cursor: 'pointer', color: (items === this.state.page) ? 'white' : 'black' }}
                          onClick={() => this.props.SetStatePage(items)}>
                          <a href="#" className={(items === this.props.page) ? 'active' : ''}>{`${items}`}</a>
                        </div>
                      );
                    })}
                    {(this.props.RunTotalPage[this.props.RunTotalPage.length - 1] !== Number(this.props.TotalPages) && (Number(this.props.TotalPages) - this.props.RunTotalPage[this.props.RunTotalPage.length - 1] > 1)) &&
                        <div>{`. . . . `}</div>
                    }
                    {this.props.RunTotalPage[this.props.RunTotalPage.length - 1] !== Number(this.props.TotalPages) && 
                        <div
                          className='pagination'
                          // style={{ fontWeight: 'bold', marginLeft: 15, marginRight: 15, borderRadius: 200, padding: 5, backgroundColor: (items === this.state.page) ? '#4A5874' : 'white', cursor: 'pointer', color: (items === this.state.page) ? 'white' : 'black' }}
                          onClick={() => this.props.SetStatePage(Number(this.props.TotalPages))}>
                          <a href="#" className={(this.props.page === Number(this.props.TotalPages)) ? 'active' : ''}>{`${this.props.TotalPages}`}</a>
                        </div>
                    }
                    {this.props.page < this.props.countpagemax && 
                <div onClick={()=> this.props.SetStatePage('Next')} style={{ cursor: 'pointer', fontSize: '20px' }}>{'🢡'}</div>
                    }
                  </div>
                  <div></div>
                </div>
              </>
                }
                {this.props.claimData.length === 0 &&
              <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                  {'No Data'}
                </div>
              </div>
                }
              </div>
            </Fragment> 
          </div> 
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayTable);
