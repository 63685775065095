import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { language } from '../language';
import html2canvas from 'html2canvas';
import pdfMake from "pdfmake"
import loading from '../image/loading.gif';
import { Button, Modal, Form, Alert, Table } from 'react-bootstrap';

class ApproverCashoutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ConsirmPass: false,
      ClaimsData: '',
      ErrorMessage: '',
      Password: '',
      StraighDownload: false,
    }
  }

  DisplayPDFFile(SelectedReceipt) {
    // window.open(SelectedReceipt, '_blank', 'noopener,noreferrer');
    const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
    if(isIOS) {
      // var windowReference = window.open();
      // const test = SelectedReceipt; // URL of the text file
      // fetch(test)
      //   .then(response => response.text())
      //   .then(text => {
      //     if (SelectedReceipt.includes('txt')) {
      //       const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
      //       const url = URL.createObjectURL(blob);
      //       windowReference.location = url;
      //     } else {
      //       windowReference.location = text;
      //     }
      //   });
      var windowReference = window.open();
      const test = SelectedReceipt; // URL of the text file
      fetch(test)
        .then(response => response.text())
        .then(text => {
          if (SelectedReceipt.includes('txt')) {
            const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
            const url = URL.createObjectURL(blob);
            windowReference.location = url;
          } else {
            windowReference.location = test;
          }
        });
    } else {
      const test = SelectedReceipt; // URL of the text file
      fetch(test)
        .then(response => response.text())
        .then(text => {
          if (SelectedReceipt.includes('txt')) {
            const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank', 'noopener,noreferrer');
          } else {
            window.open(test, '_blank', 'noopener,noreferrer');
          }
        });
    }
  }

  DownloadImages(SelectedFile) {
    const link = SelectedFile.replace('https://ace-bt.s3.ap-southeast-1.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net').replace('https://ace-bt.s3.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net')
    fetch(link)
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = link.replace(/^.*[\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
  }

  onKeyEnter(e) {
    if (e.charCode === 13) {
      this.props.ConfirmPasswordAPI(this.props.SelectedClaimObject)
    }
  }
  
  ExportPDF() {
    html2canvas(document.getElementById("appear")).then(canvas => {
      var data = canvas.toDataURL();
      var pdfExportSetting = {
        content: [
          {
            image: data,
            width: 500
          }
        ]
      };
      pdfMake.createPdf(pdfExportSetting).download(`Receipt-${moment(new Date()).format('DD-MM-YYYY HH:mm:ss')}/${this.props.SelectedClaimObject.Name}.pdf`);
    });
    this.props.OpenReceiptFunction()
  }

  DownloadNow() {
    this.props.OpenReceiptFunction()
    this.setState({ StraighDownload: true })
  }

  printWindow() {
    window.print();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.OpenReceipt !== this.props.OpenReceipt && this.state.StraighDownload === true) {
      this.ExportPDF();
      this.setState({ StraighDownload: false })
    }
  }

  componentWillUnmount() {
    this.setState({ StraighDownload: false, ConsirmPass: false })
  }

  render() {
    return (
      <Modal centered show={this.props.ApproverModal} size="lg" onHide={this.props.handleClose} className='printable' style={{ fontSize: '12px' }}>
        <Alert className='ModalAlertDesign' style={{ zIndex: 99, position: 'fixed', top: 80 }}show={this.props.showNotification} variant={this.props.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.props.notificationMessage}</Alert.Heading>
        </Alert>
        {this.props.ConsirmPass === false && 
        <>
          {/* <div className='headermodal non-printable'>
            <div className='titlemodal non-printable'>
              {(this.props.SelectedClaimObject.Status === 'CashOut') ? `${language[this.props.currentLanguage].CashOutClaim}` : `${language[this.props.currentLanguage].ApproveClaim}`}
            </div>
            <button type="button" class="btn-close" aria-label="Close"  onClick={() => this.props.handleClose()}></button>
          </div> */}
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {(this.props.SelectedClaimObject.Status === 'CashOut') ? `${language[this.props.currentLanguage].CashOutClaim}` : `${language[this.props.currentLanguage].ApproveClaim}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.OpenReceipt !== true && 
            <>
              {this.props.uploadloading === true ?
                <>
                  <div className="dcc ClaimInput">
                    <img src={loading} alt="Logo" className="dcc loadingimages"/>
                  </div>
                </>
                :
                <div>
                  <div className="ClaimInput">
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].ClaimDate}:</div>
                    {/* <div style={{ marginRight: 10, width: '60%', backgroundColor: 'white' }}>{moment(this.props.SelectedClaimObject.UploadDateTime).format('DD-MM-YYYY')}</div> */}
                    <Form.Control placeholder="从" value={moment(this.props.SelectedClaimObject.UploadDateTime).format('DD-MM-YYYY')} readOnly={true} type="text" style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px' }}/>
                  </div>
                  <div className="ClaimInput">
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Users}:</div>
                    {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.SelectedClaimObject.Name}</div> */}
                    <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.SelectedClaimObject.Name} disabled={true}/>
                  </div>
                  <div className="ClaimInput">
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Company}:</div>
                    {/* <div style={{ marginRight: 10, width: '60%', backgroundColor: 'white' }}>{this.props.SelectedClaimObject.Company}</div> */}
                    <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Company} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px' }}/>
                  </div>
                  <div className="ClaimInput">
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Branch}:</div>
                    {/* <div style={{ marginRight: 10, width: '60%', backgroundColor: 'white' }}>{this.props.SelectedClaimObject.Branch}</div> */}
                    <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Branch} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px'  }}/>
                  </div>
                  <div className="ClaimInput">
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Department}:</div>
                    {/* <div style={{ marginRight: 10, width: '60%', backgroundColor: 'white' }}>{this.props.SelectedClaimObject.Department}</div> */}
                    <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Department} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px'  }}/>
                  </div>
                  <div className="ClaimInput">
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Subject}:</div>
                    {/* <div style={{ marginRight: 10, width: '60%', backgroundColor: 'white' }}>{this.props.SelectedClaimObject.Category}</div> */}
                    <Form.Control placeholder="从" value={this.props.SelectedClaimObject.Category} readOnly={true} style={{ marginRight: 10, width: '60%', backgroundColor: 'white', fontSize: '12px'  }}/>
                  </div>
                  <div className='ClaimInput'>
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Title}:</div>
                    {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.SelectedClaimObject.Subject}</div> */}
                    <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.SelectedClaimObject.Subject} disabled={true}/>
                  </div>
                  <div className='ClaimInput'>
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Remark}:</div>
                    {/* <div style={{ width: '60%', backgroundColor: 'white', wordBreak: 'break-word' }}>{this.props.SelectedClaimObject.Comment}</div> */}
                    <textarea style={{ width: '60%', backgroundColor: 'white', fontSize: '12px', minHeight: 95  }} value={this.props.SelectedClaimObject.Comment} disabled={true}></textarea>
                  </div>
                  <div className='ClaimInput'>
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Amount}:</div>
                    {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.SelectedClaimObject.Amount}</div> */}
                    <Form.Control style={{ width: '60%', backgroundColor: 'white' , fontSize: '12px' }} value={this.props.SelectedClaimObject.Amount} disabled={true}/>
                  </div>
                  <div className='ClaimInput'>
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Status}:</div>
                    {/* <div style={{ width: '60%', backgroundColor: 'white' }}>{this.props.SelectedClaimObject.Status}</div> */}
                    <Form.Control style={{ width: '60%', backgroundColor: 'white', fontSize: '12px' }} value={this.props.SelectedClaimObject.Status} disabled={true}/>
                  </div> 
                  {this.props.ReturnClicked === true && 
              <div className='ClaimInput'>
                <div className='UploadClaimTitle'>{language[this.props.currentLanguage].ReturnReason}:</div>
                <textarea style={{ width: '60%', backgroundColor: 'white' }} value={this.props.ReasonText} onChange={(e) => this.props.ConfirmAction('SetReason', e.target.value)}></textarea>
              </div> 
                  }
                  <div style={{ marginTop: 5, marginBottom: 10}}>
                    <form>
                      <div className="form-group multi-preview" style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}>
                        {(this.props.SelectedClaimObject.ReceiptImgURL || []).map((url, ind) => (
                          <div key={ind} style={{ display: 'flex', flexDirection: 'column', width: '90%'}}>
                            {/* <div>{url.ReceiptImg}</div> */}
                            <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                              <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '90%' }}>{url.ReceiptImg}</div>
                              <div className='dcc' onClick={() => this.DisplayPDFFile(url.ReceiptImg)} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                            </div>
                            {/* <img className='button2' style={{ width: 200, height: 200, padding: 1, cursor: 'pointer' }} src={url.ReceiptImg} alt="images" onClick={() => this.DisplayPDFFile(url.ReceiptImg)}/>
                            <Button className="button3" onClick={() => this.DownloadImages(url.ReceiptImg)}>{language[this.props.currentLanguage].Download}</Button> */}
                          </div>
                        ))}
                      </div>
                      {this.props.PageType === 'Approvel' && 
                <>
                  {this.props.SelectedClaimObject.Status === 'Pending' && 
              <button type="button" className="btn btn-success btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.props.ConfirmAction('Approve', this.props.SelectedClaimObject.ID)}>
                {language[this.props.currentLanguage].ApproveClaim}
              </button>
                  }
                  {this.props.SelectedClaimObject.Status === 'FirstApprove' && 
                  <button type="button" className="btn btn-success btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.props.ConfirmAction('Approve2', this.props.SelectedClaimObject.ID)}>
                    {language[this.props.currentLanguage].SecondApproveRequest}
                  </button>
                  }
                </>
                      }
                      {(this.props.PageType === 'CashOut' && this.props.SelectedClaimObject.Status !== 'CashOut') && 
                <>
                  {/* <button type="button" className="btn btn-success btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.props.ConfirmAction('CashOut', this.props.SelectedClaimObject.ID)}>
                    {'出纳'}
                  </button> */}
                  <button type="button" className="btn btn-success btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.props.OpenonfirmPAss()}>
                    {language[this.props.currentLanguage].CashOut}
                  </button>
                </>}
                      {this.props.SelectedClaimObject.Status !== 'CashOut' &&
                <>
                  {this.props.role === 'CEO' && 
                  <button type="button" className="btn btn-danger btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.props.ConfirmAction('Reject', this.props.SelectedClaimObject.ID)}>
                    {language[this.props.currentLanguage].RejectClaim}
                  </button>
                  }
                  <button type="button" className="btn btn-danger btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.props.ConfirmAction('return', this.props.SelectedClaimObject.ID)}>
                    {(this.props.ReturnClicked === true) ? `${language[this.props.currentLanguage].CancelReturnClaim}` : `${language[this.props.currentLanguage].ReturnClaim}`}
                  </button>
                </>
                      }
                      {this.props.ReturnClicked === true && 
                  <button type="button" className="btn btn-success btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.props.ConfirmAction('ConfirmReturn' , this.props.SelectedClaimObject.ID)}>
                    {language[this.props.currentLanguage].ConfirmReturnClaim}
                  </button>
                      }
                      {(this.props.SelectedClaimObject.Status === 'CashOut') && 
                <>
                  <button type="button" className="btn btn-secondary btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.props.OpenReceiptFunction()}>
                    {language[this.props.currentLanguage].ViewReceipt}
                  </button>
                  <button type="button" className="btn btn-secondary btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.DownloadNow()}>
                    {language[this.props.currentLanguage].DownloadReceipt}
                  </button>
                </>}
                      {/* <button type="button" className="btn btn-success btn-block" style={{ marginTop: 10, marginRight: 5, backgroundColor: (this.props.DisplayHistory === false) ? '#027eab' : 'green' }} onClick={() => this.props.DisplayHistorySet()}>
                        {(this.props.DisplayHistory === false) ? language[this.props.currentLanguage].DiplayActionHistory : language[this.props.currentLanguage].HideActionHistory}
                      </button> */}
                      {/* {this.props.DisplayHistory === true &&  */}
                      <div style={{ marginTop: 5, width: '80%' }}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>{language[this.props.currentLanguage].ActionsUser}</th>
                              <th>{language[this.props.currentLanguage].Date}</th>
                              <th>{language[this.props.currentLanguage].Remark}</th>
                              <th>{language[this.props.currentLanguage].Reason}</th>
                              <th>{language[this.props.currentLanguage].Amount}</th>
                              <th>{language[this.props.currentLanguage].Action}</th>
                            </tr>
                          </thead>
                          {this.props.HistoryData.length > 0 &&
                    <tbody>
                      {this.props.HistoryData.map((items, index) => {
                        return(
                          <tr key={index}>
                            <td>{items.ActionUser}</td>
                            <td>{moment(items.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td style={{ wordBreak: 'break-word' }}>{items.Comment}</td>
                            <td>{items.Reason}</td>
                            <td>{items.Amount}</td>
                            {/* <td>{items.Status}</td> */}
                            <td>{language[this.props.currentLanguage][items.Status]}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                          }
                          {this.props.HistoryData.length === 0 &&
                    <tbody>
                      <tr>
                        <td colSpan="6" style={{ textAlign: 'center', fontWeight: 'bold' }}>No History Data</td>
                      </tr>
                    </tbody>
                          }
                        </Table>
                      </div>
                      {/* } */}
                    </form>
                  </div>
                </div>
              }
              <Button variant="danger" onClick={this.props.handleClose}>{language[this.props.currentLanguage].Close}</Button>
            </>
            }
            {this.props.OpenReceipt === true &&
              <div  style={{ display: 'flex', flexDirection: 'column'}}>
                <div  id="appear" className='dcc' style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                  <div style={{ fontWeight: 'bold', fontSize: '26px', marginBottom: 40 }}>{'Payment Receipt'}</div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Company Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Company}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Branch Name '}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Branch}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Department '}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Department}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Category Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Category}`}</div>
                  </div>
                  {this.props.SelectedClaimObject.Sub1 !== null && 
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Sub Category Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Sub1}`}</div>
                  </div>
                  }
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Subject Name'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${this.props.SelectedClaimObject.Subject}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Submitted Date'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${moment(this.props.SelectedClaimObject.UploadDateTime).format('DD-MM-YYYY')}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Paid Date'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`${moment(this.props.SelectedClaimObject.ActionDateTime).format('DD-MM-YYYY')}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Remark'}</div>
                    <div style={{ width: '60%', fontSize: '17px', wordBreak: "break-word", fontWeight: '500'}}>{this.props.SelectedClaimObject.Comment}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Submitted Amount'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`RM ${this.props.SelectedClaimObject.Amount}`}</div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 65}}>
                    <div className="CashoutReceiptTitle" style={{ width: '35%' }}>{'Paid Amount'}</div>
                    <div style={{ width: '60%', fontSize: '17px', fontWeight: '500'}}>{`RM ${this.props.SelectedClaimObject.ApproveAmount}`}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%', marginBottom: 100 }}>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'Paid By'}</div>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'Receive By'}</div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 5}}>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '17px'}}>{'_____________________________'}</div>
                    <div style={{ width: '50%', fontSize: '17px'}}>{'_____________________________'}</div>
                  </div>
                  <div className="ReceiptContainer" style={{ width: '100%' }}>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '20px'}}>{`${this.props.SelectedClaimObject.Accountant}`}</div>
                    <div style={{ width: '50%', fontWeight: 'bold', fontSize: '20px'}}>{`${this.props.SelectedClaimObject.Name}`}</div>
                  </div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                  <button  type="button" className="CssButtonP ButtonH" style={{ marginTop: 10, marginRight: 5, width: '50%', }} onClick={() => 
                  {
                    // className="btn btn-secondary btn-block "
                    const  answer = window.confirm(`${language[this.props.currentLanguage].AlertPrint}`);
                    if (answer) {
                      this.printWindow()
                    }
                  }
                  }>
                    {language[this.props.currentLanguage].Print}
                  </button>
                  <button type="button" className="CssButtonP ButtonH" style={{ marginTop: 10, marginRight: 5, width: '50%' }} onClick={() => this.ExportPDF()}>
                    {language[this.props.currentLanguage].DownloadReceipt}
                  </button>
                </div>
                <button type="button" className="CssButtonRed ButtonHRed" style={{ marginTop: 10, marginRight: 5, backgroundColor: 'red' }} onClick={() => this.props.OpenReceiptFunction()}>
                  {language[this.props.currentLanguage].Back}
                </button>
              </div>
            }
          </Modal.Body>
        </>
        }
        {this.props.ConsirmPass === true && 
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {language[this.props.currentLanguage].ConfirmPassword}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="ClaimInput">
                <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Users}</div>
                <Form.Control style={{ width: '60%', textAlign: 'center', backgroundColor: 'white' }} value={(this.props.MultiCashout === true) ? this.props.SelectedClaimObject[0].UploadBy : this.props.SelectedClaimObject.UploadBy} disabled={true}/>
              </div>
              <div className='ClaimInput'>
                <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Password}</div>
                <Form.Control onKeyPress={(e) => this.onKeyEnter(e)} style={{ width: '60%', textAlign: 'center', backgroundColor: 'white'}} value={this.props.Password} onChange={(e) => this.props.OnchangePassword(e.target.value)} type="text"/>
              </div>
            </div>
            <Button variant="success" onClick={() => this.props.ConfirmPasswordAPI( this.props.SelectedClaimObject)}>{language[this.props.currentLanguage].CashOut}</Button>
            <Button variant="danger" style={{ marginLeft: 5 }} onClick={this.props.CloseConfirmPAss}>{language[this.props.currentLanguage].Back}</Button>
          </Modal.Body>
        </>
        }
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproverCashoutModal);
