import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import Header from '../Component/Header';
import { Container, Form, Button, Table, Modal, Alert } from 'react-bootstrap';
import { getCompany, postCompany, deleteDepartment } from '../Api'
import Creatable from 'react-select/creatable';

var timer

const customStyle = {
  control: (provided) => ({ ...provided, width: 150 }), menuPortal: (provided) => ({...provided,zIndex: 9999})
}
class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      departmentList: [],
      selectedCompany: '',
      selectedBranch: '',
      companyList: [],
      branchList: [],
      modalInput: [{
        Company: '',
        Branch: '',
        Department: '',
      }],
      initialCompanyList: [],
      initialBranchList: [],
      initialDepartmentList: [],
      increaseModal: false,
    }
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    if(this.props.token === '') {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      window.location.href = '/';
    }
    this.getCompanyF()
  }

  async getCompanyF() {
    const departmentList = await getCompany(this.props.username, this.props.token, this.state.selectedCompany, this.state.selectedBranch)
    let companyList = departmentList.map((item) => item.Company)
    companyList = [...new Set(companyList)]
    let branchList = departmentList.map((item) => item.Branch)
    branchList = [...new Set(branchList)]
    if (this.state.selectedCompany === '' && this.state.selectedBranch === '') {
      const initialCompanyList = companyList.map((item) => ({
        value: item,
        label: item,
      }))
      const initialBranchList = branchList.map((item) => ({
        value: item,
        label: item,
      }))
      let initialDepartmentList = departmentList.map((item) => item.Department)
      initialDepartmentList = [...new Set(initialDepartmentList)]
      initialDepartmentList = initialDepartmentList.map((item) => ({
        value: item,
        label: item,
      }))
      this.setState({ initialCompanyList, initialBranchList, initialDepartmentList })
    }
    this.setState({ departmentList, companyList, branchList })
  }

  handleClose() {
    this.setState({
      modalInput: [{
        Company: '',
        Branch: '',
        Department: '',
      }],
      increaseModal: false,
    })
  }

  addIncreaseModal(Company, Branch) {
    let modalInput = JSON.parse(JSON.stringify(this.state.modalInput))
    if (Company && Branch) {
      modalInput.push({
        Company,
        Branch,
        Department: '',
      })
    } else if (Company) {
      modalInput.push({
        Company,
        Branch: '',
        Department: '',
      })
    } else {
      modalInput.push({
        Company: '',
        Branch: '',
        Department: '',
      })
    } 
    this.setState({ modalInput })
  }
  decreaseModal(index) {
    let modalInput = JSON.parse(JSON.stringify(this.state.modalInput))
    modalInput.splice(index, 1)
    this.setState({ modalInput: [] }, () => this.setState({ modalInput }))
  }

  changeModalInput(index, type, value) {
    let modalInput = JSON.parse(JSON.stringify(this.state.modalInput))
    modalInput[index][type] = value
    this.setState({ modalInput })
  }

  async addCompany() {
    let RunApi = true
    let Line = ''
    let ErrorMessage = ''
    const ModalInput = this.state.modalInput
    ModalInput.forEach((items, index) => {
      if(items.Company === '' && items.Branch === '' && items.Department === ''){
        RunApi = false
        ErrorMessage = `${language[this.props.currentLanguage].ErrorEmptySelected}`
        Line += `${index + 1}. `
      }
      if(items.Department !== '' && (items.Company === '' || items.Branch === '')) {
        RunApi = false
        ErrorMessage = `${language[this.props.currentLanguage].ErrorSelectCompanyandBranch}`
        Line += `${index + 1}. `
      } else if(items.Branch !== '' && items.Company === '') {
        RunApi = false
        ErrorMessage = `${language[this.props.currentLanguage].ErrorSelectCompany}`
        Line += `${index + 1}. `
      }
    })

    if(RunApi === true) {
      const departmentList = await postCompany(this.props.username, this.props.token, this.state.modalInput)
      if (departmentList.message === 'success') {
        this.setState({
          modalInput: [{
            Company: '',
            Branch: '',
            Department: '',
          }],
          increaseModal: false,
          selectedCompany: '',
          selectedBranch: '',
        }, () => this.getCompanyF())
        this.openNotification(`${language[this.props.currentLanguage].SuccessAddCompany}`,'success')
      }
      if (departmentList.error === '該部門已存在') {
        this.openNotification(`${language[this.props.currentLanguage].ErrorExistedDepartment}`,'error')
      }
    } else {
      this.openNotification(`${ErrorMessage} ${language[this.props.currentLanguage].Line}: ${Line}`,'error')
    }
  }

  async deleteDepartmentF(item) {
    const r = (window.confirm(`确定要删除${item.Company}-${item.Branch}-${item.Department}?`));
    if (r === true) {
      const departmentList = await deleteDepartment(item.ID, this.props.username, this.props.token)
      if (departmentList.message === 'Deleted successful') {
        this.getCompanyF()
      }
    }
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  render() {
    return (
      <div>
        <Header/>
        <Container fluid style={{ fontSize: '15px' }}>
          <Modal centered show={this.state.increaseModal} size="lg" onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {language[this.props.currentLanguage].Add}
              </Modal.Title>
              <Alert className='ModalAlertDesign' style={{ zIndex: 99, position: 'fixed', top: 80 }} show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
              </Alert>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>{language[this.props.currentLanguage].Company}</th>
                    <th>{language[this.props.currentLanguage].Branch}</th>
                    <th>{language[this.props.currentLanguage].Department}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.modalInput.map((item, index) => <tr key={index}>
                    <td><Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Company, label: item.Company }} styles={customStyle} options={this.state.initialCompanyList} onChange={(e) => this.changeModalInput(index, 'Company', e.value)}/></td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Branch, label: item.Branch }} styles={customStyle} options={this.state.initialBranchList} onChange={(e) => this.changeModalInput(index, 'Branch', e.value)}/>
                        <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal(item.Company)} variant="primary">{language[this.props.currentLanguage].Add}</Button>
                      </div>
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Creatable menuPortalTarget={document.body} defaultValue={{ value: item.Department, label: item.Department }} styles={customStyle} options={this.state.initialDepartmentList} onChange={(e) => this.changeModalInput(index, 'Department', e.value)}/>
                        <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal(item.Company, item.Branch)} variant="primary">{language[this.props.currentLanguage].Add}</Button>
                        <td><Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.decreaseModal(index)} variant="danger">{language[this.props.currentLanguage].Delete}</Button></td>
                      </div>
                    </td>
                  </tr>)}
                  <tr>
                    <td><Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.addIncreaseModal()} variant="primary">{language[this.props.currentLanguage].Add}</Button></td>
                  </tr>
                </tbody>
              </Table>
              <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant="danger" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</Button>
                <Button variant="success" style={{marginLeft: 10}} onClick={() => this.addCompany()}>{language[this.props.currentLanguage].Confirm}</Button>
              </div>
            </Modal.Body>
          </Modal>
          <div style={{ display: 'flex', padding: '10px 0px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
              <h2 style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Add}{language[this.props.currentLanguage].Company}</h2>
            </div>
            <div style={{ display: 'flex' }}>
              <Button style={{ minWidth: 80 }} onClick={() => this.setState({ selectedCompany: '', selectedBranch: '' }, () => this.getCompanyF())} variant="danger">Reset</Button>
              <Button style={{ minWidth: 80, marginLeft: 10 }} onClick={() => this.setState({ increaseModal: true })} variant="primary">{language[this.props.currentLanguage].Add}</Button>
            </div>
          </div>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {language[this.props.currentLanguage].Company}
                    <Form.Select style={{ width: 150, marginLeft: 10}} value={this.state.selectedCompany} onChange={(e) => this.setState({ selectedCompany: e.target.value}, () => this.getCompanyF())}>
                      <option></option>
                      {this.state.companyList.length > 0 && this.state.companyList.map((item, index) => 
                        <option value={item} key={index}>{item}</option>
                      )}
                    </Form.Select>
                  </div>
                </th>
                <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {language[this.props.currentLanguage].Branch}
                    {this.state.selectedCompany !== '' &&
                  <Form.Select style={{ width: 150, marginLeft: 10}} value={this.state.selectedBranch} onChange={(e) => this.setState({ selectedBranch: e.target.value}, () => this.getCompanyF())}>
                    <option></option>
                    {this.state.branchList.length > 0 && this.state.branchList.map((item, index) => 
                      <option value={item} key={index}>{item}</option>
                    )}
                  </Form.Select>}
                  </div>
                </th>
                <th>{language[this.props.currentLanguage].Department}</th>
                <th>{language[this.props.currentLanguage].Action}</th>
              </tr>
            </thead>
            {this.state.departmentList.length > 0 ?
              <tbody>
                {this.state.departmentList.map((item, index) => <tr key={index}>
                  <td>{item.Company}</td>
                  <td>{item.Branch}</td>
                  <td>{item.Department}</td>
                  <td><Button onClick={() => this.deleteDepartmentF(item)} variant="danger">{language[this.props.currentLanguage].Delete}</Button></td>
                </tr>)}
              </tbody>
              :
              <tbody>
                <tr style={{ backgroundColor: 'lightgray'}}>
                  <td colSpan="3" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                </tr>
              </tbody>}
          </Table>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (username, role, token) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: username,
          role: role,
          token: token,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Company);
  