import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form'
import ShowPass from '../image/ShowPass.png';
import HidePass from '../image/HidePass.png';
import Button from 'react-bootstrap/Button'
import { getLogin, getCapchaCode } from '../Api'
// import { language } from '../language';
import ClaimHeader from '../image/ClaimHeader.png';
// import Container from 'react-bootstrap/Container'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: '',
      Password: '',
      confirmCaptcha: '',
      loading: false,
      ShowPassword: false,
      secretcode: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.UsernameInput = React.createRef();
    this.PasswordInput = React.createRef();
  }
  async componentDidMount() {
    this.UsernameInput.focus();
    this.getCapcha();
  }

  async getCapcha() {
    const CapchaView = await getCapchaCode();
    if (CapchaView) {
      this.setState({ secretcode: CapchaView.secretCode })
    }
  }


  async handleSubmit() {
    this.setState({ loading: true });
    if (this.state.Username === '') {
      alert(`请输入用户名`);
      this.props.setLoginStatus('', '', '');
      this.setState({ loading: false})
    } else if (this.state.Password === '') {
      alert(`请输入密码`);
      this.setState({ loading: false})
      this.props.setLoginStatus('', '', '');
    }  else if (this.state.confirmCaptcha  === '') {
      alert(`Empty Captcha !!`);
      this.setState({ loading: false }, () => {
        this.CapchaInput.focus();
      })
      this.props.setLoginStatus('', '', '', '', '');
        
    }
    else {
      const LoginStatus = await getLogin(
        this.state.Username,
        this.state.Password,
        this.state.secretcode,
        this.state.confirmCaptcha,
      );
      if (LoginStatus.error && LoginStatus.error === 'Wrong Captcha') {
        alert('错误Capcha');
        this.setState({ loading: false })
        this.props.setLoginStatus('', '', '');
        this.CapchaInput.focus();
      } else if (LoginStatus.error && LoginStatus.error === 'Account blocked, please contact admin') {
        alert('账号被封，请联系管理员');
        this.setState({ loading: false })
        this.props.setLoginStatus('', '', '');
      } else if (LoginStatus.error && LoginStatus.error === 'Your account and/or password is incorrect, please try again') {
        alert('账号/密码错误');
        this.setState({ loading: false })
        this.props.setLoginStatus('', '', '');
        this.PasswordInput.focus();
      } else if (LoginStatus.message && LoginStatus.message === 'Login Success') {
        this.props.setLoginStatus(
          LoginStatus.username,
          LoginStatus.role,
          LoginStatus.token,
        );
        this.props.setCompanyData(JSON.stringify(LoginStatus.department))
        localStorage.setItem("CompanyData",  JSON.stringify(LoginStatus.department));
        localStorage.setItem("claimusername", LoginStatus.username);
        localStorage.setItem("claimrole", LoginStatus.role);
        localStorage.setItem("claimtoken", LoginStatus.token);
        this.setState({ loading: false })
        if (LoginStatus.role === 'Specialist') {
          this.props.history.push('/User')
        } else if (LoginStatus.role === 'Account') {
          this.props.history.push('/Claim')
        } else {
          this.props.history.push('/main')
        }
      }
    }
  }
  async onKeyEnter(e) {
    if (e.charCode === 13) {
      this.handleSubmit()
    }
  }

  handleChange(value, field) {
    this.setState({ [field]: value });
  }

  render() {
    return (
      <div  style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: 15, }}>
        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
          <Form.Control ref={(input) => { this.UsernameInput = input; }} style={{ marginBottom: 10, maxWidth: 300 }} value={this.state.Username} type="text" placeholder="Username" onKeyPress={(e) => this.onKeyEnter(e)} onChange={(e) => this.handleChange(e.target.value, 'Username')}/>
          <Form.Control ref={(input) => { this.PasswordInput = input; }} style={{ marginBottom: 10, maxWidth: 300 }} value={this.state.Password} type="text" placeholder="Password" onKeyPress={(e) => this.onKeyEnter(e)} onChange={(e) => this.handleChange(e.target.value, 'Password')}/>
          <Button style={{ marginBottom: 10 }} onClick={() => this.handleSubmit()} variant="primary">登入</Button>{' '}
        </div> */}
        <Form className=" LoginContainer">
          <img src={ClaimHeader} alt="Responsive img" class="img-fluid" style={{ width: '100%'}}/>
          <Form.Group className=" LoginUsername" style={{ alignItems: 'center', display: 'flex', marginLeft: 10, marginBottom: 15}}>
     
            <Form.Label style={{ fontSize: '18px', fontWeight: 'bold', width: 80}} >用户名</Form.Label>:
            <Form.Control
              type="text"
              value={this.state.Username}
              onChange={(e) => this.handleChange(e.target.value, 'Username')}
              ref={(input) => { this.UsernameInput = input; }}
              placeholder={'用户名'}
              style={{ fontSize: '12px', fontWeight: 'bold',width: '45%',}}
            />
          </Form.Group>
          <Form.Group className=" LoginUsername2" style={{alignItems: 'center', display: 'flex', marginLeft: 10}}>
     
            <Form.Label style={{ fontSize: '18px', fontWeight: 'bold', width: 80}} >密码</Form.Label>:
            <Form.Control
              type={(this.state.ShowPassword === true) ? "Text" : "Password"}
              value={this.state.Password}
              onChange={(e) => this.handleChange(e.target.value, 'Password')}
              placeholder={'密码'}
              ref={(input) => { this.PasswordInput = input; }}
              onKeyPress={(e) => this.onKeyEnter(e)}
              style={{ fontSize: '12px', fontWeight: 'bold',width: '45%',}}
            />
            {this.state.ShowPassword === false
              ? <div style={{ cursor: 'pointer'}} onClick={()=> this.setState({ShowPassword: true })}>
                <img src={HidePass} alt="Logo" className='PasswordEye' /></div>
              : <div style={{ cursor: 'pointer'}} onClick={()=> this.setState({ShowPassword: false })}>
                <img src={ShowPass} alt="Logo" className='PasswordEye' /></div>
            }
          </Form.Group>
          <Form.Group className="" style={{ width: '80%', height: 'auto', marginLeft: 20}}>
            <div className='' style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10}}>
              <>
                <Form.Label style={{ fontSize: '18px', fontWeight: 'bold', width: 80}} />
                <div style={{ padding: 0, textAlign: 'center', width: 270, fontSize: '25px', fontWeight: 'bold', display: 'flex', flexDirection: 'row', color: 'black' }}>
                  <div style={{ display: 'flex', textAlign: 'end', cursor: 'pointer', fontSize: '30px'}} onClick={() => this.getCapcha()}>{'⟳'}</div>
                  <div style={{ width: '80%', backgroundColor: '#EDEDED', borderRadius: 2}}><img src={`https://ace-bt.s3.ap-southeast-1.amazonaws.com/capcha/${this.state.secretcode}.png`} alt="Logo" style={{ width: '100%', height: 100 }} /></div>
                </div>
              </>
              <>
                <Form.Control
                  type="text"
                  value={this.state.confirmCaptcha}
                  onChange={(e) => this.setState({ confirmCaptcha: e.target.value })}
                  placeholder={'输入capcha'}
                  ref={(input) => { this.CapchaInput = input; }}
                  onKeyPress={(e) => this.onKeyEnter(e)}
                  style={{ fontWeight: 'bold', textAlign: 'center', width: 180, height: 54, fontSize: '18px'}}
                />
              </>
            </div>
          </Form.Group>
          {/* <Form.Group className="dcc LoginUsername2" style={{ marginLeft: 10}}>
            <label style={{ fontSize: '13px', fontWeight: 'bold', width: 80}} for="uname"><b>密码</b></label>:
            <Form.Group className="dcc" style={{ width: '100%', height: 'auto', flexDirection: 'row'}}>
              <Form.Control
                className="LoginInputText"
                type={(this.state.ShowPassword === true) ? "Text" : "Password"}
                value={this.state.Password}
                onChange={(e) => this.handleChange(e.target.value, 'Password')}
                placeholder={'密码'}
                ref={(input) => { this.PasswordInput = input; }}
                onKeyPress={(e) => this.onKeyEnter(e)}
                style={{ fontSize: '12px', fontWeight: 'bold', marginRight: null,  marginLeft: ''}}
              />
              {this.state.ShowPassword === false
                ? <div style={{ cursor: 'pointer'}} onClick={()=> this.setState({ShowPassword: true })}>
                  <img src={HidePass} alt="Logo" className='PasswordEye' /></div>
                : <div style={{ cursor: 'pointer'}} onClick={()=> this.setState({ShowPassword: false })}>
                  <img src={ShowPass} alt="Logo" className='PasswordEye' /></div>
              }
            </Form.Group>
          </Form.Group> */}
          <Form.Group className='' style={{ width: '100%', paddingBottom: '4%' }}>
            <Form.Label className='LoginUsernameLabel'></Form.Label>
            <Button  className="btn ButtonLogin" style={{ flexDirection: 'row', backgroundColor: '#FE6C33', borderColor: '#FE6C33' }} onClick={this.handleSubmit}>
              <p1 style={{ fontSize: '12px', fontWeight: 'bold', marginRight: 5, color: 'white' }}>
                  登入
              </p1>
            </Button >
          </Form.Group>
          <Form.Label style={{ width: '99%', height: 'auto', color: 'white',backgroundColor: '#FE6C33',  fontSize: '12px', paddingTop: 25, paddingBottom: 10, paddingLeft: 10,margin: 5 }}>
              Powered by ACE SDN BHD
          </Form.Label> 
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (username, role, token) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: username,
          role: role,
          token: token,
        },
      });
    },
    setCompanyData: (CompanyData) => {
      dispatch({
        type: 'COMPANY_STATUS',
        payload: {
          CompanyData: CompanyData,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);
  